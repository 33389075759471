import { TextField, Select, OutlinedInput, MenuItem } from '@material-ui/core';
import DropDownIcon from "@material-ui/icons/ExpandMore";
import Dialog from 'fitbud/components/Dialog';
import { FormTextField } from 'fitbud/components/form-fields';
import { PUBLISHED,DRAFT } from 'fitbud/utils/constants';
import _ from 'lodash';
import React, { useCallback, useContext, useState, useMemo } from 'react'
import { getEmptySchedule } from './helper';
import {CustomConfirmation} from "fitbud/components/customConfirmation";
import { MediaUploader } from 'fitbud/components/media-uploader/mediaUploader';
import update from "immutability-helper";
import * as Sentry from "@sentry/browser";
import ImageFileUpload from "fitbud/components/imageSelector";
import clsx from 'clsx';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';

const SPCLCIDS = ['silbe', 'elmtodofuncional'];

const CreateEditScheduleDialog = (props) => {
  const { cid, scheduleType, doc,title,showCustomConfirmation=false,
    handleDraft,handlePublish,isNew,onClose,draftFeature,isDraftAvailable, videoStatusRefresh } = props;
  const [data, setData] = useState(doc || getEmptySchedule(scheduleType));
  const [errors, setErrors] = useState({});
  const [isDirty, setDirty] = useState(false);
  const [isCustomConfrmtn,toggleCustomConfrmtn]=useState(false);
  const { comp } = useContext(FirebaseAuthContext);
  const compData = comp.data();
  const { _next: isExploreEnabled } = compData;
  const slots = useMemo(() => {
    if (!isExploreEnabled) return [];
    if (scheduleType !== 'workout') return [];
    const woslots = _.get(compData, ['app_config', 'tags', 'woslots']) || {};
    return _.toPairs(woslots).sort((a, b) => {
      return a[1].order - b[1].order;
    }).map(([key, obj]) => {
      return {label: obj.value, value: key};
    });
  }, [isExploreEnabled, scheduleType, compData]);

  const validate  = () => {
    let out = true;
    if(!data.title || !data.title.trim()){out=false;setErrors(o=>({...o,title: 'Please enter Display Name'}));}
    if(!data.ref_name || !data.ref_name.trim()){out=false;setErrors(o=>({...o,ref_name: 'Please enter Reference Name'}));}
    if(out) out = _.cloneDeep(data);
    return out;
  };

  const handleChange = (e) => {
    setDirty(true);
    setErrors({});
    const { name, value } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };
  const handleSaveClick=()=>{
    if(!isNew && !isDirty && !isDraftAvailable){
      onClose();
      return;
    }
    if(isNew||!draftFeature) handleSave();
    else {
      toggleCustomConfrmtn(true);
    }
  }
  const handleSave = (docType=PUBLISHED) => {
    const finalData = validate();
    if(!finalData) return;
    docType===PUBLISHED?handlePublish(finalData):handleDraft(finalData,true);
  };

  const updateMediaMeta = (args = {}) => {
    const { media } = data;
    if (media && !!_.isMatch(media[0], args)) return;
    const newState = update(data, {
      media: {
        0: {
          $merge: {
            ...args,
          },
        },
      },
    });
    setData({
      ...newState,
    });
  };

  const setDuration = (secs) => {
    const value = Math.round(secs);
    updateMediaMeta({ duration: value });
  };

  const whenPlayerReady = useCallback(
    (player) => {
      try {
        const videoHeight = player.getInternalPlayer().videoHeight;
        if (!!videoHeight) {
          updateMediaMeta({ height: `${videoHeight}p` });
        }
      } catch (err) {
        console.log('onReady err', err);
        Sentry.captureException(err);
      }
    },
    [data.media]
  );

  const handleMediaError = (err) => {
    console.log('onError', err);
    Sentry.captureException(err);
  };

  const handleMedia = async (media) => {
    let { type, url } = media;
    if (!!_.get(media, 'vimeo', '')) type = 'vimeo'; //vimeo video hack ....
    let newState = { ...data };
    if (['youtube', 'vimeo'].includes(type)) {
      newState = update(newState, {
        media: {
          $set: [media],
        },
      });
    } else {
      newState = update(newState, {
        media: {
          $set: [{ type: 'video', url }],
        },
      });
    }

    setDirty(true);
    setData(newState);
  };

  const removeMedia = (type) => {
    let newData = { ...data };
    if (['youtube', 'vimeo'].includes(type)) {
      newData = update(newData, {
        media: {
          $set: [{ type, url: '' }],
        },
      });
    } else if (type === 'video') {
      newData = update(newData, {
        media: {
          $set: [{ type: 'video', url: '' }],
        },
      });
    } else {
      newData = update(newData, {
        thumbnail: {
          $set: '',
        },
      });
    }

    setData(newData);
    setDirty(true);
  };

  return (
    <Dialog
      {...props} 
      titleFont="h3"
      appBarColor="bg-body"
      buttonColor="primary"
      paperClass={"width-640"}
      dialogContentClassName="d-flex flex-column"
      toolbarClass="height-60"
      title={title}
      fullWidth
      isDirty={isDirty}
      withConfirmation
      onSave={handleSaveClick}
      actionText={isNew ? "Next" : "Done"}
      maxWidth="md"
      showCustomConfirmation={showCustomConfirmation}
      handleDraft={()=>handleSave(DRAFT)}
      handlePublish={()=>handleSave(PUBLISHED)}
      onClose={onClose}
      >
        <div className='w-100 fp-20'>
          <div className='d-flex mb-20'>
            <div className={clsx('flex-1 d-flex flex-column justify-content-between', !isExploreEnabled && "mr-20")}>
              <FormTextField labelVariant={'subtitle1'} fullWidth label="Reference Name" required>
                <TextField
                  id="ref_name"
                  name='ref_name'
                  autoComplete="off"
                  placeholder='Enter Reference Name . . .'
                  value={data.ref_name}
                  onChange={handleChange}
                  error={!!errors.ref_name}
                  helperText={errors.ref_name}
                  variant="outlined"
                  type="text"
                  InputProps={{
                    classes: {
                      root: "medium",
                      input: "size_15_500 medium"
                    }
                  }}
                />
              </FormTextField>
              <FormTextField labelVariant={'subtitle1'} fullWidth label="Display Name" required={scheduleType !== 'meal'} classes={{control:"pb-0"}}>
                <TextField
                  id="title_schedule"
                  name='title'
                  autoComplete="off"
                  placeholder='Enter Display Name . . .'
                  value={data.title}
                  onChange={handleChange}
                  error={!!errors.title}
                  helperText={errors.title}
                  variant="outlined"
                  type="text"
                  InputProps={{
                    classes: {
                      root: "medium",
                      input: "size_15_500 medium"
                    }
                  }}
                />
              </FormTextField>
            </div>
              {!isExploreEnabled && (
                <ImageFileUpload
                  name='thumbnail'
                  aspectRatio={SPCLCIDS.includes(cid) ? 1.0 : 1.5}
                  thumbnail={data.thumbnail}
                  onChange={handleChange}
                  size={SPCLCIDS.includes(cid) ? 'small' : 'medium'}
                />
              )}
          </div>
          {Boolean(slots.length) && <div className='mb-20'>
            <FormTextField fullWidth label="Type" classes={{ control: "mr-20" }}>
              <Select
                fullWidth
                IconComponent={DropDownIcon}
                input={<OutlinedInput classes={{ root: "medium", input: "size_16_500 select-medium" }} name="fixed_slot"/>}
                value={data.fixed_slot || 'a'}
                onChange={handleChange}>
                {slots.map((slot) => (
                  <MenuItem value={slot.value}>{slot.label}</MenuItem>
                ))}
              </Select>
            </FormTextField>
          </div>}
          <FormTextField labelVariant={'subtitle1'} fullWidth label="Description">
            <TextField
              id="desc"
              name="desc"
              minRows={4}
              maxRows={4}
              multiline
              autoComplete="off"
              placeholder='Enter Description . . .'
              value={data.desc}
              onChange={handleChange}
              error={!!errors.desc}
              helperText={errors.desc}
              variant="outlined"
              type="text"
              InputProps={{
                classes: {
                  input: "size_15_500"
                }
              }}
            />
          </FormTextField>
          {isExploreEnabled && (
            <MediaUploader
              label="Media"
              media={data.media}
              errors={errors}
              handleMedia={handleMedia}
              removeMedia={removeMedia}
              featureType={scheduleType === 'workout' ? 'schedules' : 'mlSchedules'}
              showFeature={false}
              classes={{
                mediaBlock: "mb-20",
              }}
              videoStatusRefresh={videoStatusRefresh}
              playerProps={{
                onDuration: setDuration,
                onReady: whenPlayerReady,
                onError: handleMediaError,
              }}
              withThumbnail
              thumbnail={data.thumbnail}
              thumbaspect={SPCLCIDS.includes(cid) ? 1.0 : 1.5}
              handleChange={(e) => {
                setDirty(true);
                handleChange(e);
              }}
              isNew={isNew}
            />
          )}
        </div>
        {isCustomConfrmtn && (
        <CustomConfirmation
            handleClose={() => toggleCustomConfrmtn(false)}
            handleDiscard={onClose}
            handleDraft={()=>handleSave(DRAFT)}
            handlePublish={()=>handleSave(PUBLISHED)}
            dirty={isDirty}
            showDiscard={isDirty}
        />
        )}
    </Dialog>
  )
};

export default CreateEditScheduleDialog;
