import React, { useState,  useMemo, } from 'react';
import { AppBar, Toolbar, Divider, Collapse, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { Fragment } from 'react';
import { useAcl, ACL_STATUS } from 'fitbud/providers/acl-provider';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from './styles';
import { StatusChip, StatusText, AclAction, StatusInfo } from './acl-components';
import { useLocation } from 'react-router-dom';
import _some from 'lodash/some';
import AclOverlayBlocker from './acl-overlay-blocker';

const AclDivider = (props) => {
  const { status } = useAcl();
  const classes = useStyles();

  const getClasses = () => {
    if (status === ACL_STATUS.TRIAL_LIMITED || status === ACL_STATUS.PLAN_LIMITED) return classes.danderDivider;
    return classes.warningDivider;
  };

  return <Divider className={getClasses()} />;
};

const SMAclStatus = (props) => {
  const [expanded, setExpand] = useState(false);
  const classes = useStyles();
  const { checkLimitedAccess } = useAcl();
  const isLimitedAccess = useMemo(()=>checkLimitedAccess(),[checkLimitedAccess]);
  const getBg = () => {
    if (isLimitedAccess) return classes.bgDanger;
    return classes.bgWarning;
  };
  const toggleExpand = () => {
    setExpand(!expanded);
  };


  return (
    <AppBar position="static">
      <Toolbar className={clsx(classes.rootSM, getBg())} onClick={toggleExpand}>
        <StatusChip />
        <StatusInfo className="ml-15" />
        <div className="flex-grow-1" />
        <IconButton onClick={toggleExpand}>
          <ExpandMoreIcon />
        </IconButton>
      </Toolbar>
      <Collapse in={expanded} timeout="auto" className={clsx(getBg())} unmountOnExit>
        <div className="px-20 pb-15">
          <StatusText className="font_15_500" />
          <AclAction classes={{ container: 'w-100 mt-15', btn: 'w-100' }} />
        </div>
      </Collapse>
      <AclDivider />
    </AppBar>
  );
};
const DISABLE_DIALOG_PAGE = ['/config/billing',"/trial-expired","/upgrade", "/upgrade-elite" ,/\/companies\/.*/gi]; //Incase of these route no need to show action button in header



const AclContent = () => {
  const classes = useStyles();
  const { checkLimitedAccess } = useAcl();
  const isLimitedAccess = useMemo(()=>checkLimitedAccess(),[checkLimitedAccess]);
  const getBg = () => {
    if (isLimitedAccess) return classes.bgDanger;
    return classes.bgWarning;
  };
  return <Toolbar className={clsx(classes.rootMD, getBg())}>
    {/* layout for desktop */}
    <div className="d-sm-none d-lg-flex w-100 align-items-center">
      <StatusChip />
      <StatusText className="font_15_500 ml-15 " style={{width:"50%"}} />
      <div className="flex-grow-1" />
      <StatusInfo className="mr-15" />
      <AclAction />
    </div>
    {/* Layout for tab */}
    <div className="w-100 flex-column py-5 d-sm-flex d-lg-none">
      <div className="d-flex align-items-center">
        <StatusChip />
        <StatusInfo className="ml-15" />
      </div>
      <div className="d-flex align-items-center mt-5">
        <StatusText className="font_15_500" />
        <div className="flex-grow-1" />
        <AclAction />
      </div>
    </div>
  </Toolbar>;
}

const MdAclStatus = (props) => {
  return (
    <Fragment>
      <AppBar position="static">
        <AclContent />
        <AclDivider />
      </AppBar>
    </Fragment>
  );
};

const AclStatusBar = (props) => {
  const {disableRouteCheck} =props;
  const { status, checkLimitedAccess } = useAcl();
  const theme = useTheme();
  const { pathname } = useLocation();
  const isLimitedAccess = useMemo(()=>checkLimitedAccess(),[checkLimitedAccess]);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isDisableActionPage = useMemo(() => {
    if(disableRouteCheck) return false;
    if (_some(DISABLE_DIALOG_PAGE, (x) => pathname.match(x))) return true;
    return false;
  }, [pathname, disableRouteCheck]);
  if (isLimitedAccess && !isDisableActionPage ) { //Render acl blocker here 
    return <AclOverlayBlocker />
  }
  if (status === ACL_STATUS.ACTIVE || status === ACL_STATUS.TRIAL_GRACE || isDisableActionPage ) return null; //if active show nothing:
  if (isMobile) return <SMAclStatus />; //layout for mobile
  return <MdAclStatus />;
};

export default AclStatusBar;
