import { Button, Card, Divider, makeStyles, Typography } from '@material-ui/core';
import { ClrdButton } from 'fitbud/components/form-fields';
import { PeopleIcon } from 'fitbud/icons/peopleIcon';
import Resend from "fitbud/images/resend.svg";
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { useAcl, ACL_STATUS } from 'fitbud/providers/acl-provider';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useUserContext } from '../detail';
import { isSelfSignupUser } from '../helpers';
import { OnboardingCheckinCard } from './infoCards';
import LeftSection from './leftSection';
import { MessageDialog } from './messageDialog';
import RightSection from './rightSection';
import _ from 'lodash';

const styles = makeStyles((theme) => ({
  root: {
    flex: 1,
    overflow: 'auto',
    backgroundColor: '#F2F4F7'
  },
  leftPanel: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '63%',
    },
    overflow: 'auto',
  },
  rightPanel: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '37%',
      backgroundColor: '#EBEDF0',
    },
    overflow: 'auto',
  },
  listStyling:{
    padding: '20px 20px 10px 20px',
    margin:0,
    '& li':{
      margin: '0px 20px',
    }
  }
}));
const Overview = (props) => {
  const classes = styles();
  const { status } = useAcl();
  const { comp, cid, features, userProfile } = useContext(FirebaseAuthContext);
  const { userDoc, docId, measurableTags, measurableUnits, unitSystem, unitDetails  } = useUserContext();
  const { sessionDocs, loadSessions, loadNotes, noteDocs, loadCheckinsAggregate, checkinsAggregate,
    handleResendEmail, checkinAggPhotosArray, } = props;
  const { bmr, checkin_config } = comp.data();
  const [messageDialog, setMessageDialog] = useState({show: false, msg:''});
  const openMessageDialog = ({show=true, msg=''}={}) => setMessageDialog({ show, msg });
  const hasImageAccess = useMemo(() => !features.privacy || !!_.get(userProfile, `meta.${cid}.checkin`, false), [userProfile, cid, features.privacy]);
  const canResend = (status !== ACL_STATUS.TRIAL && status !== ACL_STATUS.PLAN_GRACE);

  const renderLeftSection = useCallback(() => {
    if(userDoc && !userDoc.onboarding_start_date){
      return (
        <div className="d-flex justify-content-center align-items-center h-100 flex-column w-75 mx-auto">
          <img src={Resend} alt="resend" />
          <Typography className="font_18_600 fmy-30" align='center'>
            {canResend ? 'Resend welcome email to your client to send the instructions to install the app' :
                'Welcome email has been sent. Client is yet to sign-in to the app and complete their onboarding'}
          </Typography>
          {canResend && <Button variant="contained" color="primary" className="f-xlarge" onClick={handleResendEmail}>
            Resend Email
          </Button>}
        </div>
      );
    };
    if(isSelfSignupUser(userDoc)){
      return (
        <>
          <Card elevation={0} className='bg-white'>
            <div className='d-flex align-items-center justify-content-between fp-20'>
              <Typography variant='h6' className='w-75'>
                Convert your Leads to Paid Customers. Here are a few useful tips -
              </Typography>
              <PeopleIcon />
            </div>
            <Divider />
            <ul className={classes.listStyling}>
              <li className='fmb-10 font_13_500'>
                Engage with your leads by initiating a conversation
              </li>
              <li className='fmb-10 font_13_500'>
                Get to know about their requirements by scheduling a Video Call with them
              </li>
              <li className='fmb-10 font_13_500'>
                Free trials are an effective way to convert leads. Try offering them a 7 day free trial to give them a flavor of your coaching
              </li>
            </ul>
            <div className='fpx-20 fpb-20'>
              <ClrdButton color='primary' variant='contained' style={{ height: '44px' }} onClick={() => openMessageDialog({show:true, msg: ''})}>
                Send Message
              </ClrdButton>
            </div>
          </Card>
          {(!userDoc.aplan || userDoc.aplan === 'inactive') && (
            <div className='fmt-15'>
              <OnboardingCheckinCard
                {...userDoc}
                compChkinConf={checkin_config}
                docId={docId}
                measurableTags={measurableTags}
                measurableUnits={measurableUnits}
                unitSystem={unitSystem}
                unitDetails={unitDetails}
                cid={cid}
                userDoc={userDoc}
                bmr={bmr}
                hasImageAccess={hasImageAccess}
              />
            </div>
          )}
          </>
        );
    };
    if(!userDoc.aplan || userDoc.current_plan_status === 'inactive' || (!!userDoc.aplan && userDoc.current_plan_status === null)){
      return (
        <OnboardingCheckinCard
          {...userDoc}
          compChkinConf={checkin_config}
          docId={docId}
          measurableTags={measurableTags}
          measurableUnits={measurableUnits}
          unitSystem={unitSystem}
          unitDetails={unitDetails}
          cid={cid}
          userDoc={userDoc}
          bmr={bmr}
          hasImageAccess={hasImageAccess}
        />
      )
    }
    return <LeftSection
      compChkinConf={checkin_config}
      loadCheckinsAggreagate={loadCheckinsAggregate}
      checkinsAggregate={checkinsAggregate}
      checkinAggPhotosArray={checkinAggPhotosArray}
      hasCheckinAccess={hasImageAccess} />
  }, [bmr, checkinAggPhotosArray, checkinsAggregate, cid, classes.listStyling, docId, handleResendEmail, hasImageAccess, 
    loadCheckinsAggregate, measurableTags, measurableUnits, unitDetails, unitSystem, userDoc, checkin_config]);

  return (
    <div className='h-100 d-flex flex-column overflow-hidden'>
      {!!messageDialog.show && <MessageDialog uid={userDoc.uid || ''} cid={cid} open={!!messageDialog && messageDialog.show} onClose={() => setMessageDialog(false)} />}
      <div className={`${classes.root} d-block d-md-flex flex-md-row `} >
        <div className={`${classes.leftPanel} py-15 px-20`}>
          {renderLeftSection()}
        </div>
        <div className={`${classes.rightPanel} pb-15 px-20 pt-md-15`}>
          <RightSection loadSessions={loadSessions} sessionDocs={sessionDocs}
            loadNotes={loadNotes} noteDocs={noteDocs} openMessageDialog={openMessageDialog} />
        </div>
      </div>
    </div>
  );
}
export default Overview;
