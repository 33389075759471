import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Dialog from 'fitbud/components/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import appRdxFns from 'fitbud/redux/app';
import { PlanContext } from 'fitbud/views/plan/planProvider';
import firebase from 'firebase';
import Img from 'fitbud/views/plan/img';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { debounce } from 'lodash';
import DefaultInfo from 'fitbud/components/defaultInfo';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import _ from 'lodash';
import { useStyles, SearchBar } from 'fitbud/components/paymentPlanSelector';
import { GC_STATUS, prepareGroupClasses } from 'fitbud/views/groupClasses/helper';
import { grpClsRdxFns } from 'fitbud/redux/exports';
import { snapshotDocsArrayToDataArray } from 'fitbud/utils/helpers';
import getSecondaryTextListItems from '../catalog/listSecondLine';

const GroupClassSelector = (props) => {
  const [isLoading, setLoading] = useState(false);
  const d = useDispatch();
  const { cid } = useContext(PlanContext);
  const { comp } = useContext(FirebaseAuthContext);
  const { showLoader, hideLoader } = appRdxFns(d);
  const grpClsOps = grpClsRdxFns(d);
  const gcRdxDocs = useSelector((s) => s.groupClasses);
  const docs = gcRdxDocs?.docs || [];
  const [gClasses, setGClasses] = useState(docs);

  const loadGcs = async () => {
    const docsSnap = await firebase
      .firestore()
      .collection(`companies/${cid}/gcDetails`)
      .where('archive', '==', false)
      .orderBy('index')
      .get();
    if (!docsSnap.size) return [];
    const data = snapshotDocsArrayToDataArray(docsSnap.docs);
    const list = prepareGroupClasses(data);
    setGClasses(list);
    grpClsOps.set(list, 0, true);
  };

  const fetchList = async () => {
    try {
      if (gcRdxDocs && !gcRdxDocs.length) {
        return loadGcs();
      }
    } catch (err) {
      console.log('>>>error in loading', err);
    }
  };

  useEffect(() => {
    showLoader();
    fetchList().then(() => hideLoader());
  }, []);

  const debouncedChange = debounce((value = '') => {
    if (!value) return setGClasses(docs);
    const results = docs.filter(({ data }) => data.ref_name.toLowerCase().includes(value.toLowerCase()));
    return setGClasses(results);
  }, 300);

  const handleChange = (e) => {
    const { value } = e.target;
    debouncedChange(value);
  };

  const handleItemClick = (doc) => {
    props.onSelect(doc);
  };

  const _docs = useMemo(() => {
    return _.filter(gClasses || [], (c) => [GC_STATUS.ACTIVE, GC_STATUS.PROCESSING].includes(c?.data?.status));
  }, [gClasses]);

  const classes = useStyles();
  if (isLoading) return null;
  return (
    <Dialog
      open
      title={'Select Classes'}
      paperClass={'width-600 height-70'}
      appBarColor="bg-grey-new"
      toolbarClass={'height-60'}
      dialogContentClassName="d-flex flex-column"
      hideHeaderDivider
      // actionText="Next"
      {...props}>
      <div className="px-20 bg-grey-new">
        <SearchBar onChange={handleChange} />
      </div>
      <div className="position-relative d-flex flex-column h-100 overflow-hidden">
        {!_docs || !_docs.length ? (
          <div className="flex-1 d-flex flex-column align-items-center justify-content-center">
            <DefaultInfo keyName="groupClass" label="No results found" />
          </div>
        ) : (
          <List disablePadding className="flex-1 overflow-auto">
            {_docs.map((doc, i) => (
              <ListItem
                key={i}
                id={i}
                className="fpy-10 fpx-20"
                button
                onClick={() => handleItemClick(doc)}
                classes={{
                  root: classes.root,
                }}>
                <ListItemAvatar>
                  <Img
                    name={doc.data.title}
                    src={doc.data.thumbnail}
                    size={360}
                    width={70}
                    aspect={1.5}
                    className="rounded mr-3"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={doc.data.ref_name}
                  secondary={getSecondaryTextListItems({ keyName: 'groupClass', doc })}
                />
                <div>
                  <ArrowForward />
                </div>
              </ListItem>
            ))}
          </List>
        )}
      </div>
    </Dialog>
  );
};
export default GroupClassSelector;
