import {
  Badge,
  Chip,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import notificationIcon from 'fitbud/images/notification.svg';
import Img from './img';
import clsx from 'clsx';
import { DAY, FREQUENCY } from './constant';
import moment from 'moment';

const useStatusStyles = makeStyles(() => ({
  root: {
    minHeight: '80px',
    '& .MuiListItemText-primary': {
      marginBottom: '2px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontWeight: '600',
    },
    '& .MuiListItemText-secondary': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&.Mui-selected': {
      backgroundColor: '#E0ECFE',
      '&:hover': {
        backgroundColor: '#F6F9FE',
      },
    },
    '&:hover': {
      backgroundColor: '#F6F9FE',
    },
  },
  selectedChip: {
    background: '#FFF',
    border: '1px solid #317FF5',
  },
  chip: {
    height: 28,
    backgroundColor: '#FFF',
    color: '#37404D',
    border: '1px solid #D3D9DB',
  },
  selectedClickable: {
    cursor: 'pointer',
    color: '#317FF5',
    border: '1px solid #317FF5',
    '&:focus , &:hover': {
      backgroundColor: '#FFF',
    },
  },
  unselectedClickable: {
    cursor: 'pointer',
    '&:focus, &:hover': {
      backgroundColor: '#FFF',
      color: '#37404D',
    },
  },
  badge: {
    right: '4px',
    bottom: '4px',
    border: '2px solid #FFF',
    height: '10px',
    width: '10px',
    borderRadius: '5px',
  },
  activeBadge: {
    backgroundColor: '#05B715',
  },
  inactiveBadge: { backgroundColor: '#F29900' },
  finishedBadge: { backgroundColor: '#D73717' },
}));

function filterNotification(filter, list) {
  if (filter.length === 3) return list;
  const filteredList = list.filter((doc) => {
    const { _from, _till } = doc.data;
    const startTime = moment(_from, 'YYYYMMDDHHmm');
    const endTime = moment(_till, 'YYYYMMDDHHmm');
    const currentTime = moment();
    let isValid = false;
    if (filter.includes('upcoming')) {
      isValid = currentTime.isBefore(startTime);
    }
    if (!isValid && filter.includes('live')) {
      if (endTime.isValid()) {
        isValid = currentTime.isAfter(startTime) && currentTime.isBefore(endTime);
      } else {
        isValid = currentTime.isAfter(startTime);
      }
    }
    if (!isValid && filter.includes('past')) {
      isValid = currentTime.isAfter(startTime) && currentTime.isAfter(endTime);
    }
    return isValid;
  });
  return filteredList;
}

function getBadgeStatus(doc) {
  const {active, _till} = doc.data;
  const currentTime = moment();
  const endTime = moment(_till, 'YYYYMMDDHHmm');

  if (!active) {
    return 'inactiveBadge';
  }
  if(endTime && currentTime.isAfter(endTime)) {
    return 'finishedBadge';
  }

  return 'activeBadge';
}

export default function NotificationList({ selected, onSelect, onAdd: handleAdd, loading, list: data, fetchList }) {
  const { cid } = useContext(FirebaseAuthContext);
  const classes = useStatusStyles();
  const [filter, setFilter] = useState(['upcoming', 'live']);

  const list = useMemo(() => {
    if (filter.length > 0) {
      return filterNotification(filter, data); 
    }
    return data;
  }, [filter, data]);

  const handleFilter = (filterString) => {
    if (filter.includes(filterString)) {
      setFilter((prevFilter) => {
        return prevFilter.filter((value) => value !== filterString);
      });
    } else {
      setFilter((prevFilter) => [...prevFilter, filterString]);
    }
  };

  useEffect(() => {
    fetchList();
  }, [cid]);

  return (
    <div className="d-flex flex-column vh-100 position-relative bg-white">
      <div className="position-relative bg-grey-new px-20">
        <div className="d-flex justify-content-between align-items-center" style={{ height: 56 }}>
          <Typography variant="h3" className="flex-grow-1">
            In-App Notifications
          </Typography>
          <div className="d-flex" style={{ marginRight: '-8px' }}>
            <IconButton onClick={handleAdd}>
              <AddIcon />
            </IconButton>
          </div>
        </div>

        <div className="d-flex fmb-15" style={{ columnGap: 8 }}>
          <Chip
            size="medium"
            label="Upcoming"
            clickable
            onClick={() => handleFilter('upcoming')}
            className="rounded-pill"
            classes={{
              root: clsx(classes.chip, classes.selectedChip),
              label: clsx('font_13_500'),
              clickable: filter.includes('upcoming')
                ? `${classes.selectedClickable}`
                : `${classes.unselectedClickable}`,
            }}
          />
          <Chip
            size="medium"
            label="Live"
            clickable
            onClick={() => handleFilter('live')}
            className="rounded-pill"
            classes={{
              root: clsx(classes.chip, classes.selectedChip),
              label: clsx('font_13_500'),
              clickable: filter.includes('live') ? `${classes.selectedClickable}` : `${classes.unselectedClickable}`,
            }}
          />
          <Chip
            size="medium"
            label="Past"
            clickable
            onClick={() => handleFilter('past')}
            className="rounded-pill"
            classes={{
              root: clsx(classes.chip, classes.selectedChip),
              label: clsx('font_13_500'),
              clickable: filter.includes('past') ? `${classes.selectedClickable}` : `${classes.unselectedClickable}`,
            }}
          />
        </div>

        {!!loading && <LinearProgress className={'position-absolute w-100 height-2'} style={{ left: 0, bottom: -2 }} />}
      </div>
      <Divider classes={{ root: 'height-2' }} style={{ backgroundColor: '#D8DCE0' }} />
      {!loading && !data.length && (
        <div className="text-center d-flex align-items-center justify-content-center flex-column vh-100">
          <div className="fmt-30 fmb-30 ">
            <img alt="notification-icon" src={notificationIcon} />
          </div>
          <Typography variant="h6" className="fmb-10">
            No In-App Notifications Added
          </Typography>
          <Typography variant="body2">Click on “+” to add In-App Notifications</Typography>
        </div>
      )}
      <List disablePadding className="position-relative overflow-auto">
        {list?.map((doc, i) => {
          const { title, _rules, image } = doc.data;
          const frequency = Number((_rules?.minutes || DAY) / DAY);
          const badgeStatus = getBadgeStatus(doc);
          return (
            <ListItem
              key={i}
              className="fpy-10 fpx-20"
              button
              selected={selected === doc._id}
              onClick={() => onSelect(doc._id)}
              id={`plan_${doc._id}`}
              classes={{
                root: classes.root,
              }}
            >
              <ListItemAvatar className="mr-3">
                <Badge
                  overlap="rectangular"
                  variant="dot"
                  classes={{ badge: clsx(classes.badge, classes[badgeStatus]) }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <Img name={title} src={image} size={360} width={70} aspect={1.5} className="rounded-10" />
                </Badge>
              </ListItemAvatar>
              <ListItemText primary={title} secondary={FREQUENCY[frequency]} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}
