import { Typography, Divider, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import BarGraph from 'fitbud/components/BarGraph';
import moment from 'moment';
import { ComplianceBadge, ProgressNotFound, useProgressContext } from '.';
import { numFormatter, commafyNumber, roundNumber } from 'fitbud/utils/helpers';
import { minutesToHours } from 'fitbud/views/users/progress/helpers';
import { useProgressTrendsContext } from '../progTrendsProvider';
import { secondsToDuration} from "fitbud/components/durationInput";
import { Conversion } from 'fitbud/providers/conversion';

const convertDates = (data) => 
  data.map((item) => ({...item, date: moment(item.date, 'YYYYMMDD').format("MM/DD")}));

const StatsView = ({progressDataSum, view}) => {
  const data = useMemo(() => progressDataSum[view], [progressDataSum, view]);
  const [nav, setNav] = useState((data && (data.views)[0]) || '');
  useEffect(() => setNav(data && data.views[0]), [data]);
  const handleNavSelect = (value) => () => setNav(value);

  return (
    <div className='bg-white fpy-25 position-relative'>
      {data.primary && <PrimaryStats data={data[data.primary]} type={view} />}
      {data && data.secondary && <Divider className='fmy-20' />}
      {data && data.secondary && <SecondaryStats data={data} secondaryItems={data.secondary} />}
      {data && data.showNav && <Navbar handleSelect={handleNavSelect} selected={nav} data={data} />}
      {data && !data.secondary && <div className='fmy-20' />}
      <div>
        <BarGraph
          data={convertDates((data && data.logs && nav && data.logs[nav]) || [])} 
          color={data.graphColor}
          goal={(data && data.goal && nav && data.goal[nav]) || false}
          tooltipConfig={(data && data.tooltipConfig && nav && data.tooltipConfig[nav]) || false}
        />
      </div>
      <Logs data={(data && data.logs && nav && data.logs[nav]) || []} />
    </div>
  )
}

const PrimaryStats = ({data, type}) => {
  const compliance = data && data.compliance;
  const { complianceConfig } = useProgressTrendsContext();
  return (
    <div className='d-flex justify-content-between fpx-20'>
      <div>
        <Typography variant='h1' >
            {/* Numerator */}
            <span className='font-weight-bolder'> 
              {(data && data.num && commafyNumber(data.num)) || '-'} {' '}
              {!data.deno && !!data.num && !!data.unit && <span style={{fontSize: '16px', marginLeft: '-3px', fontWeight:'500'}}>{data.unit}</span>}
            </span> {' '}
            {/* Denominator */}
            {data && data.deno && (
              <span style={{fontSize: '16px', marginLeft: '-3px', fontWeight:'500'}}> 
              / {data && data.deno && data.deno >= 10000 ? numFormatter(data.deno) : commafyNumber(data.deno) || '-'}{' '}{data.unit || ''} 
              </span>
            )}
        </Typography>
        {data && data.label && (
          <Typography variant='caption' color='textSecondary' className='font_14_500 fmt-5'>
            {data.label}
          </Typography>
        )}
      </div>
      {(!!compliance && compliance !== 0) && <div className='text-right'>
        <div className='d-flex align-items-center justify-content-end'>
          {!!compliance && <ComplianceBadge compliance={compliance} type={type} complianceConfig={complianceConfig} />}
          <Typography variant='h1' className='font-weight-bolder' align='right'>
            {!!compliance ? compliance >=200 ? 0 : compliance : '-' } {!!compliance && <span style={{fontSize: '20px', marginLeft: '-3px'}}>%</span>}
          </Typography>
        </div>
        <Typography variant='caption' color='textSecondary' className='font_14_500 fmt-5' align='right'>
          Compliance
        </Typography>
      </div>}
    </div>  
  );
}
export const getSecondaryText = (num, deno, unit='', type, showPerDay=true) => {
  if(type === 'duration'){
    if(!num) return '-';
    if(num < 1){
      return (
        <>
          {'< 1'} <span className='font_14_500'> min {!!showPerDay && '/ day'}</span>
        </>
      )
    }
    const [hrs, mins] = minutesToHours(num);
    if(!hrs && !mins) return  '-';
    return (
      <>
        {hrs ? (
          <>
            {hrs} <span className='font_14_500'>hrs</span>
          </>
        ) : null}
        {mins ? (
          <>
            {mins} <span className='font_14_500'>mins</span>
          </>
        ) : null} {!!showPerDay && <span className='font_14_500'> / day</span>}
      </>
    )
  }
  if(type === 'calories'){
    if(!num) return  '-'
    return (
      <>
        {commafyNumber(num) || '-'} <span className='font_14_500'>{unit} {!!showPerDay && '/ day'}</span>
      </>
    )
  }
  return num || deno ? (
    <>
      {commafyNumber(num) || '-'}{' '} 
      <span className='font_14_500'>{deno ? `/ ${commafyNumber(deno)}` : ''} {unit}</span>
    </>
  ) : '-';
}
const SecondaryStats = ({data = false, secondaryItems=false}) => {
  const { secondaryTab } = useStyles({length: secondaryItems.length });
  const { complianceConfig } = useProgressTrendsContext();
  if(!data || !secondaryItems) return null;
  return (
    <div className='d-flex align-items-center w-100 fpx-20'>
      {secondaryItems.map((title, index)=> {
        const {num, deno, type, compliance, unit, complianceType} = data[title];
        return (
          <div key={title + index} className={`${secondaryTab} d-flex`}>
            {(index < secondaryItems.length && index !== 0) && <Divider orientation="vertical" flexItem className='fmr-15' />}
            <div className='w-100 position-relative'>
            {!!compliance && 
              <ComplianceBadge complianceConfig={complianceConfig} compliance={compliance} type={complianceType} format='numeric' className='position-absolute' style={{top:0, right:0}}/>}
              <Typography variant='caption' color='textSecondary' className='font_14_500 text-capitalize'>
                {title}
              </Typography>
              <Typography variant='h3' className='fmt-5 font-weight-bold'>
                {getSecondaryText(num, deno, unit, type)}
              </Typography>
            </div>
          </div>
        )
      })}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  navbarRoot:{
    borderRadius:'8px',
    border:'2px solid #EFEFF0',
    backgroundColor: '#EFEFF0',
  },
  navbarItem:{
    padding: '10px 0px',
    flex:1,
    backgroundColor: ({isSelected}) => isSelected ? 'white' : '#EFEFF0',
    borderRadius:({isSelected}) => isSelected ? '8px' : '0px',
    boxShadow: ({isSelected}) => isSelected ? '0px 2px 8px 0px rgba(0, 0, 0, 0.05)' : 'none', 
    cursor: 'pointer',
    transition: 'background-color 0.1s'
  },
  secondaryTab: {
    width: ({length}) => `${Math.floor(100 / length)}%`,
  }
}));
const Navbar = (props) => {
  const { selected, handleSelect, data } = props;
  const classes = useStyles();
  if((!data || !data.views || !data.showNav)) return null;
  return (
    <div className={`${classes.navbarRoot} d-flex fmt-25 fmb-25 justify-content-between fmx-20`}>
      {( (data && data.views)|| []).map((text, index) => {
        const isSelected = selected === text;
        return (
          <NavItem text={text} isSelected={isSelected} onClick={handleSelect(text)} key={text + index} />
        );
      })}
    </div>
  )
};
const NavItem = (props) => {
  const {text, isSelected, onClick} = props;
  const classes = useStyles({ isSelected });
  return (
    <div className={clsx(classes.navbarItem, 'text-capitalize', 'd-flex justify-content-center')} onClick={onClick}>
      <Typography variant='h6'>
        {text}
      </Typography>
    </div>  
  );
}
const getDetailValueText = ({value, unit, config = { inSeconds:false,decimals:0 },trackingField}) => {
  const {inSeconds,decimals}=config||{};
  if(!value) return '-';
  if(unit==="duration"){
    if(value< 1){
      return (
        <>
          <span className='font-weight-600'>{'< 1'}</span> 
          <span>{inSeconds ? 'sec' : 'min'}</span>
        </>
      )
    }
    let [durationHrs, durationMins] = minutesToHours(value);
    if(!!inSeconds){
      const exactMoment = moment().startOf('day').add(value, 'seconds');
      durationHrs = exactMoment.clone().diff(moment().startOf('day'), 'minutes');
      durationMins = exactMoment.clone().seconds();
    };
    return (
      <>
        {!!durationHrs && (
          <>
            <span className='font-weight-600'>{commafyNumber(durationHrs)}</span>
            {' '}
            <span>{!!inSeconds ? 'm' :'hrs'}</span>
          </>
        )}{' '}
        {!!durationMins && (
          <>
            <span className='font-weight-600'>{commafyNumber(durationMins)}</span>
            {' '}
            <span>{!!inSeconds ? 's' : 'mins'}</span>
          </>
        )}
      </>
    )
  }
  if(trackingField==="pace"){
    const parsedDuration=secondsToDuration(value);
    let [h,m,s]=parsedDuration.split(" : ").map(i=>Number(i));//[h,m,s]
    return(<>
    {!!m && <>
      <span className='font-weight-600 mr-2px'>{m}</span>
      <span className={!!s && "mr-3px"}>{"m"}</span>
    </>}
    {!!s && <>
      <span className='font-weight-600 mr-2px'>{s}</span>
      <span>{"s"}</span>
    </>}
    {" /"+unit.split("/")[1]}
    </>)
  }
  return (
    <>
      <span className='font-weight-600'>
        {value >= 10000 ? commafyNumber(value) : commafyNumber(roundNumber(value, !!decimals ? decimals: 2))}
      </span>
      {' '}
      {unit && <span>{unit}</span>}
    </>
  )
};
const Logs = (props) => {
  let {
    data: _data,
    ...restProps
  } = props;
  const data = useMemo(() => _data.filter(item => item.value).reverse(), [_data]);
  if(!data || !data.length) return null;
  return (
    <div {...restProps}>
      <Divider className='fmb-15'/>
      <Typography variant='h5' className={'fmb-10 fpx-20'}>
        Details
      </Typography>
      <div className='bg-white fpt-5 fpx-20'>
        {data.map((item, index) => {
          if(!item.value) return null;
          const dateString = moment(item.date, 'YYYYMMDD').format("D MMM YYYY"); 
          return (
            <div key={`${index}${item.value}${dateString}`}>
              <Divider className='fmy-15'/>
              <div className='d-flex justify-content-between fpy-15'>
                <Typography variant='body1' color='textSecondary'>
                  {moment(item.date).format("D MMM YYYY")}
                </Typography>
                <Typography variant='body1'>
                  {getDetailValueText({value:item.value,unit:item.unit})}
                </Typography>
              </div>
            </div>
          )
        })}  
      </div>
    </div>
  );
}
export default StatsView;
export { Navbar, getDetailValueText };