import React from 'react';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { FILE_STATUS } from "fitbud/utils/constants";
import { ClrdButton } from "fitbud/components/form-fields";
import videoPlaceholderIcon from "fitbud/images/videoPlaceholder.svg";
import vimeoPlaceholderIcon from "fitbud/images/vimeoPlaceholder.svg";
const showPlaceholderStatus=[FILE_STATUS.processing,FILE_STATUS.error,FILE_STATUS.uploading];
export const getText=(status,uploadingError)=>{
    if(!uploadingError){
        switch(status){
            case FILE_STATUS.processing:{
                return "Your video is uploaded and being optimized for mobile viewing, which may take time." 
            }
            case FILE_STATUS.error:{
                return "Error while processing the uploaded video.";
            }
            case FILE_STATUS.uploading:{
                return "Your video will be uploaded and then optimized for mobile viewing, which may take time."
            }
            default:return "-"
        }
    }
    else{
        return "Video uploading was interrupted or failed. Please try again."
    }
}
const styles=makeStyles({
    container:{
        borderRadius:10,
        border:"1px solid #D3D9DB",
        backgroundColor:"#F0F3F5",
        height:200,
        width:200,
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        padding:20,
        '& img':{
            marginBottom:"16px"
        }
    }
})

export const MediaPlaceholder=({media,uploadingError,onRefresh})=>{
    const classes=styles();
    const text=getText(media.status,uploadingError);
    const showRefreshBtn=media.status===FILE_STATUS.processing||(media.status===FILE_STATUS.uploading && !uploadingError)
    if(!showPlaceholderStatus.includes(media.status)) return null;
    return(
        <div className={classes.container}>
            <img alt="" src={videoPlaceholderIcon} width={34}/>
            <Typography className="font_13_500 text-center" variant="h4">
                {text}
            </Typography>
            {showRefreshBtn && <ClrdButton 
                variant="text"
                color="primary"
                className="fmt-15"
                onClick={onRefresh}
            >
                {!uploadingError && <Typography className="font_13_500 text-nowrap">Click here to refresh</Typography>}
            </ClrdButton>}
        </div>
    )
}

export const VimeoPlaceholder = (props) =>{
    const { text, loading } = props;
    const classes = styles();
    return (
      <div className={classes.container} style={{borderRadius:6}}>
        {!!loading ? (
          <CircularProgress />
        ) : (
          <>
            <img alt="" src={vimeoPlaceholderIcon} />
            <Typography className="font-500 text-center" variant="h4">
              {text}
            </Typography>
          </>
        )}
      </div>
    ); 
}