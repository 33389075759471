import _ from 'lodash';
import React, {useState, useRef, useEffect} from 'react';
import {
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FormTextField } from "fitbud/components/form-fields";
import { fakeEvent, roundNumber } from "fitbud/utils/helpers";
import {transform} from "fitbud/utils/helpers";
import clsx from 'clsx';

const noop = (e) => e.preventDefault();
const secondsToDuration = (value, hideSeconds = false, hideHours = false) => {
  const hours = Math.floor(value / 3600);
  if(!hideHours) {
    value %= 3600;
  }
  const minutes = Math.floor(value / 60);
  const seconds = roundNumber(value % 60);
  const formattedHours = hideHours ? 0 : String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');
  let out = `${formattedHours} : ${formattedMinutes}`;
  return !hideSeconds ? `${out} : ${formattedSeconds}` : out;
};
const durationToSeconds = (value) => {
  const sectioned = value.split(':');
  if (sectioned.length < 2)
    return 0;
  else
    return Number(sectioned[2] || 0) + Number(sectioned[1] * 60) + Number(sectioned[0] * 60 * 60);
}
const humanizeSeconds = (value) => {
  if (value <= 0) return '';
  const hours = Math.floor(value / 3600);
  value %= 3600;
  const minutes = Math.floor(value / 60);
  const seconds = value % 60;
  const secs = !seconds ? '' : `${seconds} ${transform('secs', {capitalize: false, pluralize: seconds})}`
  const mins = !minutes ? '' : `${minutes} ${transform('mins', {capitalize: false, pluralize: minutes})} `
  const hrs  = !hours   ? '' : `${hours} ${transform('hrs', {capitalize: false, pluralize: hours})} `
  return hrs + mins + secs;
}

const DurationInput = (props) => {
  const inputRef = useRef(null);
  const [adjustmentFactor, setAdjustmentFactor] = useState(1);
  const {
    hideSeconds = false,
    value: initialValue = 0,
    min: minProp,
    max: maxProp,
    onChange,
    onFocus, onBlur,
    inputRef:propsInputRef,
    ...rest
  } = props;
  const shouldHideSeconds = !!hideSeconds;
  const minDuration = _.isFinite(minProp) && minProp > 0 ? minProp : 0;
  const maxDuration = _.isFinite(maxProp) && maxProp > 0 ? maxProp : Infinity;
  const matchConstraints = (x) => Math.min(Math.max(x, minDuration), maxDuration);
  const fieldName = rest.id || rest.name;

  // Gets the cursor selection
  const getCursorSelection = ({target: {selectionStart, value}}, hideSeconds) => {
    const hourMarker = value.indexOf(' : ');
    const minuteMarker = value.lastIndexOf(' : ');
    let cursorSelection;
    // The cursor selection is: hours
    if (selectionStart <= hourMarker) {
      cursorSelection = 'hours';
    } else if (hideSeconds || selectionStart <= minuteMarker) { // The cursor selection is: minutes
      cursorSelection = 'minutes';
    } else if (!hideSeconds && selectionStart > minuteMarker) { // The cursor selection is: seconds
      cursorSelection = 'seconds';
    }
    return {cursorSelection, hideSeconds, hourMarker, minuteMarker};
  };
  // Gets the time interval (hh or mm or ss) and selects the entire block
  const selectFocus = (event) => {
    // Gets the cursor position and select the nearest time interval
    const {cursorSelection, hourMarker, minuteMarker} = getCursorSelection(event, shouldHideSeconds);

    // Something is wrong with the duration format.
    if (!cursorSelection) {
      return;
    }
    // The cursor selection is: hours
    if (cursorSelection === 'hours') {
      setAdjustmentFactor(60 * 60);
      event.target.setSelectionRange(0, hourMarker);
      return;
    }
    // The cursor selection is: minutes
    if (cursorSelection === 'minutes') {
      const increment = shouldHideSeconds ? 5 : 0;
      setAdjustmentFactor(60);
      event.target.setSelectionRange(hourMarker + 3, minuteMarker + increment);
      return;
    }
    // The cursor selection is: seconds
    if (cursorSelection === 'seconds') {
      setAdjustmentFactor(1);
      event.target.setSelectionRange(minuteMarker + 3, minuteMarker + 5);
      return;
    }
    setAdjustmentFactor('ss');
    event.target.setSelectionRange(minuteMarker + 3, minuteMarker + 5);
    return;
  };

  // Inserts a formatted value into the input box
  const insertFormatted = (inputBox, secondsValue) => {
    const value = secondsToDuration(secondsValue, shouldHideSeconds);
    inputBox.value = value;
    if (onChange) onChange(fakeEvent(fieldName, secondsValue));
  };
  const highlightIncrementArea = (inputBox, adjustmentFactor) => {
    const hourMarker = inputBox.value.indexOf(' : ');
    const minuteMarker = inputBox.value.lastIndexOf(' : ');
    const hideSeconds = shouldHideSeconds;

    inputBox.focus();
    inputBox.select();

    if (adjustmentFactor >= 60 * 60) {
      inputBox.selectionStart = 0; // hours mode
      inputBox.selectionEnd = hourMarker;
    } else if (!hideSeconds && adjustmentFactor < 60) {
      inputBox.selectionStart = minuteMarker + 3; // seconds mode
      inputBox.selectionEnd = minuteMarker + 5;
    } else {
      inputBox.selectionStart = hourMarker + 3; // minutes mode
      inputBox.selectionEnd = hourMarker + 5;
      adjustmentFactor = 60;
    }

    if (adjustmentFactor >= 1 && adjustmentFactor <= 3600) {
      setAdjustmentFactor(adjustmentFactor);
    }
  };
  // gets the adjustment factor for a picker
  const getAdjustmentFactor = (picker) => {
    let _adjustmentFactor = 1;
    if (Number(adjustmentFactor) > 0) {
      _adjustmentFactor = Number(adjustmentFactor);
    }
    return _adjustmentFactor;
  };

  // Change the time value;
  const changeValue = (inputBox, direction) => {
    const adjustmentFactor = getAdjustmentFactor();
    let secondsValue = durationToSeconds(inputBox.value);
    // eslint-disable-next-line default-case
    switch (direction) {
      case 'up':
        secondsValue += adjustmentFactor;
        break;
      case 'down':
        secondsValue -= adjustmentFactor;
        if (secondsValue < 0) {
          secondsValue = 0;
        }
        break;
    }
    const fixedValue = matchConstraints(secondsValue);
    insertFormatted(inputBox, fixedValue);
  };

  // shift focus from one unit to another;
  const shiftFocus = (inputBox, toSide) => {
    const adjustmentFactor = getAdjustmentFactor();
    // eslint-disable-next-line default-case
    switch (toSide) {
      case 'left':
        highlightIncrementArea(inputBox, adjustmentFactor * 60);
        break;
      case 'right':
        highlightIncrementArea(inputBox, adjustmentFactor / 60);
        break;
    }
  };

  // Check data-duration for proper format
  const checkDuration = (duration, hideSeconds) => {
    const pattern = hideSeconds ? '^[0-9]{2,3}:[0-5][0-9]$' : '^[0-9]{2,3}:[0-5][0-9]:[0-5][0-9]$';
    const regex = RegExp(pattern);
    return regex.test(duration);
  };

  // validate any input in the box;
  const validateInput = (event) => {
    const hideSeconds = shouldHideSeconds;
    const {cursorSelection} = getCursorSelection(event, hideSeconds);
    const sectioned = event.target.value.split(' : ');

    if (
      event.target.dataset.duration &&
      checkDuration(event.target.dataset.duration, hideSeconds) &&
      ((hideSeconds && sectioned.length !== 2) ||
        (!hideSeconds && sectioned.length !== 3))
    ) {
      event.target.value = event.target.dataset.duration; // fallback to data-duration value
      return;
    }
    if (!hideSeconds && sectioned.length !== 3) {
      event.target.value = '00 : 00 : 00'; // fallback to default
      return;
    } else if (hideSeconds && sectioned.length !== 2) {
      event.target.value = '00 : 00'; // fallback to default
      return;
    }
    if (isNaN(sectioned[0])) {
      sectioned[0] = '00';
    }
    if (isNaN(sectioned[1]) || sectioned[1] < 0) {
      sectioned[1] = '00';
    }
    if (sectioned[1] > 59 || sectioned[1].length > 2) {
      sectioned[1] = '59';
    }
    if (!hideSeconds && sectioned[1].length === 2 && sectioned[1].slice(-1) === event.key && cursorSelection === 'minutes') {
      shiftFocus(event.target, 'right');
    }
    if (!hideSeconds) {
      if (isNaN(sectioned[2]) || sectioned[2] < 0) {
        sectioned[2] = '00';
      }
      if (sectioned[2] > 59 || sectioned[2].length > 2) {
        sectioned[2] = '59';
      }
    }

    event.target.value = sectioned.join(' : ');
  };

  const insertWithConstraints = (event) => {
    const picker = event.target;
    const duration = picker.value || picker.dataset.duration;
    const secondsValue = durationToSeconds(duration);
    insertFormatted(picker, matchConstraints(secondsValue));
  };

  const handleKeydown = (event) => {
    const changeValueKeys = ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'Enter', ':', '.'];
    if (changeValueKeys.includes(event.key)) {
      // eslint-disable-next-line default-case
      switch (event.key) {
        // use up and down arrow keys to increase value;
        case 'ArrowDown':
          changeValue(event.target, 'down');
          break;
        case 'ArrowUp':
          changeValue(event.target, 'up');
          break;
          // use left and right arrow keys to shift focus;
        case 'ArrowLeft':
          shiftFocus(event.target, 'left');
          break;
        case '.':
        case ':':
        case 'ArrowRight':
          shiftFocus(event.target, 'right');
          break;
        case 'Enter':
          insertWithConstraints(event);
          event.target.blur();
          break;
      }
      event.preventDefault();
    }

    // The following keys will be accepted when the input field is selected
    const acceptedKeys = ['Backspace', 'ArrowDown', 'ArrowUp', 'Tab'];
    if (isNaN(event.key) && !acceptedKeys.includes(event.key)) {
      event.preventDefault();
      return false;
    }
  };

  useEffect(() => {
    const picker = inputRef.current;
    if (!checkDuration(initialValue, shouldHideSeconds)) {
      insertFormatted(picker, initialValue);
    }
  }, [minDuration, maxDuration]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
      const picker = inputRef.current;
      if (!checkDuration(initialValue, shouldHideSeconds)) {
          const value = secondsToDuration(initialValue, shouldHideSeconds);
          picker.value = value;
      }
  },[initialValue])

  const _focs = (e) => {
    if (onFocus) onFocus(e);
    selectFocus(e);
  };
  const _blur = (e) => {
    if (onBlur) onBlur(e);
    insertWithConstraints(e);
  }
  const _clix = () => {
    if (inputRef.current) inputRef.current.focus();
  }
  return (<>
    <input {...rest}
      ref={inputRef}
      onKeyDown={handleKeydown}
      onFocus={_focs}
      onMouseUp={selectFocus}
      onChange={validateInput}
      onBlur={_blur}
      onKeyUp={validateInput}
      onDrop={noop}/>
    <div className={clsx('na position-absolute text-muted flex-column justify-content-around font-500',!rest.disabled?" bg-white":"bg-grey-new")}
      style={{top:4,right:4,bottom:4,left:14}} onClick={_clix}
    ><p className='m-0'>None</p></div>
  </>);
}

const useStyles = makeStyles({
  focused: {
    '& .na': {
      display: 'none !important'
    }
  },
  root: {
    '& .na': {
      display: 'flex'
    }
  },
});

const DurationTextField = (props) => {
  const {InputProps = {}, fullWidth, label, min, max, value, onChange, isZero,formTextFieldProps={}, noMountCb=false,...rest} = props;
  const [mounted,setMount]=useState(false);
  const {classes} = InputProps;
  const xtraClasses = useStyles(props);
  const handleChangeWrapper=(e)=>{
    if(noMountCb){
      if(mounted){
        onChange(e);
      }
      else setMount(true);
    }
    else{
      onChange(e)
    }
  }
  return (
    <FormTextField fullWidth label={label} {...formTextFieldProps}>
      <TextField {...rest}
        value={value}
        onChange={handleChangeWrapper}
        fullWidth={fullWidth}
        InputProps={{
          ...InputProps,
          inputComponent: DurationInput,
          classes: {
            ...classes,
            root: `${classes.root || ''} ${isZero ? xtraClasses.root : xtraClasses.focused} position-relative`,
            focused: xtraClasses.focused,
            input: `${classes.input || ''} h-100`,
          },
          inputProps: {
            min, max
        }}}/>
    </FormTextField>
  );
}

export {
  secondsToDuration,
  durationToSeconds,
  humanizeSeconds,
  DurationInput,
  DurationTextField
};
