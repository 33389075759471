import { Switch, withStyles } from '@material-ui/core';
import React from 'react';


const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 38,
    height: 20,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: '#FFF',
    '&$checked': {
      transform: 'translateX(18px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
    '&.Mui-checked.Mui-disabled': {
      color: '#BDBDBD',
      '& + $track': {
        backgroundColor: '#E0E0E0',
        borderColor: '#E0E0E0',
      },
    },
    '&.Mui-disabled': {
      '& + $track': {
        border: 0
      }
    }
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid #D8D8D8`,
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: '#D8D8D8',
  },
  checked: {},
}))(Switch);
export default IOSSwitch;