import React, { useContext, useRef, useState, useEffect } from "react";
import { IconButton, Typography, CardMedia, Card } from "@material-ui/core";
import CropIcon from "@material-ui/icons/Crop";
import { makeStyles } from "@material-ui/core/styles";
import { Croppy, parseImgUrl } from "croppy";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { CrossIcon } from "fitbud/icons/cross";
import { UploadIconCircle } from "fitbud/icons/upArrow";
import { withSnackbar } from "notistack";
import { fakeEvent } from "fitbud/utils/helpers";
import clsx from 'clsx';
import Loader from "fitbud/components/CircularLoader" 
import { DeleteIcon } from "fitbud/icons/delete";

const useStyles = makeStyles(props=>({
  image: {
    width:props=>{
      switch(props.size){
        case "small":return "168px";
        case "medium":return "252px";
        case "auto": return "auto";
        default: return "100%";
      }
    },
    height:props=>{
      switch(props.size){
        case "small":
        case "medium":return "168px";
        default:return "unset";
      }
    },
    position:"relative",
    cursor:props=>props.loading?"not-allowed":"pointer",
    border: "1px solid #D3D9DB",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: 3,
    '&:hover': {
      '& $overlay': {
        display: 'flex',
      },
    },
  },
  overlay: {
    display: 'none',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    top: 0, left: 0,
    width: '100%', height: '100%',
  },
}));

const helper = (url) => {
  if (!url || typeof url !== 'string') return [null, false];
  const { parsed, croppable } = parseImgUrl(url, { width: 240 });
  return [parsed.href, croppable];
};

const DEFAULT_FILE_TYPES = 'image/jpg,image/jpeg,image/png,image/gif,image/heic,image/heif';
const ImageEditor = React.memo((props) => {
  const { enqueueSnackbar, thumbnail, onChange: _onChange,
    aspectRatio = 1, id, name, className, text="", secondaryText="",
    subtitle = "Add Photo", png = false, UploadIconComponent = null,
    size='medium',loading=false,outsideControls=false,
    accept = false, purpose = false, // override accepted file types
  } = props;
  const [aspect, setAspect] = useState(aspectRatio);
  const [[imageUrl, croppable], setImageUrl] = useState(() => helper(thumbnail));
  const [imgLoading, setImgLoading] = useState(false)
  const [editSrc, setEditSrc] = useState(null);
  const classes = useStyles({size,loading});
  const inputEl = useRef(null);
  const [isContainerSmall, setIsContainerSmall] = useState(false)
  const containerRef = useRef(null)
  const { comp } = useContext(FirebaseAuthContext);
  useEffect(() => {
    if (!aspectRatio && !purpose) return;
    setAspect(aspectRatio);
    if (!comp) return;
    const compData = comp.data();
    const noaspect = Boolean(compData && compData.app_config && compData.app_config[`${purpose}_aspect_fit`]);
    if (noaspect) setAspect(0);
  }, [aspectRatio, purpose, comp]);

  const isSizeAuto = size === "auto";

  const cropImage = () => {
    setEditSrc(imageUrl)
  }
  
  const handleClick = () => {
    if(loading) return false;
    if (!imageUrl)
      return inputEl.current && inputEl.current.click();
    if (!croppable || outsideControls)
      return false;
    cropImage();
  };

  useEffect(() => {
    setImageUrl(helper(thumbnail));
  }, [thumbnail]);

  const onChange = (url) => {
    if (!_onChange) return;
    if (id || name) _onChange(fakeEvent(id || name, url));
    else _onChange(url);
  };

  const removeUpload = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setImageUrl([null, false]);
    onChange('');
  }
  const onFileSelected = (e) => {
    e.persist(); // XXX issue with React 16; see https://reactjs.org/docs/legacy-event-pooling.html
    setEditSrc(e);
  }
  const onError = (e) => {
    enqueueSnackbar('Unable to upload image at the moment. Please check your internet connection or try again later', { variant: 'error' });
  };
  const onCancel = () => {
    setEditSrc(null);
    if (inputEl && inputEl.current)
      inputEl.current.value = null;
  }
  const onCropChange = (url) => {
    const out = helper(url);
    setImageUrl(out);
    onChange(url);
    if(size === "auto") setImgLoading(true)
  }

  const handleImageLoad = () => {
    if(size === "auto") setImgLoading(false)
  }

  useEffect(() => {
    if(containerRef.current) {
      setIsContainerSmall(containerRef.current.offsetWidth <= 100)
    }
  }, [])

  return (<>
    <div className="d-flex align-items-center">
    <Card ref={containerRef} onClick={handleClick}
      className={clsx("bg-grey-new",isContainerSmall && 'overflow-unset',size === 'auto' && !imageUrl && "py-15 px-25" ,classes.image,className)} elevation={0}
      style={{...(isSizeAuto && (imgLoading || !imageUrl) && { minWidth: 180, minHeight: 80 })}}>
      {loading && <Loader centerAlign/>} 
      <CardMedia className={"w-100 h-100 position-relative d-flex flex-column justify-content-center align-items-center"}
        style={{opacity:loading?0.3:1}}
        image={size !== "auto" && imageUrl}
        key={`__media${imageUrl}`}>
        {size === 'auto' && imageUrl && (
          <img src={imageUrl} alt="toolbar logo" onLoad={handleImageLoad} style={{ maxHeight: 240, maxWidth: 240 }} />
        )}
        {!!croppable && !outsideControls && <div className={`position-absolute flex-column justify-content-center align-items-center ${classes.overlay}`}>
          <CropIcon color='inherit'/>
        </div>}
        {!!imageUrl && !outsideControls && <IconButton onClick={removeUpload}
          className="position-absolute" style={isContainerSmall ? {top: -10, right: -10, padding: 0, border: '1px solid lightgrey'} :{top:0, right:0}}>
          <CrossIcon />
        </IconButton>}
        {!imageUrl && <>
          <div className={clsx((!UploadIconComponent && (text || subtitle)) && "mb-10")}> {UploadIconComponent ? <UploadIconComponent /> : <UploadIconCircle />}</div>
          <Typography variant="subtitle1" className="text-center text-0d0d0d mb-5">{text || subtitle}</Typography>
          {secondaryText && <Typography className="text-center text-light-grey font_11_500">{secondaryText}</Typography>}
        </>}
      </CardMedia>
    </Card>
    {outsideControls && imageUrl && (
      <>
        <IconButton onClick={cropImage} className="ml-12">
          <CropIcon classes={{ root: 'small' }} />
        </IconButton>
        <IconButton onClick={removeUpload} className="ml-4px">
          <DeleteIcon classes={{ root: 'small' }} />
        </IconButton>
      </>
    )}
    </div>
    <input id={id} name={name}
      ref={inputEl}
      type="file"
      className="d-none"
      accept={accept || DEFAULT_FILE_TYPES}
      onChange={onFileSelected}
      disabled={loading}
      />
    <Croppy png={png} input={editSrc} aspect={aspect} onCancel={onCancel} onError={onError} onSave={onCropChange}>
      <Typography className='pl-4 text-white'>Crop Image</Typography>
    </Croppy>
  </>);
});

export default withSnackbar(ImageEditor);
