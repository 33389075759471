import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import notificationRepo from 'fitbud/repo/inAppNotification';
import Img from './img';
import { Chip, Divider, FormControlLabel, IconButton, Switch, Typography, Link } from '@material-ui/core';
import EditIcon from 'fitbud/icons/detailEdit';
import moment from 'moment';
import InAppNotificationDialog from './inAppNotificationDialog';
import { useDispatch } from 'react-redux';
import appRdxFns from 'fitbud/redux/app';
import { useSnackbar } from 'notistack';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { DEFAULT_ERROR } from 'fitbud/utils/constants';
import * as Sentry from '@sentry/browser';
import { ACTIONS, FREQUENCY, DAY } from './constant';
import { ChevronRightRounded } from '@material-ui/icons';
import Options from './options';
import { MoreLessTextBtn } from 'fitbud/components/moreLessText';
import Confirmation from 'fitbud/components/confirmationDialog';

function getActionLink(action, open) {
  if (action === 'allPlans') return '/plans';
  if (action === 'custom') {
    if(open?.startsWith("http")) return open
    return "//" + open
  };
  if (action === 'paymentPlan') {
    return `/plans/${open?.id}`;
  }
  if(action === 'challenges') {
    return `/challenges/${open?.id}`;
  }
  if(action === "classes"){
    return `/live/group-classes/${open?.id}`;
  }
  return `/explore/${open?.id}`;
}

const CTALink = ({ action, open }) => {
  const link = getActionLink(action, open);
  if (action === 'custom' || link.startsWith('http') || link.startsWith('//')) {
    return <a rel='noopener noreferrer' target='_blank' href={link} className='text-black text-decoration-underline'>{open}</a>;
  } else if (link.startsWith('/')) {
    return <Link component={RouterLink} to={link} className='text-black text-decoration-underline'>{open.title}</Link>;
  } else {
    return <span className='text-black text-decoration-underline'>{open.title}</span>;
  }
};

export default function NotificationInfo({ id, fetchList }) {
  const { cid, comp } = useContext(FirebaseAuthContext);
  const [openEditor, setOpenEditor] = useState(false);
  const [data, setData] = useState(null);
  const [activeConfirmation, setActiveConfirmation] = useState(false);
  const dispatch = useDispatch();
  const { hideLoader, showLoader } = appRdxFns(dispatch);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const tagOptions = comp.data().interstitial_config?.criteria?.tags || [];
  const showTags = tagOptions.length > 0;

  const { title, title_ref, image, description, _from, _till, _rules, cta1, cta2, active, criteria } = data || {};
  const { tags } = criteria || {};

  const tagsValue = useMemo(() => {
    if(!tags) return [];
    return tags.map((tag) => {
      return tagOptions.find((option) => option.value === tag);
    }).filter((tag) => !!tag);
  }, [tags, tagOptions]);
  
  const { minutes = DAY } = _rules || {};
  const frequency = useMemo(() => (minutes / DAY), [minutes]);
  const { _action: action1, label: label1, open: open1 } = cta1 || {};
  const { _action: action2, label: label2, open: open2 } = cta2 || {};
  const startDate = moment(_from, 'YYYYMMDDHHmm').format('DD MMM YY, hh:mm a');
  const endDate = moment(_till, 'YYYYMMDDHHmm').format('DD MMM YY, hh:mm a');

  const handleDelete = async () => {
    showLoader();
    try {
      const response = await notificationRepo(cid).delete(id);
      if (response) {
        fetchList();
        history.replace('/messages/in-app-notifications');
        enqueueSnackbar('Notification deleted successfully.', { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
      Sentry.captureException(error);
    } finally {
      hideLoader();
    }
  };

  const fetchData = () => {
    notificationRepo(cid)
      .doc(id)
      .then((doc) => {
        if (!doc.exists) return;
        setData(doc.data());
      });
  };

  const updateActive = async (value) => {
    const payload = { active: value };
    showLoader();
    try {
      await notificationRepo(cid).update(id, payload);
      setData((prev) => ({ ...prev, active: value }));
      fetchList();
      hideLoader();
    } catch (error) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
      hideLoader();
    }
  };

  const handleActiveToggle = (e) => {
    const checked = e.target.checked;
    if (checked) updateActive(checked);
    else setActiveConfirmation(true);
  };

  const handleConfirmation = async () => {
    await updateActive(false);
    setActiveConfirmation(false);
  };

  useEffect(() => {
    if (!cid || id === 'new') return;
    fetchData();
  }, [cid, id]);

  if (!data) return null;

  return (
    <div className="vh-100 d-flex flex-column bg-white">
      <div className="fp-25">
        <div className="d-flex">
          <Img name={title} src={image} size={360} width={180} aspect={1.5} className="rounded-10 mr-3" />
          <div className="d-flex flex-column align-items-start">
            <Typography variant="h1" className="fmb-10" style={{ fontSize: 20 }}>
              {title}
            </Typography>
            <Typography style={{ fontSize: '14px', lineHeight: 1 }} variant="h4" color="textSecondary">
              {title_ref}
            </Typography>
            <div className="flex-grow-1" />

            <FormControlLabel
              checked={active}
              classes={{ root: 'my-0', disabled: 'text-body' }}
              control={<Switch color="primary" onChange={handleActiveToggle} />}
              label={active ? 'Active' : 'Inactive'}
            />
          </div>
          <div className="flex-grow-1" />
          <div className="d-flex justify-content-center mr-n2 mt-n2 align-items-start">
            <IconButton onClick={() => setOpenEditor(true)}>
              <EditIcon />
            </IconButton>
            <Options onDelete={handleDelete} style={{ marginLeft: 8 }} />
          </div>
        </div>
        <div className="fmt-25">
          {description && <MoreLessTextBtn text={description} className="desc fmb-25" btnClassName="viewMore" />}
        </div>
        <div className="fmt-25 d-flex flex-wrap" style={{ columnGap: 110, rowGap: 20 }}>
          <div className="d-flex flex-column">
            <Typography color="primary" style={{ textTransform: 'uppercase', fontSize: 13 }}>
              Start Date &amp; Time
            </Typography>
            <Typography color="textPrimary" className="fmt-10">
              {startDate}
            </Typography>
          </div>
          <div className="d-flex flex-column">
            <Typography color="primary" style={{ textTransform: 'uppercase', fontSize: 13 }}>
              End Date &amp; Time
            </Typography>
            <Typography color="textPrimary" className="fmt-10">
              {endDate === 'Invalid date' ? '-' : endDate}
            </Typography>
          </div>
          <div className="d-flex flex-column">
            <Typography color="primary" style={{ textTransform: 'uppercase', fontSize: 13 }}>
              Frequency
            </Typography>
            <Typography color="textPrimary" className="fmt-10">
              {FREQUENCY[frequency]}
            </Typography>
          </div>
        </div>
        {showTags && tagsValue.length > 0 && (
          <div className="d-flex flex-column mt-25">
            <Typography color="primary" style={{ textTransform: 'uppercase', fontSize: 13 }}>
              Tags
            </Typography>
            <Typography color="textPrimary" className="fmt-10">
              {tagsValue?.map((tag) => (
                <Chip
                  className="rounded-20 fmr-10"
                  style={{ backgroundColor: "#f0f3f5" }}
                  key={tag.value} label={tag.label}
                />
              ))}
            </Typography>
          </div>
        )}
      </div>
      {cta1 && (
        <>
          <Divider className="dense" />
          <div className="fp-25">
            <Typography variant="h5">Primary CTA</Typography>
            <div className="d-flex fmt-25">
              <div className="d-flex flex-column" style={{ maxWidth: 260, width: '100%' }}>
                <Typography color="primary" style={{ textTransform: 'uppercase', fontSize: 13 }}>
                  CTA Text
                </Typography>
                <Typography color="textPrimary" className="fmt-10">
                  {label1}
                </Typography>
              </div>
              <div className="d-flex flex-column">
                <Typography color="primary" style={{ textTransform: 'uppercase', fontSize: 13 }}>
                  Navigate to
                </Typography>
                <Typography color="textPrimary" className="fmt-10">
                  {ACTIONS[action1] || 'Explore'} <ChevronRightRounded style={{ fontSize: 16 }} />
                  <CTALink action={action1} open={open1}/>
                </Typography>
              </div>
            </div>
          </div>
        </>
      )}
      {cta2 && (
        <>
          <Divider className="dense" />
          <div className="fp-25">
            <Typography variant="h5">Secondary CTA</Typography>
            <div className="d-flex fmt-25">
              <div className="d-flex flex-column" style={{ maxWidth: 260, width: '100%' }}>
                <Typography color="primary" style={{ textTransform: 'uppercase', fontSize: 13 }}>
                  CTA Text
                </Typography>
                <Typography color="textPrimary" className="fmt-10">
                  {label2}
                </Typography>
              </div>
              <div className="d-flex flex-column">
                <Typography color="primary" style={{ textTransform: 'uppercase', fontSize: 13 }}>
                  Navigate to
                </Typography>
                <Typography color="textPrimary" className="fmt-10">
                  {ACTIONS[action2] || 'Explore'} <ChevronRightRounded style={{ fontSize: 16 }} />
                  <CTALink action={action2} open={open2}/>
                </Typography>
              </div>
            </div>
          </div>
        </>
      )}
      {
        <Confirmation
          open={activeConfirmation}
          handleCancel={() => setActiveConfirmation(false)}
          handleChange={handleConfirmation}
          title="Please Confirm"
          msg="Are you sure you want to deactivate notification"
          confirmOption="Yes, Deactivate"
        />
      }
      {openEditor && (
        <InAppNotificationDialog
          open
          onEdit={setData}
          initialData={data}
          onClose={() => setOpenEditor(false)}
          id={id}
          onSave={fetchList}
        />
      )}
    </div>
  );
}
