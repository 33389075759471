import React, { useEffect, useState,useReducer } from "react";
import { Helmet } from "react-helmet";
import {
  makeStyles,
  Typography,
  TextField,
  LinearProgress,
  CircularProgress
} from "@material-ui/core";
import { FormTextField } from "fitbud/components/form-fields";
import {
  ClrdButton,
  PasswordField,
  isValidEmail,
} from "fitbud/components/form-fields";
import GTM from "fitbud/utils/gtm";
import { BoldLinks } from "fitbud/views/signup";
import firebase from "fitbud/firebase";
import { useSnackbar } from "notistack";
import {Banner} from "./banner";
import {removeSpace} from "fitbud/utils/helpers";
import Logo from "fitbud/components/logo";
import clsx from 'clsx';
import {Copyright} from "fitbud/components/copyright";
import { SESSION_REDIRECT_URL } from "fitbud/utils/constants";


const GTMevent = (action, args) => {
  const eventDims = args || {};
  if (typeof window === 'undefined') return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({event: action, ...eventDims});
};

const styles = makeStyles(theme=>({
  container: {
    display: "grid",
    gridTemplateColumns: "60% 40%",
    '& .logo':{
      position:"fixed",
      left:30,
      top:25
    },
    "& form": {
      maxWidth: "460px",
    },
    '& .goBack':{
      position:"absolute",
      top: 40,
      right: 40
    },
    [theme.breakpoints.down("sm")]:{
      gridTemplateColumns: "1fr",
    },
    [theme.breakpoints.up("md")]:{
      '& .field':{
        minWidth:"300px"
      }
    },
    [theme.breakpoints.down("xs")]:{
      '& form':{
        width:"100%"
      },
       '& .logo':{
        position:"static",
      },
      '& .goBack':{
      marginTop:30,
      display: 'block',
      position: 'relative',
      left: "50%",
      top: 0,
      transform: "translate(-50%)"
      }
    }
  },
  heading: {
    marginBottom: "30px",
    fontSize: "24px",
    fontWeight: "700",
    marginTop: "30px",
  },
  label: {
    color: "#06132D",
    fontSize: "12px!important",
  },
  copyright:{
    position:"absolute",
    bottom:10,
    left:"50%",
    transform:"translate(-50%)"
  }
}));
const reducer=(state,newState)=>{
  if(!Object.keys(newState).length){
    return {}
  }
  else{
    return({
      ...state,...newState
    })
  }
}
const LOGIN='login';
const FORGOT_PASSWORD='forgot-password';
const RESET_PASSWORD='reset-password';
const Signin = () => {
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();
  const [page,changePage]=useState(LOGIN);//[LOGIN,RESET_PASSWORD,FORGOT_PASSWORD]
  const [state, updateState] = useReducer(reducer,{});
  const [errors, updateErr] = useState({});
  const [loading, toggleLoading] = useState(false);
  const [resetPwdState,updateResetPwdState]=useReducer(reducer,{});
  useEffect(() => {
    if (window.location.pathname === "/reset-password") {
      changePage(RESET_PASSWORD);
      toggleLoading(true)
    };
  }, []);
  useEffect(() => {
    updateState({});
    updateErr({});
    // toggleLoading(false);
  }, [page]);
 
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validation()) return;
    toggleLoading(true);

    if (page===LOGIN) {
      //login
      firebase
        .auth()
        .signInWithEmailAndPassword(state.email.trim(), state.password.trim())
        .then(() => {
          GTMevent('login', {method: 'email'});
          const redirectUrl =   sessionStorage.getItem(SESSION_REDIRECT_URL);
          if(!!redirectUrl){
            window.history.replaceState('','',redirectUrl);
            sessionStorage.removeItem(SESSION_REDIRECT_URL);
          }
        })
        .catch(handleErr);
    } 
    else if(page===FORGOT_PASSWORD) {
      //forgot-password
      firebase
        .auth()
        .sendPasswordResetEmail(state["forgot-pass-email"])
        .then(() => {
          toggleLoading(false);
          changePage(LOGIN);
          enqueueSnackbar("Success. Please check your mail", {
            variant: "success",
          });
        })
        .catch(handleErr);
    }
    else{
      //reset-password
      const { code } = resetPwdState;
      firebase
      .auth()
      .confirmPasswordReset(code, state.password)
      .then(() => {
        toggleLoading(false);
        updateResetPwdState({done:true})
      })
      .catch(handleErr)
    }
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    if(page===RESET_PASSWORD && name==='password'){
      value=removeSpace(value);
    }
    if(name==="email"||name==="forgot-pass-email"){
      value=value.toLowerCase();
    }
    updateState({[name]:value})
  };
  const validation = () => {
    const errors = {};

    if(page===LOGIN || page===FORGOT_PASSWORD){
      const emailField = page===LOGIN ? "email" : "forgot-pass-email";
      if (
        !state[emailField] ||
        (state[emailField] && !state[emailField].trim())
      ) {
        errors[emailField] = "Email is missing";
      } else if (!isValidEmail(state[emailField].trim())) {
        errors[emailField] = "Please provide a valid email";
      }
    }
   
    if (page===LOGIN || page===RESET_PASSWORD) {
      if (!state.password) {
        errors["password"] = "Password is missing";
      }
      else if(page===RESET_PASSWORD){
        if(!!state.password && !state.password.trim()){
          errors["password"] = "Password is missing";
        }
        else if(state.password.length<6){
          errors['password']="Please select a stronger password"
        }
      }
    }
    updateErr(errors);
    return !Object.keys(errors).length;
  };
  const handleErr = (err) => {
    toggleLoading(false);
    let msg;
    if(state.page!==RESET_PASSWORD){
      msg = "The email and/or password do not match.";
      if (err.code === "auth/network-request-failed") {
        msg = err.message;
      }
    }
    else{
      msg=err.code ? err.message : "Something went wrong. Please try requesting for reset link";
    }
    enqueueSnackbar(msg, { variant: "error" });
  };
  const labelProps = {
    classes: {
      labelTxt: classes.label,
      control: "fmb-25",
    }
  };
  const props={
    //state
    state,
    loading,
    errors,
    resetPwdState,
    //fns
    toggleLoading,
    handleChange,
    handleSubmit,
    changePage,
    updateResetPwdState,
    //styles
    labelProps,
  }
  return (
    <div className={`vh-100 ${classes.container}`}>
      <Helmet>
        <title>FitBudd » Sign In</title>
      </Helmet>
      <GTM/>
      {loading && (
        <LinearProgress
          className="position-absolute w-100"
          style={{ zIndex: 9 }}
        />
      )}
      <div className="bg-offWhite d-flex justify-content-center align-items-center position-relative">
        <Copyright className={classes.copyright}/>
        {(window.location.pathname === "/reset-password"||page===RESET_PASSWORD) &&  <ResetPass {...props}  />}
        {page===LOGIN && <Login {...props}/>}
        {page===FORGOT_PASSWORD && <ForgotPass {...props}/>}
      </div>
      <Banner />
    </div>
  );
};

const Login=({state,loading,errors,handleChange,handleSubmit,changePage,labelProps})=>{
  const classes=styles();
  return(
    <>
    <form id="portal-signin" className="fp-20 text-center" onSubmit={handleSubmit}>
      <a href='https://www.fitbudd.com'>
        <Logo color="fancy" className="logo"/>
      </a>
      <Typography className={clsx(classes.heading,"text-left")}>
        Sign in with your email
      </Typography>
          <FormTextField
                fullWidth
                label="Email Address"
                required
                {...labelProps}
              >
                <TextField
                  name="email"
                  required
                  value={state.email}
                  onChange={handleChange}
                  variant="outlined"
                  error={errors.email}
                  helperText={errors.email}
                  inputProps={{
                    autocomplete:"email"
                  }}
                  InputProps={{
                    classes: {
                      root: "medium",
                      input: "size_16_500 h-100 field bg-white",
                    },
                  }}
                  disabled={loading}
                />
              </FormTextField>
              <FormTextField
                fullWidth
                label="Password"
                className="position-relative"
                {...labelProps}
              >
                <PasswordField
                  name="password"
                  required
                  value={state.password}
                  onChange={handleChange}
                  variant="outlined"
                  error={errors["password"]}
                  helperText={errors["password"]}
                  disabled={loading}
                  inputProps={{
                    autocomplete:"current-password"
                  }}
                  rootClassName="bg-white"
                  inputClassName="bg-white"
                  
                />
              </FormTextField>
              <ClrdButton
                color="main"
                type="submit"
                className="f-xxxlarge fmb-25"
                disabled={loading}
                classes={{label:"font_16_600 font-weight-700"}}
              >
            Sign In
          </ClrdButton>
          <Typography variant="body2" className="font-weight-400 d-flex justify-content-center">
              <span className="fmr-5">Don’t have an account?</span>
              <BoldLinks to="/sign-up" target="_self">
                Sign Up
              </BoldLinks>
            </Typography>
            <ClrdButton
            className="fmt-30"
            onClick={() => changePage(FORGOT_PASSWORD)}
          >
            Forgot Password ?
          </ClrdButton>
    </form>
    </>
  )
}
const ForgotPass=({state,loading,errors,handleChange,handleSubmit,changePage,labelProps})=>{
  const classes=styles();
  return(
    <>
      <form id="portal-forgot-password" className="fp-20 text-center" onSubmit={handleSubmit}>
      <Logo color="fancy" className="logo"/>
      <Typography className={clsx(classes.heading,"text-left")}>
          Forgot Password
      </Typography>
      <FormTextField
          fullWidth
          label="Email Address"
          required
          {...labelProps}
        >
          <TextField
            name="forgot-pass-email"
            value={state["forgot-pass-email"]}
            onChange={handleChange}
            variant="outlined"
            error={errors["forgot-pass-email"]}
            helperText={errors["forgot-pass-email"]}
            inputProps={{
              autocomplete:"email"
            }}
            InputProps={{
              classes: {
                root: "medium",
                input: "size_16_500 h-100 field bg-white",
              },
            }}
            disabled={loading}
          />
        </FormTextField>
        <ClrdButton
        color="main"
        type="submit"
        className="f-xxxlarge fmb-25"
        disabled={loading}
        classes={{label:"font_16_600 font-weight-700"}}
      >
        Proceed
      </ClrdButton>
      <ClrdButton
          onClick={() => changePage(LOGIN)}
          variant="text"
          color="primary"
        >
          Sign in
        </ClrdButton>
        <ClrdButton
        onClick={() => changePage(LOGIN)}
        className="goBack"
        >
          Go Back
        </ClrdButton>
      </form>
    </>
  )
}

const ResetPass=({state,loading,errors,resetPwdState,toggleLoading,handleChange,handleSubmit,changePage,updateResetPwdState,labelProps})=>{
  const classes=styles();
  useEffect(()=>{
    const codeMatch = window.location.search.match(/oobCode=([^&#]*)/);
    const keyMatch = window.location.search.match(/apiKey=([^&#]*)/);
    if (!codeMatch || !keyMatch) {
      setTimeout(()=>{
        changePage(LOGIN); 
        toggleLoading(false)
      },0);
      return;
    };
    firebase.auth().verifyPasswordResetCode(codeMatch[1]).then((mail=>{
      updateResetPwdState({
        code: codeMatch[1],
        mail
      });
      toggleLoading(false)
    })).catch(err=>{
      window.alert("Your request to reset your password has expired or the link has already been used. Please proceed back to the login page and request for a new reset link");
      window.location.href = "/";
    })
  },[])
  if(loading) return <CircularProgress/>
  return(
      <form className="fp-20 text-center" onSubmit={handleSubmit}>
      <Typography className={classes.heading}>
        Reset Password
     </Typography>
     {!resetPwdState.done?(<>
      <Typography className="fmb-20 font_18_500">Choose a new Password for <b>{resetPwdState.mail}</b></Typography>
     <FormTextField
                fullWidth
                className="position-relative"
                {...labelProps}
              >
                <PasswordField
                  name="password"
                  placeholder="New Password"
                  required
                  value={state.password}
                  onChange={handleChange}
                  variant="outlined"
                  error={errors["password"]}
                  helperText={errors["password"]}
                  disabled={loading}
                  InputProps={{
                    classes:{
                      input:"field"
                    }
                  }}
                />
              </FormTextField>
              <ClrdButton
              color="main"
              type="submit"
              className="f-xxxlarge fmb-25"
              disabled={loading}
              classes={{label:"font_16_600 font-weight-700"}}
            >
            Proceed
          </ClrdButton>
          <Typography variant="body2" className="font-weight-400">
              Already have an account?{" "}
              <BoldLinks to="/login" target="_self">
              Login
              </BoldLinks>
            </Typography>
       </>):(
     <Typography className="fmb-20 font_18_500">Password for {resetPwdState.mail} has been reset succesfully. Proceed to{" "}
          <BoldLinks to="/login" target="_self">
            Login
          </BoldLinks>
      </Typography>
     )}
          
     </form>
  )
}

export default Signin;
