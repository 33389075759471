import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function CalendarFold({ ...props }) {
  return (
    <SvgIcon viewBox="0 0 17 16" {...props}>
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.55389 2V4.39961"
          stroke="#317FF5"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.3531 2V4.39961"
          stroke="#317FF5"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.3527 11.0058V4.40684C14.3527 4.08863 14.2263 3.78345 14.0013 3.55845C13.7763 3.33344 13.4711 3.20703 13.1529 3.20703H4.75425C4.43604 3.20703 4.13087 3.33344 3.90586 3.55845C3.68085 3.78345 3.55444 4.08863 3.55444 4.40684V12.8055C3.55444 13.1237 3.68085 13.4289 3.90586 13.6539C4.13087 13.8789 4.43604 14.0053 4.75425 14.0053H11.3532L14.3527 11.0058Z"
          stroke="#317FF5"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.55444 6.80469H14.3527"
          stroke="#317FF5"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.7533 14.0057V11.6061C10.7533 11.2878 10.8797 10.9827 11.1047 10.7577C11.3297 10.5327 11.6349 10.4062 11.9531 10.4062H14.3527"
          stroke="#317FF5"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
