import React, {
  useEffect,
  useContext,
  useMemo,
  useState,
  useCallback,
} from "react";
import { get } from "lodash";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import {
  FormControlLabel,
  Typography,
  makeStyles,
  Container,
  Card,
  Checkbox,
  Button,
} from "@material-ui/core";
import ReactPlayer from "react-player";
import clsx from "clsx";
import { FormTextField } from "fitbud/components/form-fields";
import AutoSuggest from "fitbud/components/autoSuggest";
import {
  mapIdToObject,
} from "./helperfn";
import { MediaUploader } from "fitbud/components/media-uploader/mediaUploader";
import { DurationTextField } from "fitbud/components/durationInput";
import Dialog from "fitbud/components/Dialog";
import ImageFileUpload from "fitbud/components/imageSelector";
import { ClrdButton } from "fitbud/components/form-fields";
import VideoFeatures from "fitbud/components/videoFeatures";
import {CroppyContext} from "croppy";
import {DEFAULT_MEDIA_TYPE,MUSIC_ON,MUSIC_OFF} from "fitbud/utils/constants"
import { captureFrame, fetchVideoThumbnail } from "fitbud/utils/services";
import * as Sentry from '@sentry/browser';


const styles = makeStyles({
  card: {
    padding: "25px",
    borderRadius: "10px",
    border: "1px solid #DDDDDD",
  },
  button: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    borderRadius: "10px",
    border: "1px solid #DDDDDD",
    width: "100%",
    height: "48px",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
  thumbnail: {
    borderRadius: 6,
    marginBottom: 15,
  },
  frameSelectorBody: {
    backgroundColor: "rgba(0, 0, 0, 0.85)!important",
  },
  blueLabel: {
    borderRadius: 40,
    height: 30,
    cursor: "default",
    marginLeft: 10,
    pointerEvents: "none",
  },
});

export const VideoEditor = (props) => {
  const {
    doc,
    errors,
    equipmentsData,
    targetAreasData,
    handleChange,
    handleMedia,
    removeMedia,
    updateMediaMeta,
    isNew,
    videoStatusRefresh,
    videoUploadError={}
  } = props;
  const {reupload} =useContext(CroppyContext);
  const { comp } = useContext(FirebaseAuthContext);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [selectedTargetArea, setSelectedTargetArea] = useState([]);
  const [isDurationDirty, setDurationDirty] = useState(
    doc.is_single_video ? doc.duration !== doc.total_duration : false
  );
  const [isFrameSelectorOpen, toggleFrameSelector] = useState(false);
  const [canUseVideoThumbnail, allowUseVideoThumbnail] = useState(!!isNew);
  const [isThumbnailLoading,toggleThumbnailLoading]=useState(false);

  const supportsBgOff=useMemo(() => {
    if (!comp || !comp.exists) return false;
    return get(comp.data(), "features.bgoff", false);
  }, [comp]);

  useEffect(() => {
    const _selectedTargetArea = mapIdToObject(targetAreasData, doc.target_area);
    setSelectedTargetArea(_selectedTargetArea);
  }, [doc.target_area, targetAreasData]);
  useEffect(() => {
    const _selectedEquipments = mapIdToObject(equipmentsData, doc.equipments);
    setSelectedEquipments(_selectedEquipments);
  }, [doc.equipments, equipmentsData]);
  const handleChangeWrapper = (e) => {
    const {name,value}=e.target;
    if(name==="target_area"||name==="equipments"){
      const newValues=value.map(val => val.value);
      handleChange({target:{name:name,value:newValues}})
    }
    else handleChange(e);
  };
  const handleMediaWrapper = (args, action = "add") => {
    const type = args.at(-1);
    if (action === "add") {
      if (!get(args, "1", null) && type === MUSIC_ON) {
        //if no media exists
        setDurationDirty(false);
      }
      if (!doc.thumbnail && !!get(args, "1", null)) {
        allowUseVideoThumbnail(true);
      }
      handleMedia(...args);
    }
      else{
      //remove media
      if(type===MUSIC_ON){
        setDurationDirty(false);
      }
      removeMedia(args);
    }
  };
  //--callback with media duration---
  const setDuration = (secs,mediaType) => {
    const value = Math.round(secs);
    updateMediaMeta(mediaType,{duration:value});
    if(mediaType===MUSIC_OFF) return;
    //mount cb case--return if no change
    if (value === doc.total_duration) return;
    //--mount cb case - end
    if (!isDurationDirty) {
      handleChangeWrapper({
        target: { id: "duration", value },
      });
    }
    handleChangeWrapper({
      target: { id: "total_duration", value },
    });
  };
  const handleError=(err)=>{
    console.log("onError",err);
    Sentry.captureException(err);
  }
  const whenPlayerReady = useCallback(
    (player) => {
      try{
        const id=player.props.id==='media_bgon'?MUSIC_ON:MUSIC_OFF;
        //video height
        const videoHeight=player.getInternalPlayer().videoHeight;
        if(!!videoHeight){
          updateMediaMeta(id,{height:`${videoHeight}p`});
        }
        if (id===MUSIC_ON && canUseVideoThumbnail) {
          fetchThumbnail();
        }
        if (
          !player ||
          !player.getInternalPlayer() ||
          !player.getInternalPlayer().getPlayerState
        )
          return;
        player.getInternalPlayer().playVideo();
        const x = window.setInterval(() => {
          if (
            !player ||
            !player.getInternalPlayer() ||
            !player.getInternalPlayer().getPlayerState ||
            player.getInternalPlayer().getPlayerState() !== 1
          )
            return;
          window.clearInterval(x);
          player.getInternalPlayer().pauseVideo();
          // setDuration(Math.round(player.getDuration()));
        }, 100);
      }
      catch(err){
        console.log("onReady err",err);
        Sentry.captureException(err);
      }
    },
    [doc.media,doc.media_bgoff, canUseVideoThumbnail]
  ); // eslint-disable-line react-hooks/exhaustive-deps
  const toggleDurationCheck = (e) => {
    const value = e.target.checked;
    if (!!value) {
      handleChangeWrapper({
        target: { id: "duration", value: doc.total_duration },
      });
      setDurationDirty(false);
    } else {
      setDurationDirty(true);
    }
  };
  const selectFrame = async () => {
    toggleThumbnailLoading(true);
    const thumbnail = await captureFrame(true);
    closeFrameSelector();
    if (thumbnail) {
      await uploadThumbnail(thumbnail);
    }
    toggleThumbnailLoading(false);
  };
  const openFrameSelector=()=>{
    toggleFrameSelector(true);
  }
  const closeFrameSelector=()=>{
    toggleFrameSelector(false);
  }
  const uploadThumbnail=async(thumbnail)=>{
    try {
      const uploaded = await reupload(thumbnail);
      handleChangeWrapper({
        target: {
          name: "thumbnail",
          value: uploaded,
        },
      });
    } catch (err) {
      //something gone wrong;
      console.log(err, "err");
    } 
  }

  const { thumbnail, media } = doc;
  const classes = styles();
  const { type: mediaType, url: mediaUrl } = get(media, "0", {});
  const showChangeFrameBtn = mediaType === "image" || mediaType === "video";
  const showYTButton = mediaType === "youtube";
  const showVimeoButton = mediaType === "vimeo";
  const showSecondaryVid=useMemo(()=>{
    return supportsBgOff && get(doc, "media.0.type") !== "youtube"
  },[get(doc,'media.0.type',""),supportsBgOff]);
  const secondaryMedia=useMemo(()=>{
    return [get(doc,"media_bgoff",{})]
  },[get(doc,"media_bgoff",{})]);

  const fetchThumbnail = useCallback(async () => {
    try {
      toggleThumbnailLoading(true);
      const thumbnail = await fetchVideoThumbnail(media);
      if(thumbnail) await uploadThumbnail(thumbnail);
      allowUseVideoThumbnail(false);
    } catch(err){
      console.log(err, ">>");
    } finally { toggleThumbnailLoading(false) }
   // eslint-disable-next-line react-hooks/exhaustive-deps, no-use-before-define
  }, [media]);

  return (
    <>
      <Container
        maxWidth="lg"
        className={clsx("d-flex")}
        style={{ padding: "20px 7%", gap: 20 }}
      >
        <div style={{ flex: 2.2 }}>
          <Card className={clsx(classes.card, "mb-20", doc.intro ? 'd-none' : '')}>
            <Typography className="font_16_600 fmb-20">
              Equipment & Target Area
            </Typography>
            <FormTextField fullWidth label="Equipment">
              <AutoSuggest
                label="Add equipment ..."
                onChange={(option) =>
                  handleChangeWrapper({
                    target: { name: "equipments", value: option || [] },
                  })
                }
                options={equipmentsData}
                value={selectedEquipments}
              />
            </FormTextField>
            <FormTextField
              fullWidth
              label="Target Area"
              classes={{ control: "mb-0" }}
            >
              <AutoSuggest
                label="Add target area ..."
                onChange={(option) =>
                  handleChangeWrapper({
                    target: { name: "target_area", value: option || [] },
                  })
                }
                options={targetAreasData}
                value={selectedTargetArea}
              />
            </FormTextField>
          </Card>
          <Card className={clsx(classes.card, "mb-20")}>
            <MediaUploader
              label={<AddVideoWMusic blueTitle={showSecondaryVid && !doc.intro && "With Music"} />}
              classes={{
                label: "font_16_600",
              }}
              media={doc.media}
              errors={errors}
              handleChange={handleChangeWrapper}
              handleMedia={(...args) => handleMediaWrapper([...args, MUSIC_ON])}
              removeMedia={() => handleMediaWrapper(MUSIC_ON, "remove")}
              doc={doc}
              featureType="workout"
              showFeature={false}
              videoPlayerSize="WoFormUploader"
              playerProps={{
                onDuration: v=>setDuration(v,MUSIC_ON),
                onReady: whenPlayerReady,
                onError:handleError,
                id: "media_bgon",
              }}
              videoStatusRefresh={()=>videoStatusRefresh("media_bgon")}
              videoUploadError={videoUploadError.media_bgon}
              isNew={isNew}
            />
          </Card>
          {showSecondaryVid && !doc.intro && (
            <Card className={clsx(classes.card)}>
              <MediaUploader
                label={<AddVideoWMusic blueTitle="Without Music" />}
                classes={{
                  label: "font_16_600",
                }}
                media={secondaryMedia}
                handleMedia={(...args) => handleMediaWrapper([...args, MUSIC_OFF])}
                removeMedia={() => handleMediaWrapper(MUSIC_OFF, "remove")}
                doc={doc}
                featureType="workout"
                showFeature={false}
                videoPlayerSize="WoFormUploader"
                playerProps={{ 
                  onDuration: v=>setDuration(v,MUSIC_OFF),
                  onReady: whenPlayerReady,
                  onError:handleError,
                  id: "media_bgoff"
                 }}
                hideYoutube={true}
                videoStatusRefresh={()=>videoStatusRefresh("media_bgoff")}
                videoUploadError={videoUploadError.media_bgoff}
                isNew={isNew}
                />
            </Card>
          )}
        </div>
        <div style={{ flex: 1 }}>
          <Card className={clsx(classes.card, "mb-20 pb-0")}>
            <Typography className="font_16_600 fmb-25">
              Workout Duration
            </Typography>
            <DurationTextField
              fullWidth
              variant="outlined"
              InputProps={{
                classes: { root: "medium", input: "size_16_500 font_20_700" },
              }}
              value={doc["duration"]}
              id="duration"
              onChange={(e) => {
                handleChangeWrapper(e);
              }}
              disabled={!isDurationDirty}
              error={!!errors.duration}
              helperText={errors.duration}
              formTextFieldProps={{
                classes: {
                  control: "mb-15",
                },
              }}
              noMountCb={true}
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={toggleDurationCheck}
                  checked={!isDurationDirty}
                  color="primary"
                />
              }
              label="Infer from video duration"
              classes={{ label: "text-nowrap", root: "mb-10" }}
            />
          </Card>
          <Card className={clsx(classes.card, "mb-20")}>
            <Typography className="font_16_600 fmb-25">
              Workout Thumbnail
            </Typography>
            <div style={{ width: "fit-content" }}>
              <ImageFileUpload
                id="thumbnail" purpose="wo"
                thumbnail={thumbnail}
                onChange={handleChangeWrapper}
                size="small"
                className={classes.thumbnail}
                loading={isThumbnailLoading}
              />
              {showChangeFrameBtn && (
                <ClrdButton
                  color="light-primary"
                  onClick={openFrameSelector}
                  variant="contained"
                  className="f-sm-med w-100"
                  disableElevation
                  disabled={!mediaUrl || isThumbnailLoading}
                >
                  Select Frame
                </ClrdButton>
              )}
              {showYTButton && (
                <ClrdButton
                  color="light-primary"
                  onClick={fetchThumbnail}
                  variant="contained"
                  className="f-sm-med w-100"
                  disableElevation
                  disabled={!mediaUrl || isThumbnailLoading}
                >
                  Import from youtube
                </ClrdButton>
              )}
              {showVimeoButton && (
                <ClrdButton
                  color="light-primary"
                  onClick={fetchThumbnail}
                  variant="contained"
                  className="f-sm-med w-100"
                  disableElevation
                  disabled={!mediaUrl || isThumbnailLoading}
                >
                  Import from vimeo
                </ClrdButton>
              )}
            </div>
          </Card>
          <Card className={clsx(classes.card, doc.intro ? 'd-none' : '')}>
            <VideoFeatures
              handleChange={handleChangeWrapper}
              mediaType={get(doc, `media.${0}.type`, DEFAULT_MEDIA_TYPE)}
              doc={doc}
              featureType="workout"
            />
          </Card>
        </div>
      </Container>
      {isFrameSelectorOpen && (
        <FrameSelectionDialog
          onClose={closeFrameSelector}
          selectFrame={selectFrame}
          {...doc}
        />
      )}
    </>
  );
};

export const FrameSelectionDialog = ({ onClose, media, selectFrame }) => {
  const _m = get(media, "0", {});
  let _mediaUrl = _m && (_m.s3Url || _m.url) ? _m.s3Url || _m.url : "";
  if (_m && _m.url && _m.url.name) {
    _mediaUrl = URL.createObjectURL(_m.url);
  }
  const classes = styles();
  return (
    <Dialog
      toolbarClass="height-40"
      open
      onClose={onClose}
      fullScreen
      appBarColor="bg-white"
      title="Select Frame"
      actionText="Save"
      onSave={selectFrame}
      buttonColor="main"
      paperClass={classes.frameSelectorBody}
    >
      <ReactPlayer
        // playIcon={<PlayIcon style={{..._playIconStyle}} />}
        height={"100%"}
        width={"100%"}
        controls={true}
        url={_mediaUrl}
        id="frame-selector"
      />
    </Dialog>
  );
};

const AddVideoWMusic = ({ blueTitle }) => {
  const classes = styles();
  return (
    <div className="d-flex align-items-center">
      <span>Add Video</span>
      {blueTitle && <Button
        disableRipple
        className={classes.blueLabel}
        component="div"
        variant="outlined"
        color="primary"
      >
        {blueTitle}
      </Button>}
    </div>
  );
};
