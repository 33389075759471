import { IconButton, Popover, Typography, makeStyles } from '@material-ui/core';
import Close from '@material-ui/icons/CloseRounded';
import clsx from 'clsx';
import _ from 'lodash';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { NEXT_BOOKING_THRESHOLD } from 'fitbud/utils/constants';
import { findBookingStatus, SESSIONS_OPTIONS } from 'fitbud/utils/scheduling';
import React, { useMemo, useContext, useState, useRef } from 'react';
import { BookingDetails } from '../subComps/bookingsDetails';
import { JoinButton } from '../subComps/joinCallButton';
import { useLiveSessions } from 'fitbud/providers/liveSessionsProvider';
import GcInstanceDrawer from 'fitbud/views/groupClasses/gcInstance';
import useLiveTime, { CLASS_RUNNING_STATUS } from 'fitbud/hooks/useLiveTime';
import { JoinLiveGroupClass } from 'fitbud/views/groupClasses/components/countDown';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { MenuList } from 'fitbud/components/menuList';
import { ClrdButton } from 'fitbud/components/form-fields';
import { useClassesSlotStyles, useAppointmentSlotStyle } from './styles';

const useClasses = makeStyles(() => ({
  icon: {
    borderRadius: 4,
    border: '1px solid #D3D9DB',
    marginRight: 16,
  },
}));

export const TooltipHeader = ({ session, handleClose, isGroupClass }) => {
  const status = findBookingStatus(session);
  const appointmentClass = useAppointmentSlotStyle();
  const classesClass = useClassesSlotStyles();
  return (
    <div className={clsx(isGroupClass ? classesClass.toolTipHeader : appointmentClass.toolTipHeader, 'w-100')}>
      <div className="d-flex justify-content-between align-items-center w-100 fpx-15">
        <div className="text-dark fpy-15">
          <Typography variant="h6" noWrap className="text-capitalize text-0d0d0d">
            {status} Session
          </Typography>
        </div>
        <div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export const GroupClassToolTipAction = (props) => {
  const { session, className } = props;
  const classes = useClasses();
  const instanceId = _.get(session, '_id');
  const {comp} = useContext(FirebaseAuthContext);
  const company = comp ? comp.data() : {};
  const isMindBody = _.get(company, "features.mindbody", false);

  const [open, setOpen] = useState(false);
  const openInstanceDetail = () => setOpen(true);
  const closeInstanceDetail = () => setOpen(false);
  const refreshClass = (id, cb) => {
    cb && cb();
  };
  const usersMemo = useRef({});
  const { startDate, duration } = session;
  const [liveStatus] = useLiveTime(startDate, duration, NEXT_BOOKING_THRESHOLD * 60);
  const [anchorEl, setAnchorEl] = useState(null);
  const options = useMemo(() => {
    if (liveStatus === CLASS_RUNNING_STATUS.LIVE || liveStatus === CLASS_RUNNING_STATUS.UPCOMING)
      return [{ label: 'View Detail', value: SESSIONS_OPTIONS.GROUP_CLASS_DETAILS }];
    else return [];
  }, [liveStatus]);
  const openMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const closeMenu = (e) => setAnchorEl(null);
  const onMenuSelect = (e, type) => {
    e.preventDefault();
    if (type === SESSIONS_OPTIONS.GROUP_CLASS_DETAILS) {
      openInstanceDetail();
    }
    closeMenu();
  };
  const isOptionsAvailable = !!_.get(options, 'length', 0);
  if(isMindBody) return null;

  return (
    <div className={clsx(className, 'd-flex align-items-center mt-20')}>
      {isOptionsAvailable && (
        <IconButton className={clsx(classes.icon)} onClick={openMenu}>
          <MoreVertIcon />
        </IconButton>
      )}
      {liveStatus === CLASS_RUNNING_STATUS.LIVE || liveStatus === CLASS_RUNNING_STATUS.UPCOMING ? (
        <JoinLiveGroupClass className="mb-10 rounded" data={session} />
      ) : (
        <ClrdButton variant="outlined" className="f-large flex-grow-1" onClick={openInstanceDetail}>
          <Typography className="font_14_600 text-0d0d0d">View Detail</Typography>
        </ClrdButton>
      )}
      {isOptionsAvailable && (
        <MenuList
          anchorEl={anchorEl}
          onClose={closeMenu}
          options={options}
          handleClick={onMenuSelect}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        />
      )}
      {open && (
        <GcInstanceDrawer
          usersMemo={usersMemo}
          refreshClass={refreshClass}
          open={open}
          onClose={closeInstanceDetail}
          instanceId={instanceId}
        />
      )}
    </div>
  );
};

export const OneToOneToolTipAction = (props) => {
  const { session} = props;
  const { startDate, duration } = session;
  const classes = useClasses();
  const { openBookingDialog, openCancelDialog } = useLiveSessions();
  const [anchorEl, setAnchorEl] = useState(null);
  const [liveStatus] = useLiveTime(startDate, duration, NEXT_BOOKING_THRESHOLD * 60);
  const options = useMemo(() => {
    if (liveStatus === CLASS_RUNNING_STATUS.BEFORE)
      return [{ label: 'Cancel', value: SESSIONS_OPTIONS.CANCEL_VIDEO_CALL }];
    else if ([CLASS_RUNNING_STATUS.UPCOMING, CLASS_RUNNING_STATUS.LIVE].includes(liveStatus))
      return [
        {
          label: 'Reschedule',
          value: SESSIONS_OPTIONS.SCHEDULE_VIDEO_CALL,
        },
        {
          label: 'Cancel',
          value: SESSIONS_OPTIONS.CANCEL_VIDEO_CALL,
        },
      ];
    else return [];
  }, [liveStatus]);
  const openMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const closeMenu = (e) => setAnchorEl(null);
  const onMenuSelect = (e, type) => {
    e.preventDefault();
    if (type === SESSIONS_OPTIONS.CANCEL_VIDEO_CALL) {
      openCancelDialog(session, true);
    } else if (type === SESSIONS_OPTIONS.SCHEDULE_VIDEO_CALL) {
      openBookingDialog(session, true);
    }
    closeMenu();
  };
  const isOptionsAvailable = !!_.get(options, 'length', 0);
  if (liveStatus === CLASS_RUNNING_STATUS.PAST) return null; // if past not showing action in case of one to one...
  return (
    <div className="d-flex align-items-center mt-20">
      <IconButton disabled={!isOptionsAvailable} className={clsx(classes.icon)} onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      {liveStatus === CLASS_RUNNING_STATUS.BEFORE && (
        <ClrdButton
          variant="outlined"
          className="f-large flex-grow-1"
          onClick={() => {
            openBookingDialog(session, true);
          }}>
          <Typography className="font_14_600 text-0d0d0d">Reschedule</Typography>
        </ClrdButton>
      )}
      {(liveStatus === CLASS_RUNNING_STATUS.LIVE || liveStatus === CLASS_RUNNING_STATUS.UPCOMING) && (
        <JoinButton session={session} />
      )}
      {isOptionsAvailable && (
        <MenuList
          anchorEl={anchorEl}
          onClose={closeMenu}
          options={options}
          handleClick={onMenuSelect}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        />
      )}
    </div>
  );
};

export const AppointmentTooltip = (props) => {
  const { session, open, anchorEl, handleClose } = props;
  const isGroupClass = !!session.class;
  return (
    <Popover
      onClose={handleClose}
      open={open}
      anchorEl={anchorEl}
      id={session.book_id}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      <div className="d-flex flex-column bg-white w-100">
        <TooltipHeader session={session} handleClose={handleClose} isGroupClass={isGroupClass} />
        <div className="p-20">
          <BookingDetails session={session} />
          {!!isGroupClass ? <GroupClassToolTipAction {...props} /> : <OneToOneToolTipAction {...props} />}
        </div>
      </div>
    </Popover>
  );
};
