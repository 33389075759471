import { makeStyles, Typography } from '@material-ui/core';
import { GRAPH_LINE_COLOR } from 'fitbud/utils/constants';
import { numFormatter, roundNumber, secondsToMinuteFormatted } from 'fitbud/utils/helpers';
import React,{useContext,useMemo} from 'react';
import { Bar,ComposedChart, Area, CartesianGrid, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import {BarChartLegend} from "fitbud/icons/barchartLegend";
import {LineChartLegend} from "fitbud/icons/linechartLegend";
import {Conversion} from "fitbud/providers/conversion";

// SUPPORTED DATA STRUCTURE
// [
//   {
//     "dataKey-X": "11 Sep",
//     "dataKey-Y": 90,
//   },
//   {
//     "dataKey-X": "12 Sep",
//     "dataKey-Y": 85,
//   },
// ]
const useStyles = makeStyles((theme) => ({
  graphRoot: {
    marginLeft: '-20px',
    width:"calc(100% + 20px )"
  }
}));
const CustomizedLabel = (props) => {
  return (
    <g>
      <svg width="21" height="21" x={props.viewBox.x + 350} y={props.viewBox.y - 10}>
        <circle cx="10.8624" cy="10.1532" r="10.1183" fill="#F2937B" fillOpacity="0.2"/>
        <circle cx="10.8625" cy="10.1533" r="3.72778" fill="#F3997F"/>
      </svg>
      <text x={props.viewBox.x + 350} y={props.viewBox.y} fill="#111" dy={20} fontSize='10px' fontWeight='600'>
        Goal
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload, label, valueLabel, unit, labelFormatter, type=''}) => {
  if (active && payload && payload.length) {
    const { value } = payload[0];
    return (
      <div className="d-flex flex-nowrap fp-10" style={{backgroundColor:'#0D0D0D', borderRadius:'3px'}}>
        <div className='fmr-10 text-white'>
          <Typography style={{opacity:'60%', fontSize: '11px' }} align='right' className='fmb-5'>
            {valueLabel || 'Value'}
          </Typography>
          <Typography style={{opacity:'60%', fontSize: '11px' }} align='right'>
            Date
          </Typography>
        </div>
        <div className='flex-grow-1'>
          <Typography className="text-white fmb-5" style={{ fontSize: '11px' }}>
            {type.includes('duration') ?
              secondsToMinuteFormatted(
              (type === 'duration_mins' ? value * 60 : value), 
              { toString: true, secondsOnly: type !== 'duration_mins' })
            : numFormatter(roundNumber(value, 1)) || '-'}
            {' '}
            {type.includes('duration') ? '' : (!!value && unit) || ''}
          </Typography>
          <Typography className="text-white" style={{ fontSize: '11px' }}>
            {!!labelFormatter ? labelFormatter(label) : label}
          </Typography>
        </div>
      </div>
    );
  }
  return null;
};

const AreaGraph = ({data:_data, color=GRAPH_LINE_COLOR, goal=false, tooltipConfig=true, type,secondary=null }) => {
  const classes = useStyles();
  const {convertor} = useContext(Conversion);
  const primaryKey=!!secondary && secondary==="weight"?"value2":"value";
  const secondaryKey=!!secondary && secondary==="weight"?"value":"value2";
  const data=useMemo(()=>_data.reverse(),[_data]);
  return (
    <div>
      <ResponsiveContainer height={300}>
        <ComposedChart data={data} className={classes.graphRoot}>
          <CartesianGrid strokeDasharray="5 5" vertical={!!secondary} width={"100%"}/>
          {!!secondary && <Bar yAxisId={"right"} barSize={20} fill={'url(#color2)'} radius={[7, 7, 0 , 0]} dataKey={secondaryKey} isAnimationActive={false}/>}
          <defs>
            <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
              <stop offset="10%" stopColor={color} stopOpacity={0.9} />
              <stop offset="45%" stopColor={color} stopOpacity={0.8} />
              <stop offset="98%" stopColor="#FFFFFF" stopOpacity={0.3} />
            </linearGradient>
          </defs>
          <Area yAxisId="left" dot={{r:3,fill:"#4089F7"}} dataKey={primaryKey} isAnimationActive={false} fill={'url(#color1)'} stroke='#4089F7' strokeWidth={2} activeDot={{r:3}} />
          <defs>
            <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
              <stop offset="55%" stopColor="#4089F7" stopOpacity={0.2} />
              <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          {!!goal && <ReferenceLine yAxisId="left" isFront={true} y={goal} label={CustomizedLabel} stroke="#70829B" strokeDasharray="3 3" ifOverflow={"extendDomain"} />}
          <XAxis
            dataKey="date"
            tick={{fontSize: 12.74 , fontWeight: 500, fill:"#0A0A0A" }} 
            axisLine={false}
            tickLine={false} 
            tickMargin={10}
            />
          <YAxis
            yAxisId='left'
            tickFormatter={numFormatter} 
            axisLine={false} 
            tick={{fontSize: 12.74, fontWeight: 500, fill:"#0A0A0A", }} 
            tickLine={false} 
            allowDecimals={false}
            dataKey={primaryKey}
            scale="linear"
            padding={{top: 20, bottom: 20}}
            domain={[(dataMin) => Math.floor(dataMin) , (dataMax) => Math.ceil(dataMax)]}
          />
          {!!secondary && <YAxis
            yAxisId='right'
            tickFormatter={numFormatter} 
            axisLine={false} 
            tick={{fontSize: 12.74, fontWeight: 500, fill:"#0A0A0A", }} 
            tickLine={false} 
            allowDecimals={false}
            dataKey={secondaryKey}
            orientation='right'
          />}
          <Tooltip
            type={type === 'duration' ? data.some(({ unit='' }) => unit === 'mins') ? 'duration_mins' : 'duration_seconds' : type}
            cursor={{fill: 'rgba(206, 206, 206, 0.2)'}}
            content={<CustomTooltip />} 
            {...tooltipConfig}
          />
        </ComposedChart>
      </ResponsiveContainer>
      {!!secondary && <div className='mt-20 w-100 d-flex justify-content-center align-items-center'>
        <LineChartLegend/>
        <Typography className='font_15_500 mr-60 ml-10'>{convertor.getDisplayUnit(type)}</Typography>
        <BarChartLegend style={{color}}/>
        <Typography className='font_15_500 ml-10'>{convertor.getDisplayUnit(secondary)}</Typography>
      </div>}
    </div>
  );
}

export default AreaGraph;