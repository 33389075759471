import moment from "moment";
import _ from "lodash";
import React, { useContext, useState, useMemo, useCallback, useEffect } from "react";
import uuid from "uuid/v4";
import InfiniteScroll from "react-infinite-scroll-component";
import firebase from "fitbud/firebase";
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { handleError, snapshotDocsArrayToDataArray } from "fitbud/utils/helpers";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import {
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  LinearProgress,
  Link,
  Divider,
  Menu,
  MenuItem,
  ListItemAvatar,
  makeStyles,
  Chip
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import FilterView from "fitbud/components/FilterView";
import PlanFilter from 'fitbud/views/users/PlanFilter';
import TagFilter from 'fitbud/views/users/TagFilter';
import AllClientsQuickFilterCheckbox from 'fitbud/views/users/AllClientsQuickFilter';
import groupChatReduxFns from "fitbud/redux/groupChatList";
import appRdxFns from "fitbud/redux/app";
import { ListStyling } from "fitbud/hooks/useListItemStyle";
import MultiClientSelector from "../../components/multiClientSelector";
import {  DEFAULT_GC_GROUPS, updateGroupToUserProfiles } from "./helper";
import { AvatarImage } from "../users/header";
import GroupNameDialog from "./groupNameDialog";
import { Badge } from "@material-ui/core";
import clsx from "clsx";
import PeopleGroup from "fitbud/images/people_group.svg";
import { FlashIcon } from "fitbud/icons/flashIcon";
import { DarkTooltip } from "fitbud/components/darktTooltip";
import infoIcon from "fitbud/images/info.svg";
import { lastMessageHint } from "../users/list";
import { WorkerContext } from "fitbud/providers/workerProvider";
import AttributeFilter from "../users/AttributeFilter";

const useStyles = makeStyles((theme) => ({
  chatCountBadge: {
    root: {
      width: '100px',
      top: 0,
    },
  },
  badge: {
    height: '14px',
    minWidth: '14px !important',
  },
  avatarBadge: {
    right: 8,
    top: 30,
    boxShadow:'0px 0px 0px 1px #fff',
    height: '14px',
    backgroundColor:"#6F8099",
    color: '#FFF',
    fontSize: '10px',
    borderRadius: '100%',
    width: '14px',
  },
  hideBadge:{
    display: "none",
  },
  quickFilters:{
    marginBottom:7//total margin=15px, tags have mb-8
  },
  flashIcon:{
    width: "16px",
    height: "16px"
  },
  selectedChip: {
    background: '#FFF',
    border: '1px solid #317FF5',
  },
  chip: {
    margin: '0 4px 8px 0px',
    height: 28,
    backgroundColor: "#FFF",
    color: '#37404D',
    border: '1px solid #D3D9DB'
  },
  selectedClickable: {
    cursor: 'pointer',
    color: '#317FF5',
    border: '1px solid #317FF5',
    '&:focus , &:hover': {
        backgroundColor: "#FFF",
    }
  },
  unselectedClickable: {
    cursor: 'pointer',
    '&:focus, &:hover': {
        backgroundColor: "#FFF",
        color: '#37404D'
    }
  },
}));

const FILTER_OPTIONS = {
  "multi_list_filters": { text: "Client Category", component: AllClientsQuickFilterCheckbox },
  // "attribute_filters": { text: "Attributes", component:AttributeFilter},
  "plan": { text: "Plan", component: PlanFilter },
  "tags": { text: "Tags", component: TagFilter },
}

const AUTO_TOOLTIP = "Create Automated Smart group using client filters. The member list of a smart group changes based on client status filter."
const CUSTOM_TOOLTIP = "Create Custom group by manually selecting the clients. Changes in client status will not have any effect on the members list."

const GroupChatList = props => {
  const { cid, comp } = useContext(FirebaseAuthContext);
  const { fetchNext } = useContext(WorkerContext);
  const { onSelect, selected, insert, enqueueSnackbar,
    loading, docs = [], hideLoader, showLoader , groupChats
  } = props;

  const [filter, setFilter] = useState({});
  const [createDialog, setCreateDialog] = useState(false);
  const [showNameDiag, setShowNameDiag] = useState(false);
  const [newSelectedClients, setNewSelectedClients] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showUnread, setShowUnread] = useState(false);
  const openCreateMenu = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const { chat_config = {} } = comp.data() || {};
  const isSmartGroups = !chat_config.disable_smart_groups;
  const fetchNextGroupChats = () => !showUnread && fetchNext({ keyName: 'groupChats', cid });

  const createCustomGroup = async (groupName, thumbnail, desc='', groupRefName, mute = false) => {
    const isCustomGroup = !!newSelectedClients;
    if (!groupName || !groupName.trim()) {return;}
    showLoader();
    const group = {
      cid, ref_name: groupRefName.trim(), name: groupName.trim(),
      deleted: false, archive: false, type: isCustomGroup ? "custom" : "auto",
      _muat: firebase.firestore.FieldValue.serverTimestamp(),
      _cat: firebase.firestore.FieldValue.serverTimestamp(),
      last_message_timestamp: new firebase.firestore.Timestamp(0, 0),
      desc,
      mute,
    }
    const groupId = `${cid}:${uuid()}`;
    if(!isCustomGroup){ group.criteria = filter; group.type = "auto" };
    if(isCustomGroup) group.total_clients = newSelectedClients.length;
    if(isCustomGroup) group.uids = newSelectedClients.map((i) => i._id);
    if(thumbnail) group.thumbnail = thumbnail;
    group[cid] = {
      read_count: 0,
      last_seen: firebase.firestore.FieldValue.serverTimestamp(),
    }
    try {
      const promises = [firebase.firestore().doc(`groupChats/${groupId}`).set(group)];
      if(isCustomGroup) promises.push(updateGroupToUserProfiles(newSelectedClients, groupId, mute));
      if(group.criteria){
        promises.push(firebase.firestore().doc(`companies/${cid}/misc/gcCriteria`).set({ 
          [groupId]: group.criteria || false,
        }, { merge: true }));
      }
      await Promise.all(promises);
      insert({
        _id: groupId,
        data: {
          ...group,
        }
      });
      onSelect(groupId);
      setFilter({});
    } catch (error) {
      console.error(error);
      handleError(error, enqueueSnackbar)
    }
    setShowNameDiag(false);
    hideLoader();
  }
  let onClick = useCallback((e) => {
    if (!!onSelect) {
      if(!!showUnread){
        const idx = _.findIndex(showUnread, (i) => i._id === e.currentTarget.id);
        const newDoc = showUnread[idx];
        newDoc.data[cid] = { ..._.get(newDoc, `data.${cid}`, {}), read_count: _.get(newDoc, `data.total_messages`, {}) };
        const newUnread = [...showUnread];
        newUnread.splice(idx, 1, newDoc)
        setShowUnread(newUnread)
      }
      onSelect(e.currentTarget.id);
    };
  }, [cid, onSelect, showUnread]);
  
  const toggleCreateMenu = (e, val=true) => {
    setAnchorEl(prev => prev || !val ? false : e.currentTarget);
  };

  const onClose = () => {
    setNewSelectedClients(false);
    toggleCreateMenu(false);
    setShowNameDiag(false)
    setFilter({})
  }

  const showAlreadyExisted = (grp) => {
    hideLoader();
    onSelect(grp._id);
    hideLoader();
    enqueueSnackbar("A group with the selected filters already exists.", { variant: "warning" });
    setFilter({});
    return;
  }
  useEffect(() => {
    if(showUnread && !loading){
      // Update list while unread filter is on
      const newDoc = docs[0];
      const idx = _.findIndex(showUnread, (i) => i._id === newDoc._id);
      if(_.get(newDoc, `data.${cid}.read_count`, 0) === _.get(newDoc, 'data.total_messages', 0)) 
        return;
        _.set()
      let changeData = _.cloneDeep(newDoc.data);
      if(selected === newDoc._id) changeData = _.set(changeData, `${cid}.read_count`, _.get(newDoc, 'data.total_messages', 0)); 
      if(idx < 0){
        setShowUnread(prev => [{_id: newDoc._id, data: changeData}, ...prev]);
      } else {
        const newUnread = [...showUnread];
        newUnread.splice(idx, 1);
        setShowUnread([{_id: newDoc._id, data: changeData}, ...newUnread]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docs]);

  const onSetFilter = async(filter) => {
    const _filter=_.omit({...filter,...(filter['attribute_filters']||{})},['attribute_filters']);
    let grp = _.find(DEFAULT_GC_GROUPS(cid), (d) => _.isEqual(d.data.criteria, _filter));
    // Switch to that group
    if (grp) return showAlreadyExisted(grp);

    // Find again in redux first then firebase
    grp = _.find(docs, (d) => _.isEqual(d.data.criteria, _filter));
    if (!grp || (grp.data && !!grp.data.archive)) {
      grp = undefined;
      showLoader();
      const snap = await firebase
        .firestore()
        .collection('groupChats')
        .where('cid', '==', cid)
        .where('criteria', '==', _filter)
        .get();

      // look for any doc in db
      if (!snap.empty) {
        snapshotDocsArrayToDataArray(snap.docs).forEach((doc) => {
          if(!doc.data.archive) grp = doc;
        });
      }
    }
    // Switch to that group
    if (grp) return showAlreadyExisted(grp);
    hideLoader();
    setFilter(_filter);
    setShowNameDiag(true);
  }

  // Dialog triggers
  const toggleCreateDialog = (e, val) => {
    e && e.preventDefault();
    setCreateDialog(val);
  };

  const openAutoDialog = (e) => { toggleCreateDialog(e, "auto"); toggleCreateMenu(e, false) };
  const openCustomUserDialog = (e) => { toggleCreateDialog(e, "custom"); toggleCreateMenu(e, false)};

  const handleCustomSave = (selectedUsers) => {
    setNewSelectedClients([...selectedUsers]);
    toggleCreateDialog(null, false);
    setShowNameDiag(true);
  };

  const handleShowUnreadClick = async(e) => {
    e.preventDefault();
    if(showUnread) return setShowUnread(false);
    const unreadGroupIds = Object.keys(groupChats || {}).filter((key) => !!groupChats[key]);
    if(!unreadGroupIds.length) return enqueueSnackbar('No unread messages', { variant: "warning" });
    showLoader();
    try{
      const ids = _.chunk(unreadGroupIds, 10);
      let promises = ids.map((tenIds) => {
        return firebase.firestore().collection("groupChats")
          .where(firebase.firestore.FieldPath.documentId(), "in", [...tenIds]).get();
      });
      const unreadDocs = await Promise.all(promises);
      let docs = [];
      unreadDocs.forEach((res) => {
        if(!res.empty) docs = [...docs, ...snapshotDocsArrayToDataArray(res.docs)];
      });
      if(!docs.length) return enqueueSnackbar('No unread messages', { variant: "warning" });
      docs = docs.filter((a) => !a.data.archive);
      setShowUnread([
        ...docs.sort((a, b) => b.data.last_message_timestamp.toDate() - a.data.last_message_timestamp.toDate())
      ]);
      hideLoader();
    } catch(err){
      hideLoader();
      console.error(err);
      handleError(err, enqueueSnackbar)
    };    
  };

  const nonCreatedDefaultGrps = DEFAULT_GC_GROUPS(cid).filter(grp => _.findIndex(docs, (d) => d._id === grp._id) === -1)
  let grpDocs = isSmartGroups ? docs.concat(nonCreatedDefaultGrps) : docs;

  const classes = useStyles();
  return (
    <div className="d-flex flex-column vh-100 position-relative">
      <Menu 
        anchorEl={anchorEl} 
        open={openCreateMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} 
        onClose={(e) => toggleCreateMenu(e, false)}
      >
        {isSmartGroups && <MenuItem onClick={openAutoDialog}>
          New Smart Group
          <DarkTooltip
            title={AUTO_TOOLTIP} 
            enterTouchDelay={0} onClick={e=>e.preventDefault()}
          >
            <img src={infoIcon} alt="" className="ml-5" style={{ height: '16px', width: '16px' }} />
          </DarkTooltip>
        </MenuItem>}
        <MenuItem onClick={openCustomUserDialog}>
          New Custom Group
          <DarkTooltip
            title={CUSTOM_TOOLTIP}
            enterTouchDelay={0} onClick={e=>e.preventDefault()}
          >
            <img src={infoIcon} alt="" className="ml-5" style={{ height: '16px', width: '16px' }} />
          </DarkTooltip>
        </MenuItem>
      </Menu>

      {/* Header */}
      <div className="position-relative bg-grey-new px-20">
        <div className="d-flex justify-content-between align-items-center" style={{ height: 56 }}>
            <Typography variant="h3" className="flex-grow-1">
              Group Chats
            </Typography>
            <div className="d-flex" style={{ marginRight : "-8px" }}>
              <IconButton onClick={toggleCreateMenu}>
                <AddIcon />
              </IconButton>
            </div>
          </div>

          {/* Unread Chip */}
          <div className="mb-8">
            <Chip
              size="medium"
              label="Unread"
              clickable
              onClick={(e) => handleShowUnreadClick(e)}
              className="rounded-pill"
              classes={{
                  root: clsx(classes.chip, !!showUnread && `${classes.selectedChip}`),
                  label: clsx('font_13_500'),
                  clickable: !!showUnread ? `${classes.selectedClickable}` : `${classes.unselectedClickable}`
              }} />
            </div>

          {!!loading && <LinearProgress className={"position-absolute w-100 height-2"} style={{ left: 0, bottom: -2 }} />}
        </div>
      <Divider classes={{ root: 'height-2' }} style={{ backgroundColor: "#D8DCE0" }} />

      {/* List Items */}
      {!grpDocs.length && !loading ? (
        <div className="d-flex flex-column vh-100 align-items-center justify-content-center">
          <img alt="noitem" src={PeopleGroup} />
          <Typography variant='h3' className='mt-15'>
            No groups found
          </Typography>
        </div>
        ) : (
          <div id="groupChatList" className="d-flex flex-column bg-white overflow-auto">
            <InfiniteScroll
              dataLength={grpDocs.length}
              next={fetchNextGroupChats}
              hasMore={true}
              scrollableTarget="groupChatList"
            >
              <ListStyling>
                {(showUnread ? showUnread : grpDocs).map((doc) => {
                  if (!doc || !doc.data) return null;
                  else {
                    const docData = doc.data || {};
                    let { 
                      ref_name, last_message_timestamp: timeStamp, type, thumbnail, 
                      last_message_type, total_messages, last_message_body="", archive 
                    } = doc.data;
                    const isDefaultGroup = DEFAULT_GC_GROUPS(cid).find(grp => grp._id === doc._id);
                    if(!isDefaultGroup && archive) return null;
                    if (!!timeStamp) {
                      timeStamp = moment(new firebase.firestore.Timestamp(timeStamp.seconds, timeStamp.nanoseconds).toMillis()).local()
                      if (timeStamp.format('YYYY') !== '1970') {
                        if (timeStamp.isBefore(moment(), "day"))
                        timeStamp = timeStamp.format("DD/MM");
                        else timeStamp = timeStamp.format("LT");
                      } else timeStamp = null;
                    }
                    // Unread Count logic
                    let unread = total_messages && !_.isNil(docData[cid] || docData["read_count"])
                      ? total_messages - docData[cid]["read_count"] : 0;

                    return (
                      <ListItem
                        key={doc._id}
                        id={doc._id}
                        selected={selected === doc._id}
                        button
                        onClick={onClick}
                      >
                        <ListItemAvatar>
                          <GroupTypeIndicator type={type} showTooltip classes={{badge: classes.avatarBadge}}>
                            <AvatarImage
                              src={thumbnail}
                              name={ref_name}
                              base64={false}
                              externalUrl
                              alt={ref_name || "groupChatImage"}
                              styles={{ fontSize: 13, fontWeight: 600 }}
                            />
                          </GroupTypeIndicator>
                        </ListItemAvatar>
                        <ListItemText
                          primary={(
                            <span className="d-flex align-items-center justify-content-between">
                              <span className="text-truncate">{ref_name}</span>
                              {!!timeStamp && <Typography color="textSecondary" className="font_12_500">{timeStamp}</Typography>}
                            </span>
                          )}
                          secondary={
                            <span className={clsx(unread > 0 ? "font_13_600" : "font_13_500", )} style={{width: "80%"}}>
                              {lastMessageHint(last_message_type, last_message_body)}
                            </span>
                          }
                          classes={{
                            root: "text-truncate",
                            multiline: "fm-0",
                            primary: "font_15_600 text-truncate",
                            secondary: "text-truncate fmt-10 w-75"
                          }}
                        />
                        {!!unread && (docData.last_message_authorId !== cid) && (selected !== doc._id) && (
                          <div className="d-flex flex-column align-items-center">
                              <Badge
                                badgeContent={unread}
                                color="primary"
                                children={""}
                                style={{right: '10px', top: "14px" }}
                                className={classes.chatCountBadge}
                              />
                          </div>
                        )}
                      </ListItem>
                    );
                  }
                })}
                {!loading && <Link
                  component="button"
                  variant="caption"
                  underline="none"
                  className="w-100 mx-auto fpy-20 font_15_600 bg-body"
                  onClick={toggleCreateMenu}
                >
                  Create Group
                </Link>}
              </ListStyling>
            </InfiniteScroll>
          </div> 
        )}

      {createDialog === "auto" && 
        <FilterView
          title="Select Category" 
          keyName="browse"
          open
          filter_opts={FILTER_OPTIONS}
          handleClose={(e) => toggleCreateDialog(e, false)}
          filter={filter}
          setFilter={onSetFilter}
          actionText="Next"
          filter_name="group_chat"
        />
      }
      {createDialog === "custom" && 
        <MultiClientSelector
          title="Select Members"
          keyName="browse"
          open
          filter_opts={FILTER_OPTIONS}
          onClose={(e) => toggleCreateDialog(e, false)}
          filter={filter}
          setFilter={onSetFilter}
          actionText="Next"
          onSave={handleCustomSave}
        />
      }
      {showNameDiag && (
        <GroupNameDialog onSave={createCustomGroup} onClose={onClose} />
      )}
    </div>
  );
};

const mapStateToProps = (s, op) => {
  return {...s.groupChatList, ...s.home};
};

const mapDispatchToProps = d => {
  return { ...groupChatReduxFns(d), ...appRdxFns(d) }
};

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(GroupChatList)
);

export const GroupTypeIndicator = ({ type, className, showTooltip=false, children, classes={} }) => {
  const cls = useStyles();

  return (
    <DarkTooltip
      title={type ==="custom" ? "Custom Group" :"Smart Group"}
      enterTouchDelay={0} onClick={e=>e.preventDefault()}
    >
      <Badge
        className={className}
        badgeContent={type ==="custom" ? "" : <FlashIcon classes={{root: cls.flashIcon}} />}
        classes={{
          badge: clsx(cls.badge, classes.badge, type ==="custom" && cls.hideBadge),
        }}
        children={children}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal:'right',
        }}
      />
    </DarkTooltip>
  );
}
