import { Divider, FormControlLabel, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import { DateTimePicker } from '@material-ui/pickers';
import { firestore } from 'firebase';
import Dialog from 'fitbud/components/Dialog';
import IOSSwitch from 'fitbud/components/IOSSwitch';
import moment from 'moment';
import React, { useState, useMemo } from 'react';

const DEFAULT_DATA = {
  notification_only: true,
  time: null,
};

export default function ScheduleDialog({ onSave = () => {}, onClose, data }) {
  const [formData, setFormData] = useState({...DEFAULT_DATA, ...data});
  const [timeError, setTimeError] = useState();

  function handleChange(e) {
    const { name, checked } = e.target;

    setFormData((prev) => ({ ...prev, [name]: checked }));
  }

  function handleTimeChange(date) {
    setFormData((prev) => ({ ...prev, time: date }));
  }

  function validateTime(value) {
    let errorMessage;
    let error = false;
    const currentTime = moment();
    const time = moment(value);
    if (time.isBefore(currentTime)) {
      errorMessage = 'Please select a future time';
      error = true;
    }
    setTimeError(errorMessage);
    return error;
  }

  function resetTime() {
    // wrapped in setTimeout to avoid opening of date picker
    setTimeout(() => {
      setFormData((prev) => ({ ...prev, time: null }));
    }, 0)
  }

  function handleSave() {
    if (validateTime(formData.time)) {
      return;
    }
    const payload = { ...formData };
    if (!payload.time) {
      payload.time = firestore.Timestamp.now();
    } else {
      payload.time = firestore.Timestamp.fromDate(payload.time.toDate());
    }
    
    onSave(payload);
  }

  return (
    <Dialog
      title="Schedule Settings"
      actionText="Send"
      onSave={handleSave}
      onClose={onClose}
      open
      paperClass="width-400"
      dialogContentClassName="px-20 py-25"
    >
      <FormControlLabel
        name="notification_only"
        checked={formData.notification_only}
        onChange={handleChange}
        control={<IOSSwitch color="primary" />}
        label="Send as push notification only"
        labelPlacement="start"
        classes={{ root: 'd-flex justify-content-between mx-0 mb-25' }}
      />
      <Divider />

      <FormControlLabel
        control={
          <DateField
            name="time"
            value={formData.time}
            onChange={handleTimeChange}
            onReset={resetTime}
            error={!!timeError}
            helperText={timeError}
          />
        }
        label="When"
        labelPlacement="start"
        classes={{ root: 'd-flex justify-content-between mx-0 mt-20 mb-0' }}
      />
    </Dialog>
  );
}

const Arr = React.memo(({ value, onReset }) => {
  function handleClick(e) {
    e.stopPropagation();
    onReset();
  }

  if (onReset && value !== 'Now') {
    return (
      <InputAdornment position="end">
        <IconButton size="small" onClick={handleClick}>
          <CloseRounded />
        </IconButton>
      </InputAdornment>
    );
  }
  return (
    <InputAdornment position="end">
      <ArrowIcon color="action" />
    </InputAdornment>
  );
});

const PickerField = (props) => {
  let { value, onReset } = props;

  if (!value) value = 'Now';
  return (
    <TextField
      {...props}
      value={value}
      className="standard"
      InputProps={{ endAdornment: <Arr onReset={onReset} value={value} /> }}
    />
  );
};

const MAXMONTHS = 1;
const DateField = (props) => {
  const { onChange, value, minDate, onReset, error, helperText } = props;
  const maxDate = useMemo(() => {
    if (minDate) return moment(minDate).add(MAXMONTHS, 'months').endOf('month');
    else return moment().add(MAXMONTHS, 'months').endOf('month');
  }, [minDate]);
  return (
    <DateTimePicker
      autoOk
      value={value}
      inputVariant="outlined"
      onChange={onChange}
      format={value ? 'DD MMM YYYY, h:mm a' : '-'}
      TextFieldComponent={(props) => <PickerField onReset={onReset} {...props} />}
      minDate={minDate}
      maxDate={maxDate}
      helperText={helperText}
      disablePast
      error={error}
    />
  );
};
