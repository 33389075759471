import React, { useCallback, useContext, useState, useRef, useEffect, useMemo } from 'react';
import _ from 'lodash';
import firebase from 'fitbud/firebase';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { useSnackbar } from 'notistack';
import { DEFAULT_ERROR } from 'fitbud/utils/constants';
import { useParams } from 'react-router-dom';
import update from 'immutability-helper';
import uuidv4 from 'uuid/v4';
import { useDispatch, useSelector } from 'react-redux';
import appRdxFns from 'fitbud/redux/app';
import { getCatalogByIds } from 'fitbud/api';
import { parsePacks } from 'fitbud/views/plan/helper';
import planRdxFns from "fitbud/redux/plans";
import { PlanContext } from 'fitbud/views/plan/planProvider';
import * as Sentry from '@sentry/browser';
import { CIRCLE_GROUP_ENUM,PILL_GROUP_ENUM, GROUP_ENUM } from './constants';

import {
  PROGRAMS_WORKOUT,
  MEAL_PLANS_NUTRITION,
  ON_DEMAND_WORKOUT,
  ON_DEMAND_NUTRITION,
  ON_DEMAND_INSIGHTS,
  COLLECTION_SPACE,
  PRESENTATIONS
} from './constants';
import { getInitialValue } from './helper';

export const ExploreContext = React.createContext({});

export const DEFAULT_EXPLORE_SETTING = {
  wo_select: false,
  sc_select: false,
};

export const DEFAULT_EXPLORE_CONFIG = {
  workout: {
    title: 'Workouts',
    position: 0,
    enabled: false,
    search_disabled: false,
    only_tab_title: null,
    bookmark: false,
  },
  nutrition: {
    title: 'Nutrition',
    position: 1,
    enabled: false,
    search_disabled: false,
    only_tab_title: null,
    bookmark: false,
  },
  resources: {
    title: 'Resources',
    position: 2,
    enabled: false,
    search_disabled: false,
    only_tab_title: null,
    bookmark: false,
  },
};

const getDefaultOnDemand = (exploreType) => {
  let title;
  let REST = ON_DEMAND_WORKOUT;
  if (exploreType === 'workout') {
    title = 'All Workouts'
    REST = ON_DEMAND_WORKOUT;
  };
  if (exploreType === 'nutrition') {
    title = 'All Recipes';
    REST = ON_DEMAND_NUTRITION;
  }
  if (exploreType === 'resources') {
    title = 'All Resources'
    REST = ON_DEMAND_INSIGHTS;
  };
  return {
    title: title,
    hidden: true,
    index: 99999,
    description: '',
    items: [],
    ...REST,
  };
};

const getDefaultScheduleColl = (exploreType) => {
  let title;
  let REST  = PROGRAMS_WORKOUT;
  if (exploreType === 'workout') {
    title = 'Schedules';
    REST = PROGRAMS_WORKOUT;
  }
  if (exploreType === 'nutrition') {
    title = 'Meal Schedules';
    REST = MEAL_PLANS_NUTRITION;
  }
  if (exploreType === 'resources') {
    title = 'Resources';
  }
  return {
    programs: {
      title: title,
      hidden: true,
      index: 0,
      description: '',
      items: [],
      ...REST,
    },
  };
};


export const getDefaultScheduleSpace = (exploreType) => {
  return {
    hidden: true,
    index: 1,
    items: [],
    ...COLLECTION_SPACE,
  };
};

const ExploreProvider = ({id: exploreId,updateParent,children}) => {
  const { cid, comp, refreshco } = useContext(FirebaseAuthContext);
  const { parsePacksForIapIds } = useContext(PlanContext);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState({});
  const [items, setItems] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");
  const [loading, setLoading] = useState(false);
  const [compLoading, setCompLoading] = useState(false); //state for setting initial comp data : explore config and setting
  const [itemsLoading, setItemLoading] = useState(false);
  const compData = comp.data();
  const { explore_config, explore_settings, _next, color_theme, dash_config } = compData;
  const isExploreEnabled = _next === true;
  const { collection: exploreCollection, type: exploreType} = useParams();
  const compCollectionRef = useRef(firebase.firestore().doc(`companies/${cid}`));
  const d = useDispatch();
  const { showLoader, hideLoader } = appRdxFns(d);
  const { set:setPlan } = planRdxFns(d);
  const { docs: planRdxDocs } = useSelector((s) => s.plans);
  const isGroupClass = Boolean(_.get(compData, "features.group_class.enabled", false));
  const isScheduleCollection = exploreCollection === 'schedules';
  const isCollection = exploreCollection === 'collections';
  const ITEM_COLLECTION_NAME = isScheduleCollection ? 'schedules' : isCollection ? 'page_data' : '';
  const collectionData=useMemo(()=>{
    return _.get(data, `${ITEM_COLLECTION_NAME}.${exploreId}`,{});
  },[data,exploreId])
  const isSubCollViewOpen=useMemo(()=>{
    const {child_of}=collectionData||{};
    return !!child_of && exploreId!==child_of;
  },[data,exploreId]);
  const parentType = useMemo(()=>{
    return _.get(explore_config, `${exploreType}.parent_type`, exploreType);
  },[explore_config, exploreType])
  const mapKey = () => {
    if (parentType === 'workout') {
      if (isScheduleCollection) return 'schedules';
      else if (isCollection) return 'workouts';
    }
    if (parentType === 'nutrition') {
      if (isCollection) return 'foodrecipes';
    }
    if (parentType === 'resources') return 'resources';
  };

  const mapItemsWithIds = (res) => {
    const { data } = res || {};
    const _keyName = mapKey();
    const all = {};
    const out = data[_keyName] || [];
    if (parentType === 'nutrition' && isScheduleCollection) {
      ['meals', 'mlschedules'].forEach((key) => {
        all[key] = {};
        const out = data[key] || [];
        all[key] = (out || []).reduce((acc, curr) => {
          acc[curr._id] = curr;
          return acc;
        }, {});
      });
    } else {
      all[_keyName] = (out || []).reduce((acc, curr) => {
        acc[curr._id] = curr;
        return acc;
      }, {});
    }

    return all;
  };

  const getItemDataByIdsAndType = (res, items) => {
    const all = mapItemsWithIds(res);
    const sequenceData = [];
    items.forEach((item) => {
      const id = item.id;
      const type = item.type;
      const data = _.get(all, `${type}.${id}`, {});
      const out = { id, type, data };
      const alts = item.alts || [];
      if (!!alts.length) {
        const _alts = [];
        alts.forEach((id) => {
          const _altOut = { id, type, data: _.get(all, `${type}.${id}`, {}) };
          if (!_.get(_altOut, 'data.archive')) _alts.push(_altOut);
        });
        if (_alts && _alts.length) out.alts = _alts;
      }
      if (!_.isEmpty(out.data) && !_.get(out, 'data.archive')) sequenceData.push(out); //to handle deleted items.
    });
    return sequenceData;
  };
  //load items for explore
  const loadItems = async (arg) => {
    const {isGroup, exploreId}=arg;
    const collectionData = _.get(data, `${ITEM_COLLECTION_NAME}.${exploreId}`, {});
    const items = _.get(collectionData, 'items', []);
    setItemLoading(true);
    const _payload = items.reduce((acc, curr) => {
      acc[curr.type] = acc[curr.type] || [];
      acc[curr.type].push(curr.id);
      if (!!curr.alts && !!curr.alts.length) {
        acc[curr.type].push(...curr.alts);
      }
      return acc;
    }, {});

    if (_.isEmpty(_payload)) {
      //If no items found
      setItems([]);
      setItemLoading(false);
      return;
    }
    if(isGroup){
      const _data=_.get(data, ITEM_COLLECTION_NAME, {});
      const _sequenceData=[];
      items.forEach(i=>{
          const obj={...i,data:_data[i.id]};
        _sequenceData.push(obj);
      })
      setItems(_sequenceData);
      setItemLoading(false);
    }
    else{
      getCatalogByIds({ cid, ..._payload })
      .then((res) => {
        //hack---- is this required?
        const _path=window.location.pathname.split('/');
        const _routeId=_path[_path.length-1];
        if(_routeId!==exploreId && !isSubCollViewOpen) return;
        //hack
        const _sequenceData = getItemDataByIdsAndType(res, items);
        setItems(_sequenceData);
        setItemLoading(false);
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
      });
    }
  };

  const setDefaultConfig = async () => {
    const out = {};
    if (_.isEmpty(explore_settings)) {
      out.explore_settings = DEFAULT_EXPLORE_SETTING;
    }
    if (_.isEmpty(explore_config)) {
      out.explore_config = { ...DEFAULT_EXPLORE_CONFIG };
    } else {
      out.explore_config = { ...explore_config };
      if (_.isEmpty(out.explore_config['workout'])) out.explore_config.workout = DEFAULT_EXPLORE_CONFIG.workout;
      if (_.isEmpty(out.explore_config['nutrition'])) out.explore_config.nutrition = DEFAULT_EXPLORE_CONFIG.nutrition;
      if (_.isEmpty(out.explore_config['resources'])) out.explore_config.resources = DEFAULT_EXPLORE_CONFIG.resources;
    }
    if (!!Object.keys(out).length) {
      setCompLoading(true);
      await compCollectionRef.current
        .set(out, { merge: true })
        .then(() => {
          refreshco();
        })
        .catch((err) => {
          Sentry.captureException(err);
          enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
        });
      setCompLoading(false);
    }
  };

  const validateResponse = async (res) => {
    const response = { ...res };
    const isResources = parentType === 'resources';
    const out = {};
    if (!response.on_demand) {
      response.on_demand = out.on_demand = getDefaultOnDemand(parentType);
    }
    if (!response.schedules && !isResources) {
      response.schedules = out.schedules = getDefaultScheduleColl(parentType); // in case of resources we need only ondemand and page_data and collection_space
    }
    if (!response.schedulespace) {
      response.schedulespace = out.schedulespace = getDefaultScheduleSpace(parentType);
    }
    if (Object.keys(out).length) {
      const exploreRef = firebase.firestore().doc(`companies/${cid}/explore/${exploreType}`);
      await exploreRef.set(out, { merge: true });
    }
    return response;
  };

  const parseExplore = async (res) => {
    if (!res || _.isEmpty(res)) return res;
    const out = {};
    if (!('on_demand' in res) && !('schedulespace' in res)) {
      //if these two  collection is not present ie it is old explore ds
      if ('page_data' in res) {
        //program
        const page_data = _.get(res, 'page_data', {});
        const programs = _.get(page_data, 'programs');
        const on_demand = _.get(page_data, 'on_demand');
        const meal_plans = _.get(page_data, "meal_plans"); //Earlier in nutrition schedules was store as meal_plans, in new structure making universal key programs and other id for schedules collection
        if (!_.isEmpty(programs) || !_.isEmpty(meal_plans)) out.schedules = { programs: programs || meal_plans };
        if (!_.isEmpty(on_demand)) out.on_demand = { ...getDefaultOnDemand(parentType), ...on_demand };
        out.page_data = {
          programs: firebase.firestore.FieldValue.delete(),
          on_demand: firebase.firestore.FieldValue.delete(),
          meal_plans: firebase.firestore.FieldValue.delete(),
          space: firebase.firestore.FieldValue.delete(),
        };
        const collectionRef = firebase.firestore().doc(`companies/${cid}/explore/${exploreType}`);
        await collectionRef.set(out, { merge: true });
        const snap = await collectionRef.get();
        const data = await snap.data();
        return data;
      }
    }
    return res;
    //other wise return res;
  };

  const fetchPlanDocs = async() => {
    if(!planRdxDocs || !planRdxDocs.length){
      try {
        const docs = await firebase.firestore().collection(`companies/${cid}/packs`).get();
        setPlan(parsePacks(docs, isGroupClass), 0, true);
        parsePacksForIapIds(docs);
      } catch(err){
        console.log("Error in fetching plans",err);
      };
    }
  };


  const setTab = (value) =>{
    setSelectedTab(value);
  }


  const fetchExplore = async (exploreType) => {
    try {
      setLoading(true);
      const snapshot = await firebase.firestore().doc(`companies/${cid}/explore/${exploreType}`).get();
      let res = snapshot.data();
      res = await parseExplore(res);
      res = await validateResponse(res);
      setData(res);
      await fetchPlanDocs(); //fetch all payments plans if necessary:
      setLoading(false);
    } catch (err) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
    }
    setLoading(false);
  };

  useEffect(() => {
    setDefaultConfig();
  }, []);

  useEffect(() => {
    fetchExplore(exploreType);
  }, [exploreType]);

  const saveCollection = async (res,closeSubCollPopup=false) => {
    const snap = firebase.firestore().doc(`companies/${cid}/explore/${exploreType}`);
    await snap.set(res, { merge: true });
    let data = await snap.get();
    if (data.exists) {
      setData(data.data());
      if(isSubCollViewOpen && updateParent) updateParent(data.data(),closeSubCollPopup);
      return data.data(); //return updating data
    }
    return data; //return old data
  };

  const saveReorderCollection = async (res) => {
    showLoader();
    try {
      const docRef = firebase.firestore().doc(`companies/${cid}/explore/${exploreType}`);
      await docRef.update(res);
      const docSnap = await docRef.get();
      setData(docSnap.data());
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
    }
    hideLoader();
  };

  const addCollection = async (res, type, cb) => {
    const collection = _.get(data, type, {});
    const N = Object.keys(collection).length;
    const id = uuidv4();
    const collection_name = type === 'collections' ? 'page_data' : 'schedules';
    showLoader();
    const {presentation,... _res}=res;
    const colOut = {
      ..._res,
      index: N,
      title: (res.title || '').trim(),
      description: (res.description || '').trim(),
      ...getInitialValue(parentType,selectedTab,color_theme,res.group) //for adding collection add default info here like, column, aspect and all
    };
    if(!!res.group){
        switch(presentation){
          case 'pill':{
            _.merge(colOut,{...PILL_GROUP_ENUM});
            break;
          }
          case 'circle':{
            _.merge(colOut,{...CIRCLE_GROUP_ENUM});
            break;
          }
          default:{
            _.merge(colOut,{...GROUP_ENUM});
            break;
          }
        }
    }
    const out = {
      [collection_name]: {
        [id]: colOut,
      },
    };
    try {
      await saveCollection(out, type);
      cb && cb(type, id);
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
    }
    hideLoader();
  };

  const editSpaceCollection = async (res, cb) => {
    showLoader();
    try {
      await saveCollection(res);
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
    }
    hideLoader();
    cb && cb();
  };

  const setExConfig = useCallback(async (out) => {
    if (!out || _.isEmpty(out)) return;
    try {
      await compCollectionRef.current
        .set(
          {
            explore_config: { ...out },
          },
          { merge: true }
        )
        .then(() => {
          refreshco();
        });
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
    }
  }, []);

  const setExSetting = useCallback(async (out) => {
    if (!out || _.isEmpty(out)) return;
    try {
      await compCollectionRef.current
        .set(
          {
            explore_settings: { ...out },
          },
          { merge: true }
        )
        .then(() => {
          refreshco();
        });
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
    }
  }, []);

  const getCollectionData = () => {
    return _.get(data, `${ITEM_COLLECTION_NAME}.${exploreId}`);
  };

  const getOnDemandCollectionData = () => {
    return _.get(data, `on_demand`, {});
  };

  const getFirebaseKeyOfItems = () => {
    if (parentType === 'workout') {
      if (isScheduleCollection) return 'schedules';
      else if (isCollection) return 'workouts';
    }
  }; //method to save items actual type of item in firebase

  //TODO: need to add  part of
  const addItems = async (selectedItems,isGrp=false) => {
    let items = selectedItems.map((item) => {
      const _type = isGrp?'collection':item.type;
      const out = {
        type: (_type || '').toLocaleLowerCase(),
        id: item._id,
      };
      if (!!item.alts && !!item.alts.length) {
        out.alts = item.alts.map((i) => i.id);
      }
      return out;
    });
    const out = {
      [ITEM_COLLECTION_NAME]: {
        [exploreId]: {
          items: items,
        },
      },
    };
    showLoader();
    try {
      if(isGrp){
        selectedItems.forEach((i)=>{
          _.set(out,[ITEM_COLLECTION_NAME,i._id,'child_of'],exploreId);
        });
      }
      await saveCollection(out);
      setItems(
        selectedItems.map((d) => {
          const out = { id: d._id, data: d.data, type: isGrp?'collection':d.type };
          if (d.alts) out.alts = d.alts;
          return out;
        })
      );
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
    }
    hideLoader();
  };

  const handleDeleteCollection = async ({cb,retainGrpItems=false}) => {
    showLoader();
    let collections={..._.get(data,[ITEM_COLLECTION_NAME])};
    const {group,items,child_of:routeId}=collectionData;
    const isGroupWItems=!!group && !!(items||[]).length;
    //delete exploreId collection
    let docRef = firebase.firestore().collection(`companies/${cid}/explore`).doc(exploreType);
    const out = {
      [`${ITEM_COLLECTION_NAME}.${exploreId}`]: firebase.firestore.FieldValue.delete(),
    };
    //Case->Delete group along with items
    if(isGroupWItems && !retainGrpItems){
      items.forEach(i=>{
        out[ITEM_COLLECTION_NAME+'.'+i.id]=firebase.firestore.FieldValue.delete()
      });
    }
    
    try {
      await docRef.update(out);//deletion
      const _out={};
      delete collections[exploreId];
      //Case-> Delete sub-collection & update main group items.
      if(!!routeId){//
        const newGroupItems=_.get(data,[ITEM_COLLECTION_NAME,routeId,'items'],[]).filter(i=>i.id!==exploreId);
        _.set(collections,[routeId,'items'],newGroupItems);
        if(!newGroupItems.length){
          _.set(collections,[routeId,'hidden'],true);
        }
      }
      //Case->Delete group without items
      if(!!isGroupWItems){
        if(retainGrpItems){
          items.forEach(i=>{
            _.set(collections,[i.id,'child_of'],null);
            _.set(collections,[i.id,'child_only'],false);
          });
        }
        else{
          collections=_.omit(collections,[...items.map(i=>i.id)]);
        }
      }
      _.set(_out,[ITEM_COLLECTION_NAME],collections);
      const _data=await saveCollection(_out,true);//need to update local-state after delete
      await updateExploreConfigOnToggle(_data);
      cb && cb();
    } catch (err) {
      console.log(err);
    }
    hideLoader();
  };

  const updateItemById = (id,data)=>{
    //find item index,
    const iIndex = _.findIndex(items,(item)=>item.id === id);
    if(iIndex >= 0){ //found;
      const  item = _.get(items,iIndex); //item format : {id,data,type}
      const updatedItem = {...item,data:_.merge(item.data,data)};
      const outItems = update(items,{
        $splice:[[iIndex, 1, updatedItem]]
      })
      setItems(outItems)
    }
  }
  const handleReorderItems = async (selectedItems) => {
    let items = selectedItems.map((item) => {
      const out = {
        type: item.type,
        id: item._id,
      };
      if (item.alts) out.alts = (item.alts || []).map((item) => item.id || item._id);
      return out;
    });
    const _update = {
      items,
    };
    if (!items || !items.length) {
      //if all items are deleted then hidden
      _update.hidden = true;
    }
    const out = {
      [ITEM_COLLECTION_NAME]: {
        [exploreId]: _update,
      },
    };

    try {
      showLoader();
      let _data = await saveCollection(out);
      await updateExploreConfigOnToggle(_data);
      setItems(
        selectedItems.map((d) => {
          const out = { id: d._id, data: d.data, type: d.type };
          if (d.alts) out.alts = d.alts;
          return out;
        })
      );
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
    }
    hideLoader();
  };
  const handleMoveItems = async (selectedValues,destination,remainingItems=[]) => {
    if(!destination) return;
    let sourceItems = (remainingItems||[]).map((item) => {
      const out = {
        type: item.type,
        id: item.id,
      };
      if (item.alts) out.alts = (item.alts || []).map((item) => item.id || item._id);
      return out;
    });
    const out ={
      [ITEM_COLLECTION_NAME]: {
        [exploreId]: {items:sourceItems}
      },
    }
    if(!sourceItems || !sourceItems.length){
      _.set(out,[ITEM_COLLECTION_NAME,exploreId,'hidden'],true);
    }
    if(destination!=='root'){//move to other groups
      const destItems =_.get(data,[ITEM_COLLECTION_NAME,destination,'items'],[]);
      destItems.push(...selectedValues.map(i=>({id:i.id,type:i.type})));
      _.set(out,[ITEM_COLLECTION_NAME,destination,'items'],destItems);
    }
    selectedValues.forEach(i=>{
      const child_of=destination==='root'?null:destination;
      const child_only=destination==='root'?false:_.get(i,'data.child_only',false);
      _.set(out,[ITEM_COLLECTION_NAME,i.id,'child_of'],child_of);
      _.set(out,[ITEM_COLLECTION_NAME,i.id,'child_only'],child_only);
    });
    //-----
    try{
      showLoader();
      const _data=await saveCollection(out);
      await updateExploreConfigOnToggle(_data);
      setItems(
        remainingItems.map((d) => {
          const out = { id: d.id, data: d.data, type: d.type };
          if (d.alts) out.alts = d.alts;
          return out;
        })
      );
    }
    catch(err){
      Sentry.captureException(err);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
    }
    hideLoader();
  };
  const handleSubCollectionCb=(data)=>{
    setData(data);
    const items=_.get(data,[ITEM_COLLECTION_NAME,exploreId,'items'],[]).map(i=>({
      ...i,
      data:_.get(data,[ITEM_COLLECTION_NAME,i.id])
    }));
    setItems([...items]);
  }

  const editOnDemand = async (res, cb)=>{
    showLoader();
    try {
      const _out = {
        ['on_demand']: {
          ...res,
        },
      };
      const _data = await saveCollection(_out);
      await updateExploreConfigOnToggle(_data);
      cb && cb();
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
    }
    hideLoader();
  }

  const editCollection = async (res, cb) => {
    showLoader();
    try {
      const _out = {
        [ITEM_COLLECTION_NAME]: {
          [exploreId]: {
            ...res,
            title: (res.title || "").trim(),
            description: (res.description || "").trim()
          },
        },
      };
      await saveCollection(_out);
      cb && cb();
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
    }
    hideLoader();
  };

  const updateExploreConfigOnToggle = async (data) => {
    const companyDoc = firebase.firestore().doc(`companies/${cid}`);
    const { schedules, page_data, on_demand } = data || {};
    const isOnDemandDisabled = _.get(on_demand, 'hidden', true); //if not defined ie off
    const isAnyCollectionEnabled = _.chain(page_data)
      .values()
      .some((v) => !v.hidden)
      .value();
    const isAnyScCollectionEnabled = _.chain(schedules)
      .values()
      .some((v) => !v.hidden)
      .value();
    const selectedExConfig = _.get(explore_config, exploreType, {});
    const selectedExSetting = { ...(explore_settings || {}) };
    const selectedAppActions = dash_config && dash_config.inapp_actions;
    const _outExploreConfig = {};
    const _outExploreSetting = {};
    let _outInAppActions = null;
    if (parentType === 'workout' || parentType === 'nutrition') {
      const isEnabled = isAnyCollectionEnabled || isAnyScCollectionEnabled || !isOnDemandDisabled; //if on_demand or any schedule enabled
      if (selectedExConfig.enabled !== isEnabled) _outExploreConfig.enabled = isEnabled;
      const isBookMarkEnabled = isAnyCollectionEnabled || isAnyScCollectionEnabled; //if any collection is enabled then
      if (_.get(selectedExConfig, 'bookmark') !== isBookMarkEnabled) _outExploreConfig.bookmark = isBookMarkEnabled;
      if (exploreType === 'workout') { // FIXME ideally we should be checking all workout tabs. But since we don't have that data so just check the primary workout tab
        const isScSelect = isAnyScCollectionEnabled;
        if (_.get(selectedExSetting, 'sc_select') !== isScSelect) _outExploreSetting.sc_select = isScSelect;
        const isWoSelect =  isAnyCollectionEnabled;
        if (_.get(selectedExSetting, 'wo_select') !== isWoSelect) _outExploreSetting.wo_select = isWoSelect;
      }
    }
    if (parentType === 'resources') { //due to changing nature of   resources keeping it separate :: FIX IT LATER
      const isEnabled = isAnyCollectionEnabled || isAnyScCollectionEnabled || !isOnDemandDisabled;
      if (isExploreEnabled) {
        if (selectedExConfig.enabled !== isEnabled) {
          _outExploreConfig.enabled = isEnabled;
          _outExploreConfig.tab = firebase.firestore.FieldValue.delete();
        }
      } else {
        if (selectedExConfig.in_profile !== isEnabled) {
          _outExploreConfig.enabled = isEnabled;
          _outExploreConfig.in_profile = isEnabled;
          _outExploreConfig.tab = 'none';
        }
      }
      if (!isEnabled) { // resources turned off. Blindly remove from actions
        if (selectedAppActions) _outInAppActions = selectedAppActions.filter(x => (x.type !== 'resources' || !x.link));
        else _outInAppActions = [{type: 'bookmark'}, {type: 'manual'}, {type: 'downloads'}];
      } else if (!isExploreEnabled) { // resources turned on. IFF it's an in_profile case then insert in actions
        if (selectedAppActions) {
          if (!selectedAppActions.find(x => x.type === 'resources'))
            _outInAppActions = [{type: 'resources'}, ...selectedAppActions];
        } else _outInAppActions = [{type: 'resources'}, {type: 'bookmark'}, {type: 'manual'}, {type: 'downloads'}];
      }
    }
    if (!_.isEmpty(_outExploreConfig) || !_.isEmpty(_outExploreSetting) || !!_outInAppActions) { //check for any changes and key available or not
      const out = {};
      if(!_.isEmpty(_outExploreConfig)){
        out.explore_config = {
          [exploreType] : _outExploreConfig
        }
      };
      if(!_.isEmpty(_outExploreSetting)) out.explore_settings = _outExploreSetting;
      if(!!_outInAppActions) out.dash_config = { inapp_actions: _outInAppActions };
      if(!_.isEmpty(out)){
        await companyDoc.set(out, { merge: true });
        refreshco();  
      }
    }
  };

  const toggleCollection = async (cb) => {
    try {
      showLoader();
      const _out = {
        [ITEM_COLLECTION_NAME]: {
          [exploreId]: {
            hidden: !_.get(data, `${ITEM_COLLECTION_NAME}.${exploreId}.hidden`),
          },
        },
      };
      const _data = await saveCollection(_out);
      await updateExploreConfigOnToggle(_data);
      cb && cb();
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
    }
    hideLoader();
  };
  const toggleOnDemand = async (cb) => {
    try {
      showLoader();
      const _out = {
        ['on_demand']: {
          hidden: !_.get(data, `${'on_demand'}.hidden`),
        },
      };
      const _data = await saveCollection(_out);
      await updateExploreConfigOnToggle(_data);
      cb && cb();
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
    }
    hideLoader();
  };

  const manageAltsItems = async (sItems, index, type, cb) => {
    const pItem = sItems[0];
    const aItems = sItems.slice(1) || [];
    const collectionItems = _.get(data, `${ITEM_COLLECTION_NAME}.${exploreId}.items`, []);
    const _items = [...collectionItems];
    //_item for item in data base
    const _item = {
      type: type,
      id: pItem._id,
    };
    if (!!aItems && !!aItems.length) {
      _item.alts = aItems.map((i) => i._id);
    }
    //_item for item in state:
    const _sItem = {
      id: pItem._id,
      type: type,
      data: pItem.data,
      alts: aItems.map((i) => ({ id: i._id, data: i.data, type: type })),
    };

    const _oItems = update(_items, {
      $splice: [[index, 1, _item]],
    });

    const _sItems = update(items, {
      $splice: [[index, 1, _sItem]],
    });

    const out = {
      [ITEM_COLLECTION_NAME]: {
        [exploreId]: {
          items: _oItems,
        },
      },
    };

    try {
      showLoader();
      await saveCollection(out);
      setItems(_sItems);
      cb && cb();
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
    }
    hideLoader();
  };

  return (
    <ExploreContext.Provider
      value={{
        //STATES::
        cid,
        loading: loading || compLoading, // state fof loading value of explore data
        data, // state fof whole explore data
        explore_config, //state for explore config of cid
        explore_settings, //state for explore setting of cid
        items, // state for a whole items in a particular collections
        itemsLoading, //state for loading details of collection id
        exploreType,
        exploreCollection,
        exploreId,
        selectedTab, //selected tab value
        parentType,
        // METHODS::
        setTab, //methods for set selected tab
        setExConfig, //methods for config settings
        setExSetting, // methods for explore setting
        addCollection, // methods to add collection items like schedules and collections
        saveReorderCollection, //methods for reorder and delete of  collections list
        editSpaceCollection, //methods for edit space collection like name and all
        loadItems, // methods for loading items of a  collections
        getCollectionData, //methods for get all info of a collection like title hidden and all
        addItems, // methods for adding  items to a collections
        getOnDemandCollectionData, // method for getting info of on demand collections
        handleDeleteCollection, // method for deleting collection
        handleReorderItems, //methods for reorder and delete of  items of a collections
        editCollection, //methods for edit collection like name and hidden and all
        toggleCollection, // method for toggle a collection
        toggleOnDemand, //method for toggle a on demand collection
        editOnDemand, //methods for editing meta info of on demand
        manageAltsItems, // methods for adding alt items in a item of a collections
        updateItemById, //update particular item in items array , use case: when particular schedule edit in drawer and saved.
        handleMoveItems,// move sub-collections to root or other groups
        handleSubCollectionCb // handles sub-collection delete cb for group
      }}
    >
      {children}
    </ExploreContext.Provider>
  );
};

export const useExploreContext = () => useContext(ExploreContext);

export default ExploreProvider;


/*

ExploreType is firebase key 
and ParentType is behaviour key.

component Logic:
  on mount call : 
        setDefaultConfig : configure default explore config 
        fetchExplore : it will load and parse explore data and save initial value if not found like, collection space and on demand
                       it call parseExplore, run migration if required.
                       it call validateResponse, it will create default collection like on_demand, schedules and scheduleSpace if not found.
*/
