import React from 'react'
// import moment from "moment";
import {
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Typography
} from "@material-ui/core";
import Dialog from "fitbud/components/Dialog";
import { AvatarImage } from "fitbud/views/users/header";
import { pluralize } from 'fitbud/utils/helpers';
import { bffGetUserProfileByIDs } from 'fitbud/api';
import CircularLoader from 'fitbud/components/CircularLoader';
import _ from 'lodash';

const ListInner = props => {
    const { docs = [] } = props;
    return (
        docs.map((doc, index) => (
            <ListItem
                key={doc._id}
                id={doc._id}
                className="fpy-10 fpx-20 bg-white"
            >
                <div className="d-flex justify-content-between align-items-center w-100">
                    <ListItemAvatar style={{ minWidth: "40px" }}>
                        <AvatarImage
                            src={`user_profiles/${doc._id}/profile/original/${doc.image}`}
                            name={doc.name}
                            className="fmr-20"
                            size="small"
                            alt={doc.name || " "}
                            styles={{ width: 40, height: 40, fontSize: 14, borderWidth: 1 }}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        classes={{
                            multiline: "fm-0"
                        }}
                        primary={
                            <Typography
                                variant='h6'
                                classes={{
                                    root: "text-truncate"
                                }}
                            >
                                {doc.name}
                            </Typography>
                        }
                    />
                </div>
            </ListItem>
        ))
    );
};
function BroadcastRecipientsList({ cid, recipients, handleClose }) {
    const [loading, setLoading] = React.useState(true);
    const [docs, setDocs] = React.useState([]);
    React.useEffect(() => {
        setLoading(true);
        bffGetUserProfileByIDs({
            uids: recipients.slice(0, 100),
            cid,
            fields: ['name', 'email', 'image']
        }).then(res => {
            delete res.data.success;
            setDocs(_.get((res.data), 'data', []));
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            console.log(err);
        });

    }, [recipients, cid]);
    return (
        <Dialog
            open
            onClose={() => handleClose(null)}
            title={!loading && `${(recipients || []).length} Recipient${pluralize(docs.length)}`}
            // subtitle={`Sent on: ${moment(payload.timestamp.toMillis ? payload.timestamp.toMillis() : messageData.ts).format("DD MMM • HH:mm")}`}
            buttonColor="main"
            paperClass="width-400 height-70  bg-body"
            toolbarClass="height-60"
            titleFont="h3"
            dialogContentClassName="d-flex flex-column"
            subtitleClass="text-muted fmt-5 fmln-10"
            >
            {loading && <div className='h-100'><CircularLoader /></div>}
            {!loading && <List
                disablePadding
                className="position-relative overflow-auto"
                style={{ display: "table-row" }}
            >
                <ListInner docs={docs} />
            </List>}
        </Dialog>
    )
}

export default BroadcastRecipientsList
