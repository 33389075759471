/* eslint-disable no-useless-escape */

import _ from "lodash";
export const DEFAULT_ERROR =
  "Something seems to have gone wrong. Please try again";
export const FETCH_ERROR="Unable to fetch. Please Try Again";
export const OFFLINE_ERROR="You seem to be offline. Please try again"
export const DEFAULT_VID_UPLOAD_ERROR="Video could not be uploaded. Please try again.";
export const NETWORK_ERROR = "Could not load results this time. Please refresh.";
export const DrawerWidth = 240;
export const DrawerWidthMinified = 60;
export const RandomColors = [
  //no green or light blue to be added
  "#f14e3f",
  "#f5a623",
  "#bd10e0",
  "#FF6E40",
  "#FF80AB",
];
export const CalendarColors = {
  intro:"#FF8500",//introduction call (trainer-client)
  call:"#0066FF",//vid call
  past:"#70829B",
  missed:'#D73717',
  ongoiong: '#00BB9E',
  cancelled:'#65768C',
  completed: '#00BB9E',
  upcoming: '#0066FF',
}
export const ArrowColors={ red: '#E02F2F', green: '#27AE60', grey: '#70829B' };
export const MealColors = ["#05b715", "#a3b705"];
export const SupplementColors = ["#ff8360", "#ffb148"];
export const WaterColors = ["#006fd3"];
export const ActivityColors = ["#FF8259"]
export const DEFAULT_UNIT_SYSTEM = "metric";
export const GRAPH_LINE_COLOR = "#A2AEBF";

export const days = [
  { label: "Monday" },
  { label: "Tuesday" },
  { label: "Wednesday" },
  { label: "Thursday" },
  { label: "Friday" },
  { label: "Saturday" },
  { label: "Sunday" }
];
export const oprtnlDateFormat = "YYYYMMDD";
export const graphDateFormat = "MM/DD"
export const EDIT_PLAN_EXTENSION = 7;
export const USER_VIEW_ACTIVATION_TABS = [{ i: "trends", name: "Trends" }];
// export const USER_VIEW_ACTIVATION_TABS = [{ i: "checkins", name: "CheckIns" },{ i: "progress", name: "Progress" }, { i: "stats", name: "Stats" }];
export const USER_VIEW_ASSIGN_TABS = [{ i: "progress", name: "Progression" }];

export const INITIAL_USER_VIEW_TABS = [
  { i: "overview", name: "Overview" },
  { i: "schedule", name: "Schedule" },
  { i: "chats", name: "Chats" },
  // { i: "notes", name: "Notes" },
];

export const TRAINER_VIEW_TABS = [
  {i:'about', name:'About'},
  {i:'reviews', name:'Reviews'},
];

export const TRAINER_VIEW_ACTIVATION_TAB = [
  {i:'schedule', name:'Schedule'},
]

export const PAYMENT_GATEWAY = [
  {i:'stripe', name:'Stripe'},
  {i:'paypal', name:'Paypal'}
];

export const durationOptions = ["weeks", "days", "months"];

export const currencies = [
  {value:"USD",symbol:"$"},
  {value:"GBP",symbol:"£"},
  {value:"EUR",symbol:"€"},
  {value:"AUD",symbol:"AU$"},
  {value:"CAD",symbol:"CA$"},
  {value:"INR",symbol:"₹"}
];

// images
export const HOMEPAGE_THUMBNAIL =
  "https://static.onecms.io/wp-content/uploads/sites/35/2012/05/16182731/hiit-workouts-for-women-weight-loss.jpg";
//pdf cnd base url
export const CDN_DOCUMENT_BASE_URL = `https://cdn-files.fitbudd.com`  
//error
export const ERROR_MIN_DURATION = "Please enter a duration within 1-1440 minutes";
export const ERROR_DESCRIPTION = "Please enter a valid description";
export const ERROR_DECIMAL_DURATION = "Please enter a valid duration";
export const ERROR_WATER_VALID = "Please enter valid quantity of water";
export const ERROR_WATER_FORMAT =
  "Please correct the format. No (.) is allowed";
export const ERROR_REF_NAME = "Please enter a valid reference name";
export const ERROR_TITLE = field => `Please enter a valid ${field}`;
export const ERROR_URL = "Please provide a valid url";
export const ERROR_USER_ADD = field => `Please enter a valid ${field === 'duration' ? 'value for' : ''} ${field} ${field === 'email' ? 'address' : ''}`;
export const SCHEDULE_MIN_VALID_DAYS = 7;
export const SCHEDULE_MAX_VALID_DAYS = 1000;
export const SCHED_MIN_DAY_ERR = () =>
  `Minimum duration must be ${SCHEDULE_MIN_VALID_DAYS} days`;
export const SCHED_MAX_DAY_ERR = () =>
  `Maximun duration can be ${SCHEDULE_MAX_VALID_DAYS} days`;
export const DISABLED_CHAT_TEXT = "User Chat Disabled";
export const ERROR_CARIO_DUR =
  "The value should not be less than 60 or greater than 200";
export const ERROR_MEDIA_URL = "Please provide a valid Youtube or Vimeo video link";
export const ERROR_YOUTUBE_URL = "Please add a valid Youtube link";
export const ERROR_VIMEO_URL = "Please add a valid Vimeo link";
export const ERROR_MEDIA_UPLOAD = "Please upload a valid file";
export const ERROR_FREQUENCY = "Please enter a valid frequency "
//worker keys
export const WORKER_KEYS = ["chats", "checkins" ];
//Elastic keys
export const ELASTIC_KEYS = ["browse", "plansToAssign", "pendingSignIn", "active", "inactive", "leads", "onboarded"];
export const EXPORTABLE = ["plansToAssign", "active", "inactive", "leads"];
export const NON_FILTER_LISTS = ["chats","checkins", "onboarded"];
export const NON_QUICK_FILTER_LISTS = ["onboarded"];

export const PLANS_TO_UPDATE_BUFFER_IN_DAYS = 14;
export const DEFAULT_CHECKIN_CONFIG = {
  checkin_due_count: 2,
  checkin_frequency: 7,
  checkin_overdue_count: 2
};

export const ACCEPT_IMAGE_TYPE = ".png, .jpg, .jpeg";
export const DEFAULT_SCHEDULE_WATER = 2000;
export const DEFAULT_SCHEDULE_ACTIVITY = {
  distance: 0,
  energy: 0,
  flights: 0,
  steps: 0
}

export const EXERCISE_STORAGE_FILE_PATH = "exercise/image/original/";
export const WORKOUTS_STORAGE_FILE_PATH = "workout/image/original/";
export const MEAL_STORAGE_FILE_PATH = "meal/image/original/";
export const FOODRECIPES_STORAGE_FILE_PATH = "foodrecipe/image/original/";
export const COMPANIES_STORAGE_FILE_PATH = "companies/image/original/";
export const CHALLENGE_STORAGE_FILE_PATH = "challenge/image/original/";

export const YOUTUBE_VIDEO_URL_REGEX = new RegExp(/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v|shorts\/)?)([\w\-]+)(\S+)?$/);
export const VIMEO_VIDEO_URL_REGEX = new RegExp(/(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/);
export const EMAIL_REGEX =  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const TWO_DECIMAL_NO_REGEX = new RegExp(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/);

export const GOOGLE_MEET_REGEX = new RegExp(/^https:\/\/meet\.google\.com\/[^]*/);
export const MICROSOFT_MEET_REGEX = new RegExp(    /^https?:\/\/[a-z0-9.-]+\.microsoft\.com/i);

export const VIDEO_UPLOAD_VALIDATION_MSG = "Only Mp4 and Mpv videos are supported."
export const DUPLICATE_USER = "A user with the same email address already exists"
export const MAX_USER_INVITE = 'Cannot invite more customers while on trial. Please consider upgrading';

export const UNIT_TITLE_CONSTANT = {
  weight: "Weight",
  height: "Height",
  length: "Circumference",
  meal_weight: "Food (weight)",
  meal_volume: "Food (volume)",
  water: "Water",
  distance: "Distance",
  cholesterol: "Cholesterol",
};

export const UNIT_SYSTEM = [
  { value: "imperial", label: "Imperial" },
  { value: "imperial_us", label: "Imperial US" },
  { value: "metric", label: "Metric" },
  { value: "custom", label: "Custom" }
];

export const UNIT_SYSTEM_NAME_CONSTANT = {
  imperial: "Imperial",
  imperial_us: "Imperial US",
  metric: "Metric"
};

export const ACTIVITY_NAME_CONSTANT = ["steps", "distance", "flights", "energy"];

export const FILE_STATUS = {
  uploading: "UPLOADING",
  processing: "PROCESSING",
  error: "ERROR",
  complete: "COMPLETE"
};

export const TAG_INDEX = "company_tags"
export const START_DATE_THRESHOLD = 14

export const QUICK_FILTER_MAP = {
  "checkins": [
    {
      key: "unseen_checkins",
      label: "To Review",
      helperText: "Checkins that have not been reviewed by you",
      value: true
    }
  ],
  "chats": [
    {
      key: "unread_messages",
      label: "Unread",
      helperText: "DMs with unread messages",
      value: true
    },
    {
      key: "flagged_messages",
      label: "Starred",
      helperText: "DMs that have been starred",
      value: true
    }
  ],
  "groupChats": [
    {
      key: "unread_messages",
      label: "Unread",
      helperText: "Groups with unread messages",
      value: true
    }
  ],
  "leads": [
    {
      key: "onboarding_pending",
      label: "Onboarding Pending",
      helperText: "Clients who are yet to complete their onboarding",
      value: true
    },
    {
      key: "onboarding_completed",
      label: "Onboarding Done",
      helperText: "Clients who have completed onboarding and a schedule is yet to be assigned",
      value: true
    }
  ],
  "plansToAssign": [{
    key: "pending_signin",
    label: "Signup Pending",
    helperText: "Clients who have purchased a plan or were added by you but they have not signed in the app yet.",
    value: true
  }, {
    key: "pta_onboarding_pending",
    label: "Onboarding Pending",
    helperText: "Clients who are yet to complete their onboarding",
    value: true
  }, {
    key: "onboarding_completed",
    label: "Onboarding Done",
    helperText: "Clients who have completed onboarding and a schedule is yet to be assigned",
    value: true
  }],
  "active": [{
    key: "plans_to_update",
    label: "Plans to Update",
    helperText: "Assigned schedule has already finished or will finish in the next 7 days",
    value: true
  },
  {
    key: "upcoming_renewal",
    label: "Upcoming Renewal",
    helperText: "Subscription renewals scheduled in next 7 days",
    noChip: true,
    value: true
  },
  {
    key: "completing_soon",
    label: "Completing Soon",
    helperText: "One time purchase plans completing in next 7 days",
    value: true
  },
  {
    key: "renewal_overdue",
    label: "Payment Pending",
    helperText: "Subscription renewals that are pending payment",
    value: true
  },{
    key: "in_trial",
    label: "In Trial",
    helperText: "Clients who are currently on a Trial Plan",
    value: true
  },{
    key:"manually_added",
    label:"Manually Added",
    helperText:"Clients who were added manually from the dashboard",
    value:true,
    noChip: true,
  },{
    key:"is_iap",
    label:"In App Subscriptions",
    helperText:"Subscriptions purchased though Apple or Google",
    value:true,
    noChip: true,
  }],
  "inactive": [{
    key: "recently_completed",
    label: "Recently Completed",
    helperText: "Clients who have recently completed their plan",
    noChip: true,
    value: true
  },
  {
    key: "plan_completed",
    label: "Plan Completed",
    helperText: "Clients who have completed their plan more than 7 days ago",
    value: true
  },
  {
    key: "deactivated",
    label: "Deactivated",
    helperText: "Clients whose plan was manually deactivated",
    value: true
  }],
  "browse": [{
    key: "leads",
    label: "Leads",
    value: true,
    filterKey: 'multi_list_filters'
  },
  {
    key: "plansToAssign",
    label: "Plans To Assign",
    value: true,
    filterKey: 'multi_list_filters'
  },
  {
    key: "active",
    label: "Active Clients",
    value: true,
    filterKey: 'multi_list_filters'
  },
  {
    key: "inactive",
    label: "Inactive Clients",
    value: true,
    filterKey: 'multi_list_filters'
  }
  ],
  "challenges":[{
    key:'active',
    label: "Active",
    filterKey: 'challenge_filters.status',
  },{
    key:'draft',
    label: "Draft",
    filterKey: 'challenge_filters.status',
  },{
    key:'ended',
    label: "Ended",
    filterKey: 'challenge_filters.status',
  }]
}
export const ATTRIBUTE_FILTER_MAP = [
  {
    label:'Gender',
    value:'gender',
    options:[
      {
        key: "male",
        label: "Male",
        value: 'Male'
      },
      {
        key: "female",
        label: "Female",
        value: 'Female'
      },
      {
        key: "other",
        label: "Other",
        value: 'Other'
      }
    ]
  }
]
export const getQuickFilters = (keyName, chipsOnly = false) => {
  let out = QUICK_FILTER_MAP[keyName] || [];
  if(keyName === 'browse') {
    out = out.concat(_.flatten(Object.values(_.omit(QUICK_FILTER_MAP, ['browse']))).map(o => {
      return {...o, noChip: true }
    }))
  }
  else if(keyName===VIEWS_CONSTANTS.CHALLENGES){
    out = out.concat(_.flatten(Object.values(_.omit(QUICK_FILTER_MAP, ['browse']))).map(o => {
      return {...o, noChip: true }
    }))
  }
  if (chipsOnly) {
    out = out.filter(x => !x.noChip);
  }
  return out;
}

export const packModeLabel = {
  "one_time": "One Time Payment",
  "subscription": "Subscription",
  "challenge": "Challenge"
}

export const DEFAULT_BUFFER_DAYS = 7
//Question types for questionnaire
export const QUES_TYPE_MEASUREABLE = 'measurables';
export const QUES_TYPE_TARGET = 'target_measurables';
export const QUES_TYPE_SCALE = 'scale';
export const QUES_TYPE_FREE_TEXT = 'free_text';
export const QUES_TYPE_PROFILE = 'profile';
export const QUES_TYPE_PHOTOS = 'photos';
export const QUES_TYPE_BMR = 'bmr';
export const QUES_TYPE_SINGLE_SELECT = 'single_select';
export const QUES_TYPE_MULTI_SELECT = 'multi_select';
export const EXCL_QUES_TYPES = [QUES_TYPE_PROFILE, QUES_TYPE_PHOTOS, QUES_TYPE_MEASUREABLE, QUES_TYPE_TARGET];
export const ALL_QUES_TYPES = [
  QUES_TYPE_PROFILE, QUES_TYPE_MEASUREABLE, QUES_TYPE_TARGET, QUES_TYPE_PHOTOS,
  QUES_TYPE_SINGLE_SELECT, QUES_TYPE_MULTI_SELECT, QUES_TYPE_FREE_TEXT,
  QUES_TYPE_SCALE, QUES_TYPE_BMR,
];
export const BOOKING_CHECK_THRESHOLD = 60; //in seconds
export const NEXT_BOOKING_THRESHOLD = 10; //in minutes

export const DEFAULT_VIDEO_CONSTRAINTS = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';


export const SUBSCRIPTION_LOOKAHEAD_DAYS = 28;
export const DEFAULT_CURRENCY='USD';
export const HUBSPOT_PROPS = {
  CLIENT_ADDED: 'client_added',
  CLIENT_PLAN_ASSIGNED: 'client_plan_assigned',
  CLIENT_PLAN_ACTIVATED: 'client_plan_activated',
  PLAN_ONETIME_CREATED: 'plan_onetime_created',
  PLAN_SUBSCRIPTION_CREATED: 'plan_subscription_created',
  LAST_LOGIN: 'last_login',
  FITNESS_SCHEDULE_ASSIGNED: 'fitness_schedule_assigned',
  QUESTIONNAIRE_VIEWED:'questionnaire_viewed',
  QUESTIONNAIRE_UPDATED:'questionnaire_updated',
  FOOD_CREATED:'food_created',
  RECIPE_CREATED: 'recipe_created',
  SUPPLEMENT_CREATED: 'supplement_created',
  MEAL_PLAN_CREATED: 'meal_plan_created',
  SUPPLEMENT_PLAN_CREATED: 'supplement_plan_created',
  FOOD_DB_USED: 'food_db_used',
  FOOD_DB_BROWSED: 'food_db_browsed',
  NUTRITION_SCHEDULE_CREATED: 'nutrition_schedule_created',
  NUTRITION_SCHEDULE_ASSIGNED: 'nutrition_schedule_assigned',
  EXERCISE_CREATED: 'exercise_created',
  WORKOUT_CREATED: 'workout_created',
  CARDIO_CREATED: 'cardio_created',
  LONGFORM_CREATED: 'longform_created',
  FITNESS_SCHEDULE_CREATED:'fitness_schedule_created',
  EXERCISE_DB_USED: 'ex_db_used',
  EXERCISE_DB_BROWSED: 'ex_db_browsed',
  CHECKIN_VIEWED:'checkin_viewed',
  LAST_ACTIVE_TIMESTAMP: 'last_active_timestamp',
  BOOKED_DEMO: 'booked_demo',
  WATCHED_CLIENT_APP_VIDEO: 'watched_client_app_video',
  WATCHED_FITNESS_VIDEO: 'watched_fitness_video',
  WATCHED_NUTRITION_VIDEO: 'watched_nutrition_video',
  WATCHED_ADD_CLIENT_VIDEO: 'watched_add_client_video',
  WATCHED_SCHEDULE_VIDEO: 'watched_schedule_video',
  WATCHED_CATEGORY_VIDEO: 'watched_client_category_video',
  WATCHED_SETTINGS_VIDEO: 'watched_settings_video',
  UPGRADE_NOW: 'upgrade_now',
  BILLING: 'upgrade_now_billing',
  UPGRADE_TO_SILVER: 'initiate_upgrade_to_silver',
  UPGRADE_TO_GOLD: 'initiate_upgrade_to_gold',
  CONTACT_TYPE: 'contact_type',
  ACCOUNT_ACCESS_TYPE:'account_access_type',
  CID: 'cid',
  SEO_CONSENT: 'seo_consent',
  CITY: 'city',
  PHONE: 'phone',
  ZIP: 'zip',
  COUNTRY: 'country',
  STATE: 'state',
  TRAINER_CATEGORY: 'trainer_category',
  TRAINER_AUDIENCE: 'trainer_audience',
  TRAINER_AREA: 'trainer_area',
  TRAINER_MAP_LINK: 'trainer_map_link',
  SPECIALITY: 'speciality',
  SPECIALITY_OTHER: 'speciality___other',
  CERTIFICATIONS: 'certifications',
  CERTIFICATION_OTHER: 'certification___others',
  TRAINER_INSTAGRAM_HANDLE: 'instagram_handle',
  PAYMENT_SERVICE_STATUS: 'payment_service_status',
  PAYMENT_SERVICE_NAME: 'payment_service_name',
  //new checkout hubSpot kes
  PAYMENT_STATUS:'payment_status',
  LAST_CHECKOUT_DATE :'last_checkout_date',
  // PAID_PLAN_TYPE:'paid_plan_type',
  // CURRENT_PAID_PLAN_TYPE:'current_paid_plan_type',
  ACCOUNT_REACTIVATION_REQUEST :"account_reactivation_request_received",
  CLIENT_APP_PUBLISHED : "client_app_theme_published"
};
export const REFRESH_HEIGHT=40;
export const EXERCISE_SINGLE_REP_DURATION=6//seconds
export const MAX_REP_DURATION=60;//seconds
export const MAX_REP_VALUE=9999;


export const VIEWS_CONSTANTS = {
  EXERCISE:"exercises",
  WORKOUT:"workouts",
  CARDIO:"cardio",
  SCHEDULE:"schedules",
  FOOD:"foods",
  RECIPE:"recipes",
  MEAL:"meals",
  SUPPLEMENT:"supps",
  SUPPLEMENTS:"supplements",
  MEAL_SCHEDULE:"mlSchedules",
  EXPLORE_WORKOUT:"explore_workout",
  EXPLORE_NUTRITION :"explore_nutrition",
  USER:'user',
  CHATS:'chats',
  CHECKINS:'checkins',
  BROADCAST:'broadcast',
  PLAN:"plan",
  CHALLENGES:'challenges',
  SERVICES:"services"
}
export const DEFAULT_MEDIA_TYPE="video";

export const DRAFT_STATES={
  PUBLISHED:'published',
  DRAFT_ONLY:"draft_only",
  DRAFT_ASWELL:"draft_aswell"
}
export const PUBLISHED="PUBLISHED";
export const DRAFT="DRAFT";

export const TO_PUBLISH_WARNING_MSG="Publishing of the last draft is still in progress. Saving new changes will stop the publish and override the last draft.";

export const MUSIC_ON="music_on";
export const MUSIC_OFF="music_off";

export const DISCARD_MSG="Changes have not been saved yet. Do you want to discard them?"
export const EMPTY_SCH_DISCARD_MSG="This schedule is empty, are you sure you want to exit?"
export const EMPTY_WO_DISCARD_MSG="This workout is empty, are you sure you want to exit?"
export const EMPTY_MEAL_DISCARD_MSG="This meal is empty, are you sure you want to exit?"
export const EMPTY_DOC_WARN_MSG=(keyName)=>{
  switch(keyName){
    case "wocardio":
    case "workouts":return"Empty Workouts can't be added";
    case "meals":return"Empty Meals can't be added";
    case "schedules":return "Empty Schedules can't be added";
    case "mlSchedules":return "Empty Meal Schedules can't be added";
    default:return"Empty documents can't be added";
  }
}

export const DEFAULT_DEBOUNCE_WAIT = 300;
export const MEASURE_BY_OPTIONS=[{label:'Weight',value:"meal_weight"},{label:"Volume",value:"meal_volume"}]

export const TRAINER_CATEGORY_OPTIONS = [
  { label: 'Personal Trainer', value: 'Personal Trainer' },
  { label: 'Dance Instructor', value: 'Dance Instructor' },
  { label: 'Wellness Coach', value: 'Wellness Coach' },
  { label: 'Sports Coach', value: 'Sports Coach' },
  { label: 'Gym Trainer', value: 'Gym Trainer' },
  { label: 'Nutritionist', value: 'Nutritionist' },
  { label: 'Yoga Instructor', value: 'Yoga Instructor' },
  { label: 'Zumba Instructor', value: 'Zumba Instructor' },
  { label: 'Gym / Fitness Studio Owner', value: 'Gym / Fitness Studio Owner' },
  { label: 'Gym / Fitness Studio Manager', value: 'Gym / Fitness Studio Manager' },
  { label: 'add your own', value: 'custom' },
];

export const TRAINER_TAGS = [
  { label: 'Adolescents', value: 'Adolescents' },
  { label: 'Adults', value: 'Adults' },
  { label: 'Aerobics', value: 'Aerobics' },
  { label: 'Athletes', value: 'Athletes' },
  { label: 'Beginners', value: 'Beginners' },
  { label: 'Bodybuilding', value: 'Bodybuilding' },
  { label: 'Disabled', value: 'Disabled' },
  { label: 'Home', value: 'Home' },
  { label: 'Injured', value: 'Injured' },
  { label: 'Kids', value: 'Kids' },
  { label: 'Men', value: 'Men' },
  { label: 'Moms', value: 'Moms' },
  { label: 'Over 40', value: 'Over 40' },
  { label: 'Over 50', value: 'Over 50' },
  { label: 'Over 60', value: 'Over 60' },
  { label: 'PCOS', value: 'PCOS' },
  { label: 'Postnatal Women', value: 'Postnatal Women' },
  { label: 'Pregnant Women', value: 'Pregnant Women' },
  { label: 'Seniors', value: 'Seniors' },
  { label: 'Teenager', value: 'Teenager' },
  { label: 'Weightlifting', value: 'Weightlifting' },
  { label: 'Weight gain', value: 'Weight gain' },
  { label: 'Weight loss', value: 'Weight loss' },
  { label: 'Women', value: 'Women' },
];

export const SPECIALITY_TAGS = [
  {"label": "Yoga", "value": "Yoga"},
  {"label": "Calisthenics", "value": "Calisthenics"},
  {"label": "CrossFit", "value": "CrossFit"},
  {"label": "Bodybuilding", "value": "Bodybuilding"},
  {"label": "Pilates", "value": "Pilates"},
  {"label": "HIIT (High-Intensity Interval Training)", "value": "HIIT (High-Intensity Interval Training)"},
  {"label": "Functional Fitness", "value": "Functional Fitness"},
  {"label": "Powerlifting", "value": "Powerlifting"},
  {"label": "Zumba", "value": "Zumba"},
  {"label": "Martial Arts Fitness", "value": "Martial Arts Fitness"},
  {"label": "Mental wellness", "value": "Mental wellness"},
  {"label": "Sports Coaching", "value": "Sports Coaching"}
]

export const CERTIFICATIONS_OPTIONS = [
  {"label": "NASM", "value": "NASM"},
  {"label": "ACE", "value": "ACE"},
  {"label": "ACSM", "value": "ACSM"},
  {"label": "ISSA", "value": "ISSA"},
  {"label": "NSCA", "value": "NSCA"},
  {"label": "CPT", "value": "CPT"},
  {"label": "NSCA-CPT", "value": "NSCA-CPT"},
  {"label": "CSCS", "value": "CSCS"},
  {"label": "NASM-CPT", "value": "NASM-CPT"},
  {"label": "ACE-CPT", "value": "ACE-CPT"},
  {"label": "AFAA", "value": "AFAA"},
  {"label": "CrossFit Level 1", "value": "CrossFit Level 1"},
  {"label": "Pilates Method Alliance", "value": "Pilates Method Alliance"},
  {"label": "TRX", "value": "TRX"},
  {"label": "ISSN", "value": "ISSN"},
  {"label": "N/A", "value": "N/A"}
]


export const SESSION_REDIRECT_URL = "redirect_url";
