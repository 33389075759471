import React, { useContext, useEffect, useState, useCallback, useMemo, useRef } from 'react'
import _ from "lodash";
import moment from "moment";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import InfiniteScroll from "react-infinite-scroll-component";
import {
    ListItem,
    ListItemText,
    CircularProgress,
    Typography,
    Link,
    IconButton,
    Menu,
    MenuItem
} from "@material-ui/core";
import ImgAspectFill from "fitbud/components/imgAspectHOC";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { getHeightWidthUisngFormula } from "fitbud/utils/helpers";
import Linkify from "linkifyjs/react";
import { AttachmentList } from 'fitbud/views/chats/chatView';
import ScrollToBottom from "fitbud/views/chats/scrollToBottom";
import { pluralize } from "fitbud/utils/helpers";
import { BroadcastMessageViewPlaceholder } from 'fitbud/views/broadcasts/placeholder';
import firebase from "fitbud/firebase";
import CalendarFold from 'fitbud/icons/calendarFold';
import ScheduleDialog from './scheduleDialog';
import { firestore } from 'firebase';
import { useDispatch } from 'react-redux';
import appRdxFns from "fitbud/redux/app";
import { useSnackbar } from 'notistack';
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles(theme => ({
    chatArea: {
        background: "#ffffff",
        flex: 1,
        padding: '0 20px',
        overflow: "scroll",
    },
    bubble: {
        width: "100%",
        whiteSpace: "pre-wrap",
        marginTop: 2
    },
    rightMsg: {
        width: "max-content",
        background: "#F0F3F5",
        borderBottom: 'none',
        maxWidth: "70%",
        wordWrap: "break-word",
        marginLeft: "auto",
        borderRadius: "15px 0 15px  15px"
    },
    mediaRight: {
        padding: 0,
        marginLeft: "auto",
        border: "none"
    },
    chip: {
        backgroundColor: "#F2F4F7",
        margin: '0 0 8px 8px',
        height: 25,
        color: '#65768C'
    },
}));



function BroadcastMessageListView(props) {
    const { messages, fetchMore, lastFetchedMsg, setMessageData, fetching, broadcastGrpId } = props;
    const [show, setShow] = useState(false);
    const [scheduleData, setScheduleData] = useState(null);
    const selectedMsg = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const d = useDispatch();
    const {showLoader, hideLoader} = appRdxFns(d)
    const {enqueueSnackbar} = useSnackbar()

    const classes = useStyles()
    const view_width =
        document.getElementById("chatArea") &&
        document.getElementById("chatArea").getBoundingClientRect().width;


    const decideToShowUnread = useCallback(
        _.debounce(() => {
            const chatarea = document.getElementById("chatArea");
            const last_msg = document.getElementById(`broadcastMessagesEnd`);
            if (!chatarea || !last_msg) return;
            if (last_msg.getBoundingClientRect().bottom - chatarea.getBoundingClientRect().bottom <= 100) {
                setShow(false);
            } else {
                setShow((chatarea.getBoundingClientRect().bottom < last_msg.getBoundingClientRect().bottom))
            }
        }, 100),
        [],
    )

    const openSchedule = (data) => { 
        setScheduleData({
            notification_only: data.notification_only,
            time: moment(data.time.toMillis()),
            id: data.id
        });
    }

    const openMenu = (e, msgData) => {
        selectedMsg.current = {...msgData};
        setAnchorEl(e.currentTarget);
    }

    const closeMenu = () => {
        setAnchorEl(null);
    }

    const updateSchedule = async (data) => {
      const { time, notification_only } = data;
      const broadcastRef = firebase
        .firestore()
        .collection(`groups/${broadcastGrpId}/broadcasts`)
        .doc(scheduleData.id);

        try {
            showLoader();
            await broadcastRef.update({
                "schedule.time": firestore.Timestamp.fromDate(time.toDate()),
                notification_only,
                "payload._uat": firebase.firestore.FieldValue.serverTimestamp()
            })
            hideLoader();
            setScheduleData(null);
            enqueueSnackbar("Schedule updated successfully", { variant: "success" });
        } catch (error) {
            console.log(error);
            enqueueSnackbar("Failed to update schedule", { variant: "error" });
            hideLoader();
        }
    };

    const cancelSchedule = async () => {
      const broadcastRef = firebase
        .firestore()
        .collection(`groups/${broadcastGrpId}/broadcasts`)
        .doc(selectedMsg.current.id);

        try {
            showLoader();
            await broadcastRef.update({
                "schedule.cancelled": true,
                "payload._uat": firebase.firestore.FieldValue.serverTimestamp()
            })
            hideLoader();
            enqueueSnackbar("Schedule cancelled successfully", { variant: "success" });
        } catch (error) {
            console.log(error);
            enqueueSnackbar("Failed to cancel schedule", { variant: "error" });
            hideLoader();
        }
    }

    useEffect(() => {
        const chatarea = document.getElementById("chatArea");
        chatarea.addEventListener("scroll", decideToShowUnread);
        return () => {
            chatarea && chatarea.removeEventListener("scroll", decideToShowUnread);
        };

    }, [decideToShowUnread]);

    return (
      <div id="chatArea" className={classes.chatArea}>
        <div className={clsx('mx-auto text-center pt-1 ', lastFetchedMsg && fetching ? 'visible' : 'd-none')}>
          <CircularProgress size={22} />
        </div>
        <InfiniteScroll
          dataLength={messages.length}
          next={fetchMore}
          hasMore={lastFetchedMsg}
          scrollableTarget="chatArea"
          inverse
        >
          {messages &&
            messages.map((msg, index) => (
              <Message
                key={msg.id}
                data={msg.data()}
                msgId={msg.id}
                view_width={view_width}
                openRecipientModal={setMessageData}
                broadcastGrpId={broadcastGrpId}
                openSchedule={openSchedule}
                openMenu={openMenu}
              />
            ))}
        </InfiniteScroll>
        {(!messages || !messages.length) && <BroadcastMessageViewPlaceholder />}
        <div id={`broadcastMessagesEnd`} />
        <ScrollToBottom show={show} />
        {scheduleData && (
          <ScheduleDialog onClose={() => setScheduleData(null)} onSave={updateSchedule} data={scheduleData} />
        )}
        <Menu
          anchorEl={anchorEl}
          elevation={4}
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          onClose={closeMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          classes={{
            paper: `fb-border ${classes.zIndex}`,
            list: "p-0"
          }}
          id="delete_pop_up"
          onClick={closeMenu}
        >
          <MenuItem key="reaction" onClick={cancelSchedule}>
            Cancel Schedule
          </MenuItem>
        </Menu>
      </div>
    );
}

export default BroadcastMessageListView

function checkIfMsgScheduled(msgData) {
    if (!msgData.schedule || !(msgData.schedule.time instanceof firestore.Timestamp))
        return false;
    const currentTime = moment();
    
    const time = moment(msgData.schedule.time.toMillis());
    const result = time.isAfter(currentTime);

    return result;
}

function Message({ data, msgId, view_width, openRecipientModal, broadcastGrpId, openSchedule, openMenu }) {
  const classes = useStyles();
  const { cid } = useContext(FirebaseAuthContext);
  let msgPayload = data.payload;
  const { height, width } = useMemo(() => {
    if (!msgPayload.media) return {};
    const aspect = msgPayload.media.aspect || 1;
    let { height_new, width_new } = getHeightWidthUisngFormula(view_width, aspect);

    return { height: height_new, width: width_new };
  }, [msgPayload.media, view_width]);

  let isMsgScheduled = checkIfMsgScheduled(data);

  const displayTime = (function () {
    let time;
    if (data.schedule?.time?.toMillis) {
      time = moment(data.schedule.time.toMillis());
    } else if (msgPayload.timestamp.toMillis) {
      time = moment(msgPayload.timestamp.toMillis());
    } else {
      time = moment(data.ts);
    }
    return time.format('DD MMM YYYY • HH:mm');
  })();

  function handleScheduleClick() {
    openSchedule({
      notification_only: data.notification_only,
      time: data.schedule.time,
      id: msgId
    });
  }

  function renderMsgStatus() {
    if(data.schedule && data.schedule.cancelled) {
      return null;
    }
    if (isMsgScheduled)
      return (
        <Link
          component="button"
          variant="caption"
          underline="none"
          onClick={handleScheduleClick}
          className="d-flex align-items-center"
        >
          <CalendarFold className="mr-4px" /> Scheduled for
        </Link>
      );
    if (data.recipientsV2 && data._sent)
      return (
        <Link component="button" variant="caption" underline="none" onClick={() => openRecipientModal(data)}>
          {`${(data.recipientsV2 || []).length} Recipient${pluralize((data.recipientsV2 || []).length)}`}
        </Link>
      );

    return <Typography className="font_13_500 text-muted fml-15">Sending...</Typography>;
  }

  function renderMsgTime() {
    if(data.schedule && data.schedule.cancelled) {
      return (
        <Typography className="font_13_500 text-muted fml-15">Cancelled</Typography>
      )
    }

    if(msgPayload.timestamp) {
      return (
        <Typography className="font_13_500 text-muted fml-15">{displayTime}</Typography>
      )
    }
  }

  return (
    <div
      key={msgId}
      id={msgId}
      className="fmy-40 d-flex flex-column align-items-end text-right ml-auto"
      style={{ width: '70%' }}
    >
      <div className={`${classes.bubble} fmb-10`}>
        <ListItem
          divider
          className={clsx(
            msgPayload.type !== 'text' && msgPayload.type !== 'attachment' && msgPayload.type !== 'audio'
              ? `${classes.mediaRight} d-flex justify-content-end`
              : classes.rightMsg
          )}
          component="div"
        >
          {msgPayload.type === 'text' && (
            <ListItemText>
              <Linkify>{msgPayload.body}</Linkify>
            </ListItemText>
          )}
          {(msgPayload.type === 'attachment' || msgPayload.type === 'audio' || msgPayload.type === 'video') && (
            <AttachmentList attachmentData={msgPayload} key={msgPayload.attachment.identifier} />
          )}
          {msgPayload.type === 'image' && (
            <div
              style={{
                height,
                width,
              }}
            >
              {msgPayload.media && (
                <ImgAspectFill
                  authorId={cid}
                  authUserId={cid}
                  targetHeight={height}
                  targetWidth={width}
                  identifier={msgPayload.media.identifier}
                  completed={msgPayload.media.completed}
                  aspect={msgPayload.media.aspect || 1}
                  imageData={msgPayload.media.imageData}
                  storageFolderRef={`companies/${broadcastGrpId}/broadcasts/chats`}
                  bucketName="chat"
                  style={{ width: '100%', borderRadius: '10px', borderTopRightRadius: 0 }}
                  alt={'Broadcast Image'}
                  ext={'jpg'}
                />
              )}
            </div>
          )}
        </ListItem>
      </div>
      <div className="d-flex justify-content-end align-items-center w-100">
        {renderMsgStatus()}
        {renderMsgTime()}
        {data.schedule && !data.schedule.cancelled && isMsgScheduled && (
          <IconButton size='small' edge='end' onClick={(e) => openMenu(e, {...data, id: msgId})} >
            <MoreVertIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
}