import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function CalendarCog({ ...props }) {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.6667 14.0833L11.9167 13.75"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.6667 15.9141L11.9167 16.2474"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.3333 1.67188V5.00521"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.0833 12.6641L13.75 11.9141"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.0833 17.332L13.75 18.082"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.25 11.9141L15.9166 12.6641"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.25 18.082L15.9166 17.332"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.5 8.74479V4.99479C17.5 4.55276 17.3244 4.12884 17.0118 3.81628C16.6993 3.50372 16.2754 3.32813 15.8333 3.32812H4.16667C3.72464 3.32813 3.30072 3.50372 2.98816 3.81628C2.67559 4.12884 2.5 4.55276 2.5 4.99479V16.6615C2.5 17.1035 2.67559 17.5274 2.98816 17.84C3.30072 18.1525 3.72464 18.3281 4.16667 18.3281H9.16667"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.0833 13.75L17.3333 14.0833"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.0834 16.2474L17.3334 15.9141"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M2.5 8.32812H17.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M6.66675 1.67188V5.00521"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15 17.5C16.3807 17.5 17.5 16.3807 17.5 15C17.5 13.6193 16.3807 12.5 15 12.5C13.6193 12.5 12.5 13.6193 12.5 15C12.5 16.3807 13.6193 17.5 15 17.5Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
