import React, { useContext } from "react";
import { getQuickFilters } from "fitbud/utils/constants";
import {
  FormControlLabel,
  FormHelperText,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { PlanContext } from "fitbud/views/plan/planProvider";


const QuickFilterCheckbox = (props) => {
  const { componentState: state = {}, dispatch, type, keyName } = props;
  const {hasAppleIAP, hasPlayIAP} = useContext(PlanContext)
  const quick_filters = getQuickFilters(keyName)
  const fields = quick_filters.map(qf => ({ ...qf, value: qf.key }))

  const handleChange = (e) => {
    const value = e.target.name;
    dispatch({ type, payload: { ...state, [value]: !state[value] } })
  }

  return (
    fields.map(f => ({ ...f, checked: state[f.value] })).map((f, i) => {
      if(f?.key === "is_iap" &&  !(hasAppleIAP || hasPlayIAP)) return null
        return (<>
          <FormControlLabel
            key={`_check${i}_${keyName}`}
            className="fmb-0"
            control={
              <Checkbox
                name={f.value}
                color="primary"
                checked={!!f.checked}
                onChange={handleChange}
                value={f.value}
                disabled={f.disabled}
              />
            }
            label={<Typography className="font_14_600">{f.label}</Typography>}
          />
          {f.helperText && <FormHelperText className="fml-30 font_12_500 fmb-8" style={{ marginTop: -2 }}>{f.helperText}</FormHelperText>}
        </>
        );
      })
  )
}

export default QuickFilterCheckbox