import firebase from 'fitbud/firebase';
import axios from 'axios';
import moment from 'moment';
import omit from 'lodash/omit';
import get from 'lodash/get';
import { convertBlobToBase64 } from 'fitbud/utils/helpers';

const getBFFClient = (token) =>
  axios.create({
    baseURL: process.env.REACT_APP_BFF_URL,
    headers: {
      'x-os-name': 'web',
      'x-client-version-number': 2,
      'fb-project': process.env.REACT_APP_FB_PROJECT,
      'fb-token': token || '',
    },
  });

let currentDeviceToken = undefined; // in-mem local copy of the token that just got registered
export const bffFCMSubscribe = async (cid, deviceToken) => {
  currentDeviceToken = deviceToken;
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/rpc/fcmSubscribe', { cid, token: deviceToken });
};

export const bffLogout = async () => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  try {
    await getBFFClient(token).post('/rpc/logout', { token: currentDeviceToken });
  } catch (e) {
    console.error(e);
  }
  await firebase.auth().signOut();
};

export const bffUpdateHubspot = async (props, noAuth) => {
  if (!props || !Object.keys(props).length) return false;
  if (!noAuth) {
    // Send request with fb-token
    const user = firebase.auth().currentUser;
    if (!user) return false;
    return user
      .getIdToken()
      .then(getBFFClient)
      .then((bff) => bff.post('/rpc/updateHubspotProp', { props }))
      .catch(console.error);
  }
  // Send request without fb-token ( used in signup )
  if (!!noAuth && !props.email) return false; // ignore email-less and FITBUDD employees
  return getBFFClient('').post('/rpc/updateHubspotProp', { props }).catch(console.error);
};

export const bffUpdateHubspotProp = async (key, value = null) =>
  bffUpdateHubspot({ [key]: value || moment().format('YYYY-MM-DD') });

export const bffGetTestAc = (cid) => getBFFClient().get(`/api/testac/${cid}`);
export const bffSwitchAcc = async (payload) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/sudo/login', payload);
};

export const bffAddCustomer2Company = (token, cid, email) =>
  getBFFClient(token).post('/rpc/addCustomer2Company', { cid, email });

export const bffMailWelcomeCustomer = (token, cid, uid) =>
  getBFFClient(token).post('/rpc/mailWelcomeCustomer', { cid, uid });

export const bffAddStaff2Company = (token, cid, email, name, roles = ['o']) =>
  getBFFClient(token).post('/rpc/inviteStaff', { cid, email, name, roles });

export const bffUpdateStaffRoles = (token, cid, uid, roles) => 
  getBFFClient(token).post('/rpc/updateStaff', { cid, uid, roles });

export const bffChangeClientMail = async (profile, email) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/rpc/changeClientMail', { profile, email });
};

export const sudoResetPwd = async (email) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/sudo/resetTrainerPwd', { email });
};

export const bffChangeEmail = async (uid, email, cid) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/rpc/changeStaffEmail', { uid, email, cid });
};

export const bffRevokeAccess = async (cid, uid, newTrainer) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/rpc/removeStaff', { cid, uid, newTrainer });
};

export const sudoAddIAPSecret = async (cid, secret, first = false) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/sudo/addIAPSecret', { cid, secret, first });
};

export const sudoMakeTestAcc = async (cid) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/sudo/mkTestAc', { cid });
};
export const bffGetContractStatus = async (cid) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/rpc/contract/status', { cid });
};

export const bffGetContractIssues = async (cid) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/rpc/contract/getIssues', { cid });
};

export const bffGetContractOopsies = async (cid) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/rpc/contract/oops', { cid });
};

export const bffSESTestMail = async (cid, email, name) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/rpc/sesTestMail', { cid, email, name });
};

export const bffGetReferrerLink = async (cid, name) => {
  const user = firebase.auth().currentUser;
  return await user
    .getIdToken()
    .then((token) => getBFFClient(token))
    .then((client) => client.post('/rpc/getReferrerLink', { cid, name }));
};

export const bffTrainerAvailability = (token, cid, start_date, end_date, trainerId) =>
  getBFFClient(token).post('/rpc/getAvailability', { cid, start_date, end_date, trainerId, mode: 'trainer' });

export const bffTrainerBookCall = (token, cid, uid, time, duration, isTrainerBooking, userProfile, trainer_id, serviceId, locationId) =>
  getBFFClient(token).post('/rpc/bookCallTrainer', {
    cid,
    uid,
    time,
    duration,
    isTrainerBooking,
    userProfile,
    trainer_id,
    serviceId,
    locationId,
  });

export const bffTrainerCancelBooking = (token, cid, time, book_id) =>
  getBFFClient(token).post('/rpc/cancelBookingTrainer', { cid, time, book_id });

export const bffTrainerRescheduleCall = (token, cid, book_id, newSlot, duration) =>
  getBFFClient(token).post('/rpc/rescheduleCallTrainer', { cid, book_id, newSlot, duration });

export const bffSetDaySchedule = (token, cid, date, slots, cancel = true, uid) =>
  getBFFClient(token).post('/rpc/setDaySchedule', { cid, date, slots, cancel, uid });

export const bffSetMasterSchedule = (token, cid, data, uid) =>
  getBFFClient(token).post('/rpc/setMasterSchedule', { cid, data, uid: uid || '' });

export const bffGetRoomToken = (token, cid, book_id, mode) =>
  getBFFClient(token).post('/rpc/getRoomToken', { cid, book_id, mode: mode || 'trainer' });

export const bffUpdateFeature = async (cid, features) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/sudo/contractFeatures', { cid, features });
};

export const bffGetCatalogByIDs = async (args) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/rpc/getCatalogByIDs', { ...args });
};
export const bffGetUserProfileByIDs = async (payload) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/rpc/getUserProfileByIds', payload);
};
export const fetchData = async (collection, cid, pg = 0, q, n, dtype, storeKey, today, maxDate, minDate, filters) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  const result = await getBFFClient(token).post('/rpc/fetchCatalog', {
    type: collection,
    cid,
    n,
    pg,
    q,
    dtype,
    storeKey,
    today,
    maxDate,
    minDate,
    filters,
  });
  return result;
};

export const fetchCIDStats = async (cid, today, maxDate, minDate) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  const result = await getBFFClient(token).post('/rpc/fetchStats', { cid, today, maxDate, minDate });
  return result;
};

export const fetchFDC = async (q, next) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/rpc/foodSearch', { q, next });
};

export const fetchFoodDetail = async (id, cid) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  const [resp, matches] = await Promise.all([
    getBFFClient(token).post('/rpc/foodDetails', { id, type: 'fdc' }),
    cid
      ? firebase.firestore().collection(`companies/${cid}/foodRecipes`).where('fdcId', '==', id).get()
      : Promise.resolve({ empty: true }),
  ]);
  const copy = matches.empty ? null : matches.docs[0];
  return {
    success: resp.data.success,
    data: omit(get(resp, 'data.data', {}), ['calPercBreakDown', 'serving_options']),
    copy,
  };
};

export const searchExercises = async (q, next = null, filters = {}) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/rpc/exerciseSearch', { q, next, filters });
};

export const getExercise = async (id, cid) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  const [resp, matches] = await Promise.all([
    getBFFClient(token).get(`/rpc/exercise/${id}`),
    cid
      ? firebase.firestore().collection(`companies/${cid}/exercises`).where('id', '==', id).get()
      : Promise.resolve({ empty: true }),
  ]);
  //fb used version doesn't support '!=' for archive
  const nonArchivedDocs = (matches.docs || []).filter((i) => {
    const data = i.data();
    return !data.archive;
  });
  const copy = matches.empty || !nonArchivedDocs.length ? null : nonArchivedDocs[0];
  return { success: resp.data.success, data: { ...resp.data.data, id }, copy };
};

export const getURLMeta = async (url) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return await getBFFClient(token).get(`/rpc/meta/${url}`);
};

export const deactivateUser = async (cid, uid) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post(`/rpc/deactivateUser`, { cid, uid });
};

export const deleteUser = async (cid, uid) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post(`/rpc/deleteUser`, { cid, uid });
};

export const isCidAvailable = async (cid) => {
  return getBFFClient().get('/api/cidAvailable', {
    params: {
      cid,
    },
  });
};
export const signup = async (payload) => {
  return getBFFClient().post('/api/companySignup', {
    ...payload,
  });
};
export const extendPlan = async (payload) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/rpc/sendExtndPlanMail', {
    ...payload,
  });
};
export const getClientStats = async ({ cid, startDate, endDate }) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/rpc/fetchClientStats', {
    cid,
    startDate,
    endDate,
  });
};
export const applyPromoCode = async (payload) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post(`/rpc/contract/applyCoupon`, payload);
};
export const createSetupIntent = async (payload) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/rpc/contract/upgradeV2', payload);
};
export const updateCardDetails = async (payload) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/rpc/contract/updateCard', payload);
};
export const updateBillingDetails = async (payload) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/rpc/contract/updateBillingDetails', payload);
};

export const createCompany = async (
  cid,
  email,
  company_name,
  trainer_name,
  time_zone = 'US/Eastern',
  upsell = false
) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post(`/rpc/createCompany`, { cid, email, trainer_name, company_name, time_zone, upsell });
};

export const stripeStatus = async (cid) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post(`/rpc/stripeStatus`, { cid });
};

export const paypalVerify = async (payload) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post(`/rpc/paypalVerify`, payload);
};

export const connectCalendar = async (payload) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post(`/rpc/connectCalendar`, { ...payload, env: process.env.REACT_APP_ENV_NAME });
};
export const getBooking = async () => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).get(`/rpc/contract/supportBooking`);
};
export const getCatalogByIds = async (payload) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post(`/rpc/getCatalogByIds`, payload);
};
export const getProgramDetail = async (cid, id) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).get(`explore/${cid}/program/${id}`);
};

export const getMealSchedulesDetail = async (cid, id) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).get(`explore/${cid}/meal-program/${id}`);
};

export const bffCheckinResetDefault = async (cid) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/rpc/checkinResetDefault', { cid });
};

export const fetchThumbnail = async (id, type) => {
  if (!id) return;
  const base = `${process.env.REACT_APP_THUMBNAIL_BFF}/${type}/${id}`;
  const blob = await (await fetch(base)).blob();
  return convertBlobToBase64(blob);
};

export const bffExportClients = async (cid, storeKey) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/rpc/portal/exportClients', { cid, storeKey });
};

export const bffDeleteGroup = async (payload) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/rpc/deleteGroup', payload);
};

export const createVidObject = async ({ cid, ...payload }) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post(`/rpc/createVideoObject/${cid}`, payload);
};
export const bffMailChimpList = async (cid) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).get(`/mailchimp/lists/${cid}`);
};

export const bffMailChimpSync = async (cid, list) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post('/mailchimp/sync', { cid, list });
};

export const bffReferInvites = async (body) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return axios.post(`${process.env.REACT_APP_BFF_URL}/rpc/portal/referInvites`, body, {
    headers: {
      'x-os-name': 'web',
      'x-client-version-number': 2,
      'fb-project': process.env.REACT_APP_FB_PROJECT,
      'fb-token': token || '',
    },
  });
};

export const bffContractDueAmount = async (body) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return axios.post(`${process.env.REACT_APP_BFF_URL}/rpc/contract/upgradeV2`, body, {
    headers: {
      'x-os-name': 'web',
      'x-client-version-number': 2,
      'fb-project': process.env.REACT_APP_FB_PROJECT,
      'fb-token': token || '',
    },
  });
};

export const bffManageAddOnFeatures = async (body) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return axios.post(`${process.env.REACT_APP_BFF_URL}/rpc/contract/features`, body, {
    headers: {
      'x-os-name': 'web',
      'x-client-version-number': 2,
      'fb-project': process.env.REACT_APP_FB_PROJECT,
      'fb-token': token || '',
    },
  });
};
/* supported  query params are eg: classId, trainerId, locationId, startDate, endDate, offset, limit */
export const getMindBodyClasses = async (cid, queryParams = {}) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  let url = `/rpc/mindbody/${cid}/instances`;
  Object.keys(queryParams).forEach((key, index) => {
    if (index === 0) url = url + `?${key}=${queryParams[key]}`;
    else url = url + `&${key}=${queryParams[key]}`;
  });
  return getBFFClient(token).get(url);
};

export const getSignPurchase = async (payload) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post(`/rpc/portal/signPurchase`, payload);
};

export const getZoomDisconnect = async (payload) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post(`/zoom/disconnect`, payload);
};


export const bffReminderResetDefault = async (cid) =>{
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post("/rpc/remindersResetDefault", { cid })
};


export const bffUpgradeToPlatinum = async (payload) =>{
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return getBFFClient(token).post(`/sudo/upgradePlatinum`, payload);
}


export const fetchVimeoVideo = async(cid, id) =>{
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return await getBFFClient(token).get(`vimeo/${process.env.REACT_APP_FB_PROJECT}/${cid}/obj/${id}`);
}

export const bffInvitePurchase = async (payload, time_zone = "") =>{
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  return axios.post(`${process.env.REACT_APP_BFF_URL}/rpc/invitePurchase`, payload, {
    headers: {
      'x-os-name': 'web',
      'x-client-version-number': 2,
      'fb-project': process.env.REACT_APP_FB_PROJECT,
      'fb-token': token || '',
      'timezone' :time_zone
    },
  });
}
