import { delay, takeLeading, all, call, select, put, takeLatest } from "redux-saga/effects";
import axnsBuilder from "fitbud/redux/base/actions";
import appRdxFns from "fitbud/redux/app/actions";
import moment from "moment";
import { fetchData } from "fitbud/api";
import _ from "lodash";
import { NETWORK_ERROR } from 'fitbud/utils/constants';

const DATA_LIMIT = 15;
const daysBefore = 7;
const expiryGracePeriod= 7;
const getPage = storeKey => state => state[storeKey].nextPage;
const getSearchPage = storeKey => state => state[storeKey].searchNextPage;
const getQuery = storeKey => state => state[storeKey].query;
const getFilter = storeKey => state => state[storeKey].filter;


function* handleDataLoad(
  cid,
  page,
  q = "",
  n = 15,
  collection,
  dtype,
  storeKey,
  filter,
  appAxns
) {
  try {
    const local=moment().local();
    const today = local.format("YYYYMMDD");
    let maxDate = moment(local).add(daysBefore, 'days').format("YYYYMMDD");
    if (cid === 'handsdan' && filter && filter.quick_filters && filter.quick_filters.completing_soon)
      maxDate = moment(local).add(3, 'weeks').format("YYYYMMDD");
    const minDate = moment(local).subtract(expiryGracePeriod, 'days').format("YYYYMMDD");
    const result = yield call(
      fetchData,
      collection,
      cid,
      page,
      q,
      n,
      dtype,
      storeKey,
      today,
      maxDate,
      minDate,
      filter
    );
    return result;
  } catch (e) {
    if(e.message === "Network Error"){
      //same time error set when refesh the api call
      yield put({
        type: appAxns.showEnqueueSnackBar,
        notifications: NETWORK_ERROR
      })
    }
    console.log(e);
  }
}

function* handleRequest(payload, rest) {
  const { axns, collection, dtype, storeKey, cid, appAxns } = payload;
  try {
    const page = !rest.refresh ? yield select(getPage(storeKey)) : 0;
    const dataLimit = Math.max(rest.dataLimit || 0, DATA_LIMIT);
    const { data } = yield handleDataLoad(
      cid,
      page,
      "",
      dataLimit,
      collection,
      dtype,
      storeKey,
      rest.filter,
      appAxns
    );
    yield put({
      type: axns.set,
      list: (data && data.data) || [],
      count: (data && data.count) || 0,
      refresh: rest.refresh,
      filter: rest.filter
    });
  } catch (err) {
    yield put({ type: axns.toggle });
    console.log(err.message);
  }
}
/* Force:
  In Case of Default Filters(Filters applied on mount of list)--See challenges list-
  Search api is called with no query and no filter, but the response is important
  thus using force.

*/
function* handleSearch(payload, rest) {
  const { axns, collection, dtype, storeKey, cid, appAxns } = payload;
  yield delay(500);
  try {
    console.log(rest.query, rest.filter)
    if (!rest.query && !rest.filter && !rest.force) {
      yield put({
        type: axns.searchList,
        list: undefined,
        searchCount: undefined,
        query: undefined,
        filter: undefined
      });
      return;
    }
    yield put({ type: axns.toggle });
    const query = yield select(getQuery(storeKey));
    const filter = yield select(getFilter(storeKey));
    const searchPage =
      (query === rest.query && _.isEqual(filter, rest.filter)) ? yield select(getSearchPage(storeKey)) : 0;
    const { data } = yield handleDataLoad(
      cid,
      searchPage,
      rest.query,
      DATA_LIMIT,
      collection,
      dtype,
      storeKey,
      rest.filter,
      appAxns
    );
    yield put({
      type: axns.searchList,
      list: data.data,
      searchCount: data.count,
      query: rest.query,
      filter: _.cloneDeep(rest.filter)
    });
  } catch (err) {
    yield put({ type: axns.toggle });
    console.log(err.message);
  }
}

export default (cid, collection, dtype = null, storeKey = null) => {
  // const cid = window.localStorage.getItem("cid")
  const axns = axnsBuilder(storeKey);
  const appAxns = appRdxFns();

  function* watchRequest(action, args) {
    yield takeLeading(action, handleRequest, args);
  }

  function* watchSearch(action, args) {
    // yield debounce(500, action, handleSearch);
    yield takeLatest(action, handleSearch, args);
  }

  return function* rootSaga() {
    yield all([
      watchSearch(axns.searching, {
        cid,
        axns,
        collection,
        dtype,
        storeKey,
        appAxns,
      }),
      watchRequest(axns.request, {
        cid,
        axns,
        collection,
        dtype,
        storeKey,
        appAxns,
      })
      // add other watchers to the array
    ]);
  };
};
