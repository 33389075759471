import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const BarChartLegend = (props) => {
  return (
    <SvgIcon classes={{
      root: "small"
    }} width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} style={{fill:"none", ...(props.style || {})}}>
      <path d="M1.33325 15.6667H14.6666" stroke="currentColor" stroke-width="1.33333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2.5 3.66659V15.6666H6.83333V3.66659C6.83333 2.93325 6.4 2.33325 5.1 2.33325H4.23333C2.93333 2.33325 2.5 2.93325 2.5 3.66659Z" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.33325 9.44453V15.6667H13.3333V9.44453C13.3333 8.46675 12.9333 7.66675 11.7333 7.66675H10.9333C9.73325 7.66675 9.33325 8.46675 9.33325 9.44453Z" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M38.3936 6.05L36.0236 12.41L33.6686 6.05H32.4536L30.0536 12.38L27.7436 6.05H26.3786L29.3336 14H30.7136L33.0386 7.97L35.3336 14H36.7136L39.6836 6.05H38.3936ZM48.1101 10.07C48.1101 7.625 46.4751 5.975 44.1801 5.975C41.8851 5.975 40.1901 7.67 40.1901 10.025C40.1901 12.395 41.9001 14.09 44.4651 14.09C45.7851 14.09 46.8801 13.64 47.6001 12.8L46.8051 11.87C46.2201 12.515 45.4401 12.83 44.5101 12.83C42.9351 12.83 41.8101 11.9 41.6301 10.505H48.0801C48.0951 10.37 48.1101 10.19 48.1101 10.07ZM44.1801 7.19C45.5751 7.19 46.5801 8.135 46.7301 9.47H41.6301C41.7801 8.12 42.8001 7.19 44.1801 7.19ZM50.8296 4.52C51.3996 4.52 51.8046 4.1 51.8046 3.56C51.8046 3.05 51.3846 2.645 50.8296 2.645C50.2746 2.645 49.8546 3.065 49.8546 3.59C49.8546 4.115 50.2746 4.52 50.8296 4.52ZM50.1096 14H51.5496V6.05H50.1096V14ZM60.5391 6.05V7.28C59.8641 6.41 58.7841 5.975 57.5991 5.975C55.3041 5.975 53.5641 7.52 53.5641 9.815C53.5641 12.11 55.3041 13.67 57.5991 13.67C58.7391 13.67 59.7741 13.265 60.4641 12.44V13.07C60.4641 14.87 59.6091 15.74 57.7341 15.74C56.5641 15.74 55.4091 15.335 54.6741 14.705L53.9841 15.815C54.8541 16.595 56.3241 17 57.7791 17C60.4791 17 61.9041 15.725 61.9041 12.92V6.05H60.5391ZM57.7641 12.41C56.1591 12.41 55.0191 11.345 55.0191 9.815C55.0191 8.27 56.1591 7.235 57.7641 7.235C59.3541 7.235 60.4941 8.27 60.4941 9.815C60.4941 11.345 59.3541 12.41 57.7641 12.41ZM68.9005 5.975C67.6855 5.975 66.6955 6.41 66.0955 7.175V2.87H64.6555V14H66.0955V9.905C66.0955 8.18 67.0855 7.265 68.6155 7.265C69.9805 7.265 70.7605 8.03 70.7605 9.59V14H72.2005V9.425C72.2005 7.085 70.8355 5.975 68.9005 5.975ZM78.8855 12.5C78.5855 12.74 78.1805 12.875 77.7605 12.875C76.9655 12.875 76.5155 12.395 76.5155 11.54V7.235H78.7955V6.05H76.5155V4.31H75.0755V6.05H73.7255V7.235H75.0755V11.6C75.0755 13.205 75.9905 14.09 77.5955 14.09C78.2405 14.09 78.9005 13.91 79.3355 13.535L78.8855 12.5Z" fill="#0A0A0A"/>
    </SvgIcon>
  );
};
