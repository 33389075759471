import { FormHelperText, makeStyles, Typography, MenuItem, Select, OutlinedInput } from '@material-ui/core';
import { TimePicker } from '@material-ui/pickers';
import { date2hhmm, hhmm2mins, mins2hhm } from 'fitbud/utils/scheduling';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import DropDownIcon from '@material-ui/icons/ExpandMore';
import { FormTextField } from 'fitbud/components/form-fields';
import clsx from 'clsx';
import { useServices } from 'fitbud/providers/services-provider';
import { useLocations } from 'fitbud/providers/gcLocationsProvider';
import { getLocationName } from 'fitbud/views/groupClasses/helper';
import { usePicker } from 'fitbud/hooks/form';
import _ from 'lodash';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { RoleContext } from 'fitbud/providers/roleProvider';
import { LEGACY_SERVICE_BASE } from 'fitbud/providers/access-provider';

/* * * * * SLOT - PICKER * * * * *
 * - Renders two MUI TimePickers to set a time range
 * - Data Model --- {time:"HH:MM" <string>, duration: difference between the range <number> }
 */
function SlotPicker(props) {
  let { disabled, slot, onChange, index, error } = props;
  const classes = useStyles();
  const [startTime, setStartTime] = useState(slot && new Date(`2021-01-01T${slot.time}:00`)); //Make new date from time of the slot
  const [endTime, setEndTime] = useState(
    slot && new Date(`2021-01-01T${mins2hhm(hhmm2mins(slot.time) + slot.duration)}:00`)
  );

  function handleStartTime(time) {
    const start = moment(time);
    if (!start.hour() && !start.minutes()) start.minute(15); // midnight becomes quarter past midnight
    let diff = moment(endTime).diff(start, 'minutes'); // find difference b/w start and end time
    // Pass the change up to parent and setState
    onChange({ time: date2hhmm(start), duration: diff }, index);
    setStartTime(start.toDate());
  }

  function handleEndTime(time) {
    const end = moment(time);
    if (!end.hour() && !end.minutes()) end.hour(23) && end.minute(45); // midnight becomes quarter to midnight
    let diff = end.diff(moment(startTime), 'minutes'); // find difference b/w start and end time
    // Pass the change up to parent and setState
    onChange({ time: date2hhmm(startTime), duration: diff }, index);
    setEndTime(end.toDate());
  }

  useEffect(() => {
    // Pass the change up to parent and setState
    setEndTime(slot && new Date(`2021-01-01T${mins2hhm(hhmm2mins(slot.time) + slot.duration)}:00`));
  }, [slot]);

  return (
    <div>
      <div className="d-flex align-items-center">
        <div>
          <TimePick
            inputClasses={classes.maxWidth}
            disabled={disabled}
            value={startTime}
            onChange={handleStartTime}
            error={error}
          />
        </div>
        <div className="mx-2">
          <Typography variant="caption" color="textPrimary">
            -
          </Typography>
        </div>
        <div>
          <TimePick
            inputClasses={classes.maxWidth}
            disabled={disabled}
            value={endTime}
            onChange={handleEndTime}
            error={error}
          />
        </div>
      </div>
      {error && (
        <FormHelperText style={{ marginTop: '-15px' }} error={true}>
          {error}
        </FormHelperText>
      )}
    </div>
  );
}
export function AvailabilitySlotPicker(props) {
  //contains slot picker with service and location
  let { disabled, slot, onChange, index, error, staffId, updateInConsistence } = props;
  const classes = useStyles();
  const { comp } = useContext(FirebaseAuthContext);
  const { gcEnabled } = useContext(RoleContext);
  const company = comp ? comp.data() : {};
  const intro_call_trainers = _.get(company, `features.intro_call_trainers`, []);
  const intro_call = _.get(company, `features.intro_call`, false);
  const intro_call_duration = _.get(company, `features.intro_call_duration`, false);
  const { getServiceById, services } = useServices();
  const [startTime, setStartTime] = useState(slot && new Date(`2021-01-01T${slot.time}:00`)); //Make new date from time of the slot
  const [endTime, setEndTime] = useState(
    slot && new Date(`2021-01-01T${mins2hhm(hhmm2mins(slot.time) + slot.duration)}:00`)
  );
  const [service, setService] = usePicker(slot?.service || null);
  const [location, setLocation] = usePicker(slot?.location || null);

  const serviceOptions = useMemo(() => {
    const _services = (services || []).map((service) => ({
      value: service._id,
      label: service?.data?.title,
      trainers: service?.data?.trainers || [],
    }));
    const filter_services = _.filter(
      _services,
      (s) => _.isEmpty(s.trainers) ||  _.includes(s.trainers, 'all') || _.includes(s.trainers, staffId)
    );
    const legacyServices = [{ value: `intro`, label: `Intro Call`}, {value: `vidcall`, label: LEGACY_SERVICE_BASE.ref_name}]
    filter_services.unshift(...legacyServices);
    return filter_services;
  }, [staffId, intro_call_trainers, intro_call, gcEnabled, intro_call_duration, services]);

  const isInconsistent = useMemo(() => {
    if (!gcEnabled) return false;
    const service = slot?.service;
    if (!service) return false;
    const found = _.find(serviceOptions, (op) => op.value === service);
    if (!!found) return false;
    if (!!service && !found) return true;
    return false;
  }, [serviceOptions, slot, gcEnabled]);

  updateInConsistence(isInconsistent);

  const [mode, locationOptions] = useMemo(() => {
    if (!service) return ['', []];
    if (!!service) {
      const serviceInfo = getServiceById(service);
      const mode = serviceInfo?.data?.mode;
      const locations = serviceInfo?.data?.locations || [];
      return [mode, [...locations]];
    }
  }, [service]);

  const errorMsg = useMemo(() => {
    if (!!error) return error;
    else if (!!isInconsistent) return 'Please fix services';
  }, [error, isInconsistent]);

  const onChangeService = (e) => {
    const value = e.target.value;
    const serviceInfo = getServiceById(value);
    const mode = serviceInfo?.data?.mode;
    if (mode === 'online') {
      setLocation('app');
      onChange({ time: slot.time, duration: slot.duration, location: 'app', service: value }, index);
    } else {
      setLocation('');
      onChange({ time: slot.time, duration: slot.duration, location: null, service: value }, index);
    }
    setService(e);
  };

  const onChangeLocation = (e) => {
    setLocation(e);
    const value = e.target.value;
    onChange({ time: slot.time, duration: slot.duration, service: slot.service || null, location: value }, index);
  };

  function handleStartTime(time) {
    const start = moment(time);
    if (!start.hour() && !start.minutes()) start.minute(15); // midnight becomes quarter past midnight
    let diff = moment(endTime).diff(start, 'minutes'); // find difference b/w start and end time
    // Pass the change up to parent and setState
    onChange({ time: date2hhmm(start), duration: diff, location: location, service: service }, index);
    setStartTime(start.toDate());
  }

  function handleEndTime(time) {
    const end = moment(time);
    if (!end.hour() && !end.minutes()) end.hour(23) && end.minute(45); // midnight becomes quarter to midnight
    let diff = end.diff(moment(startTime), 'minutes'); // find difference b/w start and end time
    // Pass the change up to parent and setState
    onChange(
      { time: date2hhmm(startTime), duration: diff, location: location || null, service: service || null },
      index
    );
    setEndTime(end.toDate());
  }

  useEffect(() => {
    // Pass the change up to parent and setState
    setEndTime(slot && new Date(`2021-01-01T${mins2hhm(hhmm2mins(slot.time) + slot.duration)}:00`));
    if (slot.service !== service) setService(slot.service);
    if (slot.location !== location) setLocation(slot.location);
  }, [slot, location, service]);

  return (
    <div>
      <div className="d-flex align-items-center">
        <FormTextField label="Start time">
          <TimePick
            inputClasses={classes.maxWidth}
            disabled={disabled}
            value={startTime}
            onChange={handleStartTime}
            error={error}
          />
        </FormTextField>
        <Typography variant="caption" className="mx-10">
          -
        </Typography>
        <FormTextField label="End time" classes={{ control: clsx('mb-20 mr-10') }}>
          <TimePick
            inputClasses={classes.maxWidth}
            disabled={disabled}
            value={endTime}
            onChange={handleEndTime}
            error={error}
          />
        </FormTextField>
        {!!gcEnabled && (
          <React.Fragment>
            <ServicePicker
              value={service}
              onChange={onChangeService}
              disabled={disabled}
              options={serviceOptions}
              classes={{ control: clsx('mb-20 mr-10', classes.selectMaxWidth) }}
            />
            <LocationPicker
              disabled={disabled || !service || mode === 'online'}
              value={location}
              options={locationOptions}
              onChange={onChangeLocation}
              classes={{ control: clsx('mb-20 mr-10', classes.selectMaxWidth) }}
            />
          </React.Fragment>
        )}
      </div>
      {!!errorMsg && (
        <FormHelperText style={{ marginTop: '-15px' }} error={true}>
          {errorMsg}
        </FormHelperText>
      )}
    </div>
  );
}

export const ServicePicker = (props) => {
  const { value, onChange, label, classes, disabled, fullWidth, allowEmpty = true,  options, ...rest } = props;
  const { services = [] } = useServices();
  const serviceOptions = useMemo(() => {
    if(_.size(options)) return options; // if options, [{value, label}] provide other wise all services .
    return (services || []).map((service) => ({ value: service._id, label: service?.data?.title }));
  }, [services]);

  const [isInconsistent, selectedService] = useMemo(()=>{
    const found =  _.find(serviceOptions, (op)=>op.value === value);
    if(!!found) return [false, found];
    if(!!value && !found) return [true];
    return [false]
  },[serviceOptions,value]);

  const _renderValue = (value) =>{
     if(isInconsistent) return 'Select 1 on 1 Session';
     else if(!!selectedService) return selectedService.label;
     else return "All";
  }

  return (
    <FormTextField fullWidth label={label || '1 on 1 Session'} classes={classes}>
      <Select
        variant="outlined"
        error={isInconsistent}
        IconComponent={DropDownIcon}
        onChange={onChange}
        value={value || ''}
        disabled={disabled}
        displayEmpty
        fullWidth
        renderValue={_renderValue}
        input={
          <OutlinedInput
            classes={{
              root: 'medium',
              input: 'size_16_500 select-medium',
            }}
            name="category"
          />
        }
        select
        {...rest}>
        {allowEmpty && (
          <MenuItem value={''} key="all">
            All
          </MenuItem>
        )}
        {serviceOptions.map((service) => (
          <MenuItem key={service?.value} value={service?.value}>
            {service?.label}
          </MenuItem>
        ))}
      </Select>
    </FormTextField>
  );
};

export const LocationPicker = (props) => {
  const { value, onChange, label, mode, options, allowEmpty = true, classes, disabled, fullWidth, ...rest } = props;
  const { offline_locations, actual_online_locations, getLocation } = useLocations();

  const locations = useMemo(() => {
    if (mode && !(options || []).length) {
      let _options = [];
      //if mode available and options not then load all location of specif mode. use case when booking services having location : all.
      const _locations = mode === 'offline' ? offline_locations : actual_online_locations;
      _options = _locations.map((option) => {
        return {
          value: option?._id,
          label: getLocationName(option.data),
        };
      });
      if (allowEmpty) _options.unshift({ value: '', label: 'All' });
      return _options;
    }
    if (!options.length)
      if (allowEmpty) return [{ value: '', label: 'All' }];
      else return [];

    const lOptions = options.map((id) => {
      if (id === 'all' || !id) return '';
      if(id === "app") return {value:'app', label:"In App"}
      const ld = getLocation(id);
      return { value: id, label: getLocationName(ld.data) };
    });
    if (allowEmpty) lOptions.unshift({ value: '', label: 'All' });
    return _.without(lOptions, undefined, null, '');
    //if nothing found return [];
    return [];
  }, [mode, allowEmpty, options, offline_locations, actual_online_locations]);

  return (
    <FormTextField fullWidth label={label || 'Location'} classes={classes}>
      <Select
        variant="outlined"
        IconComponent={DropDownIcon}
        onChange={onChange}
        value={value || ''}
        disabled={disabled}
        displayEmpty
        fullWidth
        input={
          <OutlinedInput
            classes={{
              root: 'medium',
              input: 'size_16_500 select-medium',
            }}
            name="category"
          />
        }
        select
        {...rest}>
        {locations.map((location) => (
          <MenuItem key={location?.value} value={location?.value}>
            {location?.label}
          </MenuItem>
        ))}
      </Select>
    </FormTextField>
  );
};

export function TimePick(props) {
  const { minutesStep = 15 } = props;
  const classes = useStyles();
  return (
    <TimePicker
      {...props}
      minutesStep={minutesStep}
      inputVariant="outlined"
      InputProps={{
        className: `${classes.timeInput} ${props.inputClasses}`,
        ...props.InputProps,
      }}
    />
  );
}
const useStyles = makeStyles((theme) => ({
  timeInput: {
    minHeight: '48px',
    fontSize: '15px',
    fontWeight: 500,
  },
  maxWidth: {
    maxWidth: '120px',
  },
  selectMaxWidth: {
    width: '150px',
  },
}));

export default withSnackbar(SlotPicker);
