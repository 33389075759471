import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import firebase from "fitbud/firebase";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { convertToAppointments, fetchWeekData, STATUS_CANCEL, structureAppointment, sortByStartDate, GROUP_CLASS_PROVIDER, structureInstance, filterActiveInstances } from "fitbud/utils/scheduling";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useRouteMatch } from "react-router";
import { findIndex } from "lodash";
import BookingDialog from "fitbud/views/liveSessions/booking-dialog";
import CancelDialog from "fitbud/views/liveSessions/subComps/cancelDialog";
import { useLocations } from 'fitbud/providers/gcLocationsProvider';
import _ from "lodash";
import staffs from "fitbud/repo/staffs";
import { getMindBodyClasses } from "fitbud/api";
import { useServices } from "./services-provider";
export const LiveSessionsContext = React.createContext({});

//helper functions ...

export const loadBookings = async(from ,to, cid, uid, filter) => {
  let out = [];
  const [filter_mode = "trainer", filter_value = uid] = filter || [];
  let collectionRef = firebase.firestore().collection(`companies/${cid}/bookings`);
  if(filter_mode === "trainer") collectionRef =  collectionRef.where('trainer_id', '==', filter_value);
  let snapshot = await collectionRef.where('time', '>=', from).where('time', '<=', to).where("class" , "==", null).orderBy('time', 'desc').get()
  if(snapshot.empty) return out;
  snapshot.forEach((doc)=> {
    const data = doc.data();
    if(!!data.class) return; //ignoring group classes...
    out.push({...data, book_id: doc.id})
  })
  return convertToAppointments(out, {withTimestamp: true});
}

export const loadInstances = async ({startDate, endDate, filter, cid}) =>{
  const _from = moment(startDate).format('YYYYMMDD');
  const _to = moment(endDate).format('YYYYMMDD');
  let query = firebase.firestore().collection(`/companies/${cid}/gcInstances`);
  const [filter_mode, filter_value] = filter;
  if (filter_mode === 'trainer') query = query.where('trainer_id', '==', filter_value);
  if (filter_mode === 'location') query = query.where('location_offline', '==', filter_value);
  query = query
  .where('time', '>=', _from)
  .where('time', '<=', _to)
  .orderBy('time', 'desc');
  const snap = await query.get();
  const docs = snap.docs;
  const  instances = [];
  docs.forEach((doc) => {
    const data = doc.data();
    // if (data.mode === 'offline') return; //TODO ::: need to review.
    const instance = { _id: doc.id, ...data, book_id: doc.id };
    const appointment = structureInstance(instance);
    if (appointment) instances.push(appointment);
  });
  const out = filterActiveInstances(instances);
  return out;
}

export const loadMindBodyInstances = async ({startDate, endDate, filter, cid}) =>{
  const _from = moment(startDate).format('YYYYMMDD');
  const _to = moment(endDate).format('YYYYMMDD');
  const [filter_mode, filter_value] = filter;
  const queryParams = {offset:0, limit:100, startDate : _from, endDate : _to };
  if(filter_mode === "trainer") queryParams.trainerId = filter_value;
  if(filter_mode === "location") queryParams.locationId = filter_value;
  const response = await getMindBodyClasses(cid, queryParams );
  const {success, instances} = response.data;
  if(!success) return [];
  let outInstances = [];
  if(!!instances && !!instances.length){
    outInstances = instances.slice(0,40)
  }
  const out = [];
  outInstances.forEach((data)=>{
    const instance = { _id: data.id, ...data, book_id: data.id };
    const appointment = structureInstance(instance);
    if (appointment) out.push(appointment);
  })
  const _instances = filterActiveInstances(out); 
  console.log(">>>>>> instances",_instances)
  return _instances;
}


const LiveSessionsProvider = (props) => {
  let { cid, authUser: { uid } = {}, comp, isAdmin } = useContext(FirebaseAuthContext);
  let isLiveSessionPage = Boolean(useRouteMatch('/live/calendar')) // true if path is /video-calls
  let isHomePage = Boolean(useRouteMatch('/home')) // true if path is /home
  const {fetchLocations} = useLocations();
  const company = comp ? comp.data() : {};
  const {loadServices} = useServices();
  /* * * * * * * * * * * * * * * * * * * * * 
    ----- Dialog / Loaders API -----
  * * * * * * * * * * * * * * * * * * * * */
  const [isLoading, setLoading] = useState(true);
  const [isScheduleLoading, setScheduleLoading] = useState(true);
  const [isBackdropLoading, setBackdropLoading] = useState(false);
  const [refresh, setRefresh] = useState(false); // Trigger for BFF call 
  const [isNewBookings, setNewBookings] = useState(false);
  const [configDoc, setConfigDoc] = useState({});
  const [filter, setFilter] = useState(["trainer", uid]); // structure is like [type, value];
  const [selectedStaff, setSelectedStaff] = useState({});
  // for default selected trainer in booking info of booking dialog
  const [defaultTrainer, setDefaultTrainer] = useState(null);
  const isGroupClass = Boolean(_.get(company, "features.group_class.enabled", false));
  const isMindBody = !!_.get(company, 'features.mindbody', false);


  function triggerRefresh() {
    setRefresh(!refresh);
    setNewBookings(false)
    setScheduleLoading(true);
  }
  function showBackdrop() {
    setBackdropLoading(true);
  }
  function hideBackDrop() {
    setBackdropLoading(false);
  }

  /* * * * * * * * * * * * * * * * * * * * * 
    ----- Date / Week API -----
  * * * * * * * * * * * * * * * * * * * * */
  //Set the week for today's date from SUN to SAT
  const startToday = moment().startOf('day');
  const endToday = moment().endOf('day');
  const firstDayOfWeek = startToday.clone().subtract(startToday.weekday(),'days');

  const [startDate, setStartDate] = useState(firstDayOfWeek.toDate());
  const [endDate, setEndDate] = useState(firstDayOfWeek.clone().add(6, 'days').endOf('day').toDate());
  const [customDtLoading,toggleCustomDtLoading]=useState(false);

  //Set endDate every time date changes
  function adjustEndDate(from) {
    const d = moment(from).add(6, 'days').endOf('day').toDate();
    setEndDate(d);
  }
  //methods for updating  filter 
  function setSessionFilter (filter){
    setFilter(filter);
    setScheduleLoading(true);
  }
  //set startDate, endDate is calculated with adjustEndDate() 
  function setRange(from){
    adjustEndDate(from);
    setStartDate(moment(from).toDate());
    toggleCustomDtLoading(true);
  }
  function resetDate(){
    const from=firstDayOfWeek.toDate();
    setRange(from);
  }
  //Navigate to previous week
  function navigatePrevWeek(e) {
    e.preventDefault();
    if (isScheduleLoading) return;
    setScheduleLoading(true);
    const d = moment(startDate).subtract(7, 'days').toDate();
    adjustEndDate(d);
    setStartDate(d);
  }
  //Navigate to next week
  function navigateNextWeek(e) {
    e.preventDefault();
    if (isScheduleLoading) return;
    setScheduleLoading(true);
    const d = moment(startDate).add(7, 'days').toDate();
    adjustEndDate(d);
    setStartDate(d);
  }

  /* * * * * * * * * * * * * * * * * * * * * 
    ----- Availability / Bookings API -----
  * * * * * * * * * * * * * * * * * * * * */
  const [availability, setAvailability] = useState(undefined);
  const [bookings, setBookings] = useState(undefined);
  const [gcInstances, setGcInstances] = useState([]);
  /* All booking including one to one and group classes  */
  const _bookings = useMemo(()=>{
    let out = [];
    out = [...(bookings || []), ...gcInstances];
    out = sortByStartDate(out);
    return out;
  },[bookings, gcInstances])

  const resetFilter = () =>{
    setFilter(["trainer", uid]);
    setScheduleLoading(true);
  }

  //on first mount load logged in user master schedule
  const loadStaff = async (id) =>{
    const doc = await staffs.doc(id);
    if(!doc.exists) return {};
    return doc.data();
  }

  const loadAvailabilityAndBooking = async ()=>{
    const [filter_mode = "trainer", filter_value = uid] = filter || [];
    const isTrainerFilter = filter_mode === "trainer";
    const promises = [];
    promises.push(fetchWeekData(cid, startDate, endDate, isTrainerFilter ? filter_value : "")); //availability
    promises.push(loadBookings(startDate, endDate, cid, uid, filter)); // one to one booking
    if(isTrainerFilter) promises.push(loadStaff(filter_value));
    else promises.push(Promise.resolve(undefined)) //staff if trainer filer applied...
    if(isGroupClass)promises.push(isMindBody ? loadMindBodyInstances({startDate, endDate, filter, cid}):loadInstances({startDate, endDate, filter, cid}))
    else promises.push(Promise.resolve([]))
    try{
      const responses = await Promise.all(promises);
      const [availability, bookings, staff, instances] = responses;
      const {success, data } = availability || {};
      if(!success) setAvailability(false);
      //TODO: we not shows calender if availability is false...
      if(!!success){ 
        const schedule = convertToAppointments(data.slots, {withTimestamp: false, ignoreBooked: true}); //Convert to AppointmentModel and ignore bookings
        setAvailability({ ...data, appointments: schedule });
      }
      if(!!bookings) setBookings(bookings);
      if(!!staff) setSelectedStaff(staff);
      if(instances) setGcInstances(instances);
      //setting all loader false...
      setLoading(false);
      setScheduleLoading(false);
      toggleCustomDtLoading(false);
    }catch(err){
      console.log(">>>error in loading initial data",err);
    }

  };

  useEffect(()=>{
    loadAvailabilityAndBooking();
    return ()=>{
      console.log(">>>> unmount")
    }
  },[startDate, endDate, filter, uid, cid, refresh])
   

  useEffect(()=>{
    loadServices();
  },[])

  
  /* * * * * * * * * * * * * * * * * * * * * 
    -------- SNAPSHOT LISTENER API ---------
  * * * * * * * * * * * * * * * * * * * * */
  const parseChangesAndUpdate = useCallback((changes, endLimit) => {
    // Validate changes and update state conditionally if only changes are before endLimit 
    let newChanges = [];
    changes.forEach(({doc}, index) => {
      const data = doc.data();
      if(data.provider === GROUP_CLASS_PROVIDER) return null;
      if (moment(data.time.toDate()).isAfter(endLimit)) return;
      if(data.status === STATUS_CANCEL) newChanges.push({...data, book_id: doc.id});
      // Convert new booked changes to appointments
      let appointment = structureAppointment({...data, book_id: doc.id}, index, { withTimestamp: true, includeCancelled: true })
      if (appointment) newChanges.push(appointment);
    });
    if(!newChanges.length) return; // No new changes for any booking in this week
    setBookings((prev) => { 
      if(!prev) return [...newChanges];
      let toUpdate = [...prev];
      newChanges.forEach((booking) => {
        let idx = findIndex(toUpdate, (slot) => slot.book_id === booking.book_id);
        if(booking.status === STATUS_CANCEL){
          if(idx === -1) return;
          toUpdate.splice(idx, 1); // Remove from bookings if there
          return;
        }
        if (idx === -1) return toUpdate.push({...booking}); // add to bookings
        toUpdate.splice(idx, 1, {...booking}); // Omit that booking if already exists in bookings
      });
    return [...toUpdate];
    });
    setNewBookings(true) // Reload data
  }, []);
 
  useEffect(()=> {
    if(!uid) return;
    const [filter_mode, filter_value] = filter;
    let trainerUid = uid;
    if(filter_mode === "trainer") trainerUid = filter_value;
    const collectionRef = firebase.firestore().collection(`companies/${cid}/bookings`);
    let query = collectionRef.where('trainer_id', '==', trainerUid).where("class" , "==", null).where('_uat' , '>=', moment().toDate());
    if(!isLiveSessionPage){
      // When not /live-session page listen for today's changes
      const unsubscribe = query.onSnapshot((snapshot) => {
        const changes = snapshot.docChanges();
        if(!changes.length) return;
        console.log(`${changes.length} changes detected for /live-sessions`); // rm logs
        // Only if change is for booking which are in this week
        const newEndTime=isHomePage?endDate:endToday.toDate();
        parseChangesAndUpdate(changes, newEndTime);
      })  
      return () =>  unsubscribe();
    }
    // When on /live-session page listen for week's changes
    const lastDateOfWeek = moment(startDate).clone().add( 7 - moment(startDate).weekday(),'days').endOf('day');
    const unsubscribe = query.onSnapshot((snapshot) => {
      const changes = snapshot.docChanges();
      if(!changes.length) return;
      console.log(`${changes.length} changes detected for /live-sessions`); // rm logs
      // Only if change is for booking which are in this week
      parseChangesAndUpdate(changes, lastDateOfWeek.toDate())
    })
    return () => {
        console.log('Snapshot listener removed for /live-sessions');
        unsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLiveSessionPage, startDate, filter])

  /* Listener For Group Classes Instances   */
  useEffect(()=>{
    if (!uid || !isGroupClass) return;
    const _from = moment(startDate).format('YYYYMMDD');
    const _to = moment(endDate).format('YYYYMMDD');
    let query = firebase.firestore().collection(`/companies/${cid}/gcInstances`);
    const [filter_mode, filter_value] = filter;
    if (filter_mode === 'trainer') query = query.where('trainer_id', '==', filter_value);
    if (filter_mode === 'location') query = query.where('location_offline', '==', filter_value);
    query = query
      .where('time', '>=', _from)
      .where('time', '<=', _to)
      .orderBy('time', 'desc');
    const unsubscribe = query.onSnapshot(
      (snapshot) => {
        const changes = snapshot.docChanges();
        const instances = [];
        changes.forEach((snap) => {
          const instance = { _id: snap.doc.id, ...snap.doc.data(), book_id :snap.doc.id};
          const appointment = structureInstance(instance);
          if(appointment)instances.push(appointment);
        });
        setGcInstances((prev) => {
          if (!prev || !prev.length) return filterActiveInstances(instances);
          const temp = [...prev];
          instances.forEach((instance) => {
            const index = _.findIndex(temp, (i) => i.book_id === instance.book_id);
            if (index > 0) {
              if (!instance.active) {
                temp.splice(index, 1);
              }else{
                temp.splice(index, 1, {...instance})
              }
            } else if (index === -1) {
              temp.push(instance);
            }
          });
          return filterActiveInstances(temp);
        });
      },
      (error) => {
        console.log('>>>error in listers of gcInstances', error);
      }
    );
    return () => {
      unsubscribe()
    };
  },[filter,startDate, endDate, uid, isGroupClass])

  // Trigger refresh when user comes on live-session and isNewBookings is true
  useEffect(()=> {
    if (isNewBookings && isLiveSessionPage){
        triggerRefresh();
        setNewBookings(false);
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewBookings, isLiveSessionPage])

  //Get bufferTime from appConfig
  const fetchConfigDoc = async () => {
    const snapshot = await firebase.firestore().collection("config").doc("appConfig").get();
    return snapshot;
  }

  useEffect(() => {
    fetchConfigDoc().then((configDoc) => {
      if(!!configDoc) {
        setConfigDoc(configDoc.data());
      }
    })
    fetchLocations();
  }, []);

  // Video calling screen dialog API
  const [openVideoScreen, setVideoScreen] = useState(false);
  const [bookingDialog, setBookingDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);

  function openBookingDialog(session, userProfile) {
    // Pass session parameter to enable reschedule mode
    // pass userProfile to skip to step 2
    setBookingDialog(session || {...userProfile, ...(userProfile.profile || {})} || true);
  }
  function closeBookingDialog() {
    setBookingDialog(false);
  }
  function openCancelDialog(session) {
    setCancelDialog(session);
  }
  function closeCancelDialog() {
    setCancelDialog(false);
  }
  const handleOpenVideoScreen = (data) => {
    setVideoScreen(data.book_id);
  };
  const handleCloseVideoScreen = (e, ended = false) => {
    if (e) e.preventDefault();
    if (ended) {
      firebase.firestore().doc(`companies/${cid}/bookings/${openVideoScreen}`)
        .update({trainerCallEndTs: moment().toDate()})
        .then(() => setVideoScreen(false))
        .catch(() => setVideoScreen(false));
    } else setVideoScreen(false);
  };

  const classes = useStyles();
  return (
    <LiveSessionsContext.Provider
      value={{
        cid,
        startDate,
        endDate,
        openVideoScreen,
        availability,
        isBackdropLoading,
        isScheduleLoading,
        isLoading,
        isNewBookings,
        bookings : _bookings,
        configDoc,
        customDtLoading,
        bookingDialog,
        filter,
        selectedStaff, 
        resetFilter,
        setSessionFilter,
        setNewBookings,
        navigatePrevWeek,
        navigateNextWeek,
        setLoading,
        handleOpenVideoScreen,
        handleCloseVideoScreen,
        showBackdrop,
        hideBackDrop,
        triggerRefresh,
        openBookingDialog,
        closeBookingDialog,
        openCancelDialog,
        closeCancelDialog,
        setRange,
        resetDate,
        setDefaultTrainer
      }}
    >
      {isBackdropLoading ? (
        <Backdrop className={classes.backdrop} open={isBackdropLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      {Boolean(bookingDialog) ? (
        <BookingDialog
          open={Boolean(bookingDialog)}
          onClose={closeBookingDialog}
          directBooking={Boolean(bookingDialog.uid)} // Check if userProfile is passed
          rescheduleMode={Boolean(bookingDialog.book_id)} // Check if any session is passed to reschedule
          data={Boolean(bookingDialog.book_id) || Boolean(bookingDialog.uid) ? bookingDialog : undefined}
          triggerRefresh={triggerRefresh}
          availability={availability} 
          defaultTrainer={defaultTrainer} />
      ) : null}
      {Boolean(cancelDialog) ? (
        <CancelDialog
          open={Boolean(cancelDialog)}
          onClose={closeCancelDialog}
          cancelMode={Boolean(cancelDialog.startDate)}
          session={cancelDialog}
        />
      ) : null}
      {props.children}
    </LiveSessionsContext.Provider>
  );
};
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1001,
    color: "#fff",
  },
}));

export default LiveSessionsProvider;

export const useLiveSessions = () => {
  const context = useContext(LiveSessionsContext);
  if (!context) {
    throw new Error("useSchedulerContext must be used within a SchedulerProvider");
  }
  return context;
};
